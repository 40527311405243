import env from '~env';
import { HttpRequestError } from '~modules/errors';

export const processAxiosError = (error: any): never => {
  if (error.response) {
    throw HttpRequestError.fromOriginal(error, error.response.status, error.response.data);
  }

  throw HttpRequestError.fromOriginal(error, undefined);
};

export const isAlmondApi = (url?: string): boolean => {
  return !!url && url.includes(env.API_BASE_URL);
};

// https://github.com/expo/expo/issues/2402#issuecomment-443726662
export const toBlob = (fileUri: string): Promise<Blob> => {
  return new Promise<Blob>((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.responseType = 'blob';

    request.onload = () => resolve(request.response);

    request.onerror = () => reject(new TypeError('Network request failed'));

    request.open('GET', fileUri, true);
    request.send(null);
  });
};
