import {
  RECOMMENDATION_PAGE_NAME,
  SCHEDULING_PAGE_NAME,
  TELL_US_MORE_PAGE_NAME,
  VISIT_CATEGORIES_PAGE_NAME,
  VISIT_REASONS_PAGE_NAME,
} from '~types';

import { signupRoutes } from './signup';

import type { RoutingConfig, StackParamList } from '~types';

export const initialNewMemberRoutes = {
  id: 'initialNewMember' as const,
  initial: () => ({ name: VISIT_REASONS_PAGE_NAME }),
  routes: {
    [VISIT_CATEGORIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: VISIT_REASONS_PAGE_NAME }),
      },
      reset: true,
    },
    [VISIT_REASONS_PAGE_NAME]: {
      on: {
        submit: (_, params) => {
          if (params.visit_reason === 'establish_care') {
            return { name: SCHEDULING_PAGE_NAME };
          }

          return { name: RECOMMENDATION_PAGE_NAME };
        },
      },
    },
    [RECOMMENDATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: SCHEDULING_PAGE_NAME }),
        curatedSubmit: () => {
          throw new Error(`Recommendation page for new member can't dispatch "curatedSubmit" event`);
        },
        secondary: () => ({ name: SCHEDULING_PAGE_NAME, params: { showAll: 'true' } }),
      },
    },
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: () => ({ name: TELL_US_MORE_PAGE_NAME }),
        curatedSubmit: () => {
          throw new Error(`Scheduling page for new member can't dispatch "curatedSubmit" event`);
        },
      },
    },
    [TELL_US_MORE_PAGE_NAME]: {
      on: {
        submit: () => signupRoutes,
      },
    },
  },
} satisfies RoutingConfig<StackParamList>;
