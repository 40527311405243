import React from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import { MainForm } from '~modules/forms';
import { usePromotionCodeNotificationStatus, useReferralCodeNotificationStatus } from '~modules/payment';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom } from '~modules/state';
import { NotificationStatusBar } from '~modules/ui';

import { VisitCategories } from './VisitCategories';

import themedStyles from './styles';

import type { VISIT_CATEGORIES_PAGE_NAME } from '~types';

export const VisitCategoriesPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof VISIT_CATEGORIES_PAGE_NAME>();
  const [styles] = useTheme(themedStyles);
  const referralNotificationStatus = useReferralCodeNotificationStatus();
  const promotionNotificationStatus = usePromotionCodeNotificationStatus();

  return (
    <MainForm
      id="visitCategories"
      title={t('visitCategories.title')}
      atom={appointmentParamsAtom}
      size="L"
      statusBar={
        <>
          {referralNotificationStatus && <NotificationStatusBar {...referralNotificationStatus} />}
          {promotionNotificationStatus && <NotificationStatusBar {...promotionNotificationStatus} />}
        </>
      }
    >
      <Text style={styles.header} size="m" fontStyle="bold">
        {t('chooseOne')}
      </Text>
      <VisitCategories isLoading={false} onSubmit={category => dispatch('submit', undefined, { category })} />
    </MainForm>
  );
};
