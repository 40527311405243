/* eslint-disable global-require */
import { Appearance } from 'react-native';

export type Meditations = {
  clouds1: number;
  plant1: number;
  plant2: number;
  plant3: number;
  universe1: number;
  universe2: number;
  universe3: number;
  relaxingWoman: number;
  womanLookingAhead: number;
};

export type Images = Meditations & {
  error: number;
  searchByAvailability: number;
  search: number;
  stars: number;
};

export const lightImages: Images = {
  searchByAvailability: require('./searchByAvailability.png'),
  error: require('./error.webp'),
  search: require('./search.png'),
  stars: require('./stars.webp'),
  clouds1: require('./meditations/clouds1.webp'),
  plant1: require('./meditations/plant1.webp'),
  plant2: require('./meditations/plant2.webp'),
  plant3: require('./meditations/plant3.webp'),
  universe1: require('./meditations/universe1.webp'),
  universe2: require('./meditations/universe2.webp'),
  universe3: require('./meditations/universe3.webp'),
  relaxingWoman: require('./meditations/relaxingWoman.webp'),
  womanLookingAhead: require('./meditations/womanLookingAhead.webp'),
};

// We don't support multiple themes now, but it'll be possible in the future.
export const darkImages: Images = lightImages;

export const images: Images = Appearance.getColorScheme() === 'dark' ? darkImages : lightImages;
