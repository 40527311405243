import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  container: {
    height: 68,
    width: '100%',
    zIndex: 1,
  },

  contentContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
}));
