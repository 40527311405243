import type { StyleProp, ViewStyle } from 'react-native';

export const combineClassNames = (styles: StyleProp<ViewStyle>): StyleProp<ViewStyle> => {
  if (!Array.isArray(styles)) {
    return styles;
  }

  const classNames = [];
  const toReturn: StyleProp<ViewStyle> = [];

  for (let i = 0; i < styles.length; i += 1) {
    if (Array.isArray(styles[i])) {
      throw new Error('Not supported: nested style arrays');
    }

    const thisStyle = styles[i] as unknown;

    if (
      thisStyle &&
      typeof thisStyle === 'object' &&
      '$$css' in thisStyle &&
      thisStyle.$$css === true &&
      '_' in thisStyle &&
      typeof thisStyle._ === 'string'
    ) {
      classNames.push(thisStyle._);
    } else {
      toReturn.push(styles[i]);
    }
  }

  if (classNames.length) {
    toReturn.push({ $$css: true, _: classNames.join(' ') } as StyleProp<ViewStyle>);
  }

  return toReturn;
};
