import { I18n } from '@almond/localization';

import type { PregnancyOutcomeEnum } from '@almond/api-types';
import type { SelectOption } from '@almond/ui';
import type { PerniciousHabitEnum } from '~types';

export const PERNICIOUS_HABITS_OPTIONS: SelectOption<PerniciousHabitEnum>[] = [
  { label: I18n.t('perniciousHabits.daily'), value: 'daily' },
  { label: I18n.t('perniciousHabits.weekly'), value: 'weekly' },
  { label: I18n.t('perniciousHabits.monthly'), value: 'monthly' },
  { label: I18n.t('perniciousHabits.yearly'), value: 'yearly' },
  { label: I18n.t('perniciousHabits.never'), value: 'never' },
  { label: I18n.t('other'), value: 'other' },
];

export const PREGNANCIES_OPTIONS: SelectOption<PregnancyOutcomeEnum>[] = [
  { label: I18n.t('pregnancies.birth'), value: 'birth' },
  { label: I18n.t('pregnancies.miscarriage'), value: 'miscarriage' },
  { label: I18n.t('pregnancies.abortion'), value: 'abortion' },
  { label: I18n.t('other'), value: 'other' },
];

export const fieldsWithDefaultValues = [
  'medications',
  'drugAllergies',
  'allergies',
  'surgeries',
  'hospitalization',
  'familyHistory',
] as const;
