import { cypressUtilities } from '@almond/utils';
import { useLocalSearchParams } from 'expo-router';

import env, { devEnv } from '~env';

export const getArtemisRedirectUrl = (artemisRedirectUrl?: string) => {
  return (
    artemisRedirectUrl || (cypressUtilities.isCypressRunning() ? devEnv.ARTEMIS_FALLBACK_URL : env.ARTEMIS_FALLBACK_URL)
  );
};

export const useArtemisRedirectUrl = () => {
  const searchParams = useLocalSearchParams();

  return getArtemisRedirectUrl(searchParams.artemis_redirect_url);
};
