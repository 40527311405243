import { useCallback } from 'react';

import { useLocalSearchParams } from 'expo-router';

import { profilesApi } from '~modules/api';

import { log, logAndCapture } from '../logger';

import type { FormValues } from '@almond/ui';
import type { InsuranceIdFormValues } from '~types';

const useAddInsuranceId = (): ((values: FormValues<InsuranceIdFormValues>) => Promise<void>) => {
  const searchParams = useLocalSearchParams();

  return useCallback(
    async (values: FormValues<InsuranceIdFormValues>) => {
      if (!searchParams.profile_uuid) {
        return logAndCapture("`profileUuid` is not specified, so can't add the insurance id.");
      }

      if (!values.id) return log("The insurance id is not specified, so can't add the insurance id.");

      await profilesApi.addInsuranceId(searchParams.profile_uuid)({ insuranceMemberId: values.id });
    },
    [searchParams.profile_uuid]
  );
};

export default useAddInsuranceId;
