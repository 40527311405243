import type { LocationAttributes } from '../location';

export type AccountSetupState = {
  pharmacy?: LocationAttributes;
  referral?: string;
  referrerByName?: string;
};

export type AddressState = {
  line1?: string;
  line2?: string;
  city?: string;
  stateCode?: string;
  zip?: string;
};
