import { useEvent } from '@almond/utils';

import { patientsApi } from '~modules/api';
import { useAsync } from '~modules/promises';

import { SANDBOX_RESPONSE_ID } from '../config';

export const useCompleteTypeform = () => {
  const { doAsync, isLoading } = useAsync();

  const completeTypeform = useEvent(
    (response: { responseId: string; formId: string }, manualFields?: readonly { title: string; value: string }[]) => {
      // Loading indicator is handled by the widget. Until we
      // call dispatch(), the <iframe> will contain a loading
      // indicator.
      const toCall = async () => {
        if (response.responseId !== SANDBOX_RESPONSE_ID) {
          // If using MockWidget or in sandbox mode,
          // don't trigger the API request.
          await patientsApi.submitTypeformResponse({
            formId: response.formId,
            responseId: response.responseId as string,
            manualFields: manualFields ?? [],
          });
        }
      };

      doAsync(toCall);
    }
  );

  return {
    completeTypeform,
    isLoadingCompleteTypeform: isLoading,
  };
};
