import React from 'react';

import { RadioGroup, useController, useFormContext } from '@almond/ui';

import type { RadioGroupItem, RadioGroupProps } from '@almond/ui';

export type ConnectedRadioGroupProps<T extends string, Item extends RadioGroupItem<T>> = Omit<
  RadioGroupProps<T, Item>,
  'selectedItem' | 'onSelect'
> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedRadioGroup = <T extends string, Item extends RadioGroupItem<T>>(
  props: ConnectedRadioGroupProps<T, Item>
) => {
  const { name, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, value, ...restField } = field;

  return <RadioGroup {...restField} {...restProps} selectedItem={value} onSelect={onChange} />;
};

export default ConnectedRadioGroup;
