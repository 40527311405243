import { yupResolver } from '@hookform/resolvers/yup';
import { useForm as useFormLibrary } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { emptyAtom } from '~modules/state';

import type { UseFormReturn } from 'react-hook-form';
import type { RecoilState } from 'recoil';
import type { ObjectSchema } from 'yup';

type UseFormArgs<State extends Record<string, any>> = {
  atom?: RecoilState<State>;
  validationSchema?: ObjectSchema<Record<string, any>>;
};

export function useForm(args: UseFormArgs<any>): UseFormReturn<Record<string, unknown>>;
export function useForm<State extends Record<string, any>>(args: UseFormArgs<State>): UseFormReturn<State>;
export function useForm(args: UseFormArgs<any>): UseFormReturn<Record<string, unknown>> {
  const { atom, validationSchema } = args;
  const state = useRecoilValue(atom || emptyAtom);

  // Should match useForm() call in <Form /> component
  return useFormLibrary({
    mode: 'onSubmit',
    // This option allows you to configure when inputs with errors get re-validated after submit.
    // By default, validation is only triggered during an input change.
    reValidateMode: 'onSubmit',
    defaultValues: state,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });
}
