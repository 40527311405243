import type { AccountSetupState, AddressState } from '~types';

export const ACCOUNT_SETUP_STATE_KEY = 'accountSetupState';
export const ADDRESS_STATE_KEY = 'addressState';

export const DEFAULT_ACCOUNT_SETUP_STATE: AccountSetupState = {
  referral: '',
  referrerByName: '',
};

export const DEFAULT_ADDRESS_STATE: AddressState = {
  line1: '',
  line2: '',
  city: '',
  stateCode: '',
  zip: '',
};
