import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import { InfoMessage } from '~modules/ui';

import SecondaryMessage from './SecondaryMessage';

import themedStyles from './styles';

export const VisitCostMessage = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <InfoMessage
      title={t('visitCostMessage.withoutInsuranceTitle')}
      description={
        <View>
          <Text style={[styles.text, styles.messageDescription]}>
            {t('visitCostMessage.withoutInsuranceDescription')}
          </Text>
          <SecondaryMessage />
          <Text style={[styles.text, styles.bottom]}>{t('visitCostMessage.bottom')}</Text>
        </View>
      }
    />
  );
};
