import type { InsuranceCarrierChoicesEnum } from '@almond/api-types';
import type { SelectInputValue } from '@almond/ui';

export type DemographicFormValues = {
  firstName: string;
  preferredFirstName: string;
  lastName: string;
  birthday: string;
  email: string;
  phone: string;
  isOptedIntoSms: boolean;
};

export type InsuranceFormValues = {
  carrier: Required<SelectInputValue<InsuranceCarrierChoicesEnum>>;
};

export type InsuranceIdFormValues = {
  id?: string;
  card: { front: string; back?: string };
};

export type PhoneVerificationFormValues = {
  otp: string;
};
