import { I18n } from '@almond/localization';

import type { ErrorResponse } from '~types';

export const parseErrorResponse = (errorResponse: ErrorResponse): string => {
  const filteredErrors = errorResponse.errors.filter(error => error.error_code === 'display_to_user');

  // Display all the errors that should be displayed to the user, otherwise
  // display the default error.
  if (!filteredErrors.length) return I18n.t('defaultError');

  return filteredErrors.map(error => error.message).join('; ');
};

export const parseErrorCode = (errorResponse: ErrorResponse): string | undefined => {
  if (!errorResponse.errors?.length) return;

  return errorResponse.errors[0].error_code;
};

export const getErrorMessageWithFallback = (message: string | undefined, originalError: unknown): string => {
  if (message) {
    return message;
  }

  if (!originalError || typeof originalError !== 'object') {
    return 'unknown';
  }

  if ('message' in originalError && typeof originalError.message === 'string') {
    return originalError.message as string;
  }

  if ('toString' in originalError && typeof originalError.toString === 'function') {
    return originalError.toString();
  }

  return 'unknown';
};
