import React, { useMemo } from 'react';
import { View } from 'react-native';

import { dateAndTimeUtilities } from '@almond/date-and-time';
import { useTheme } from '@almond/ui';
import dayjs from 'dayjs';

import { Header } from './Header';
import { Week } from './Week';
import { WeekHeader } from './WeekHeader';

import themedStyles from './styles';

import type { Dayjs } from 'dayjs';
import type { StyleProp, ViewStyle } from 'react-native';

export type CalendarProps = {
  date: Dayjs;
  onDateSelect?: (date: Dayjs) => void;
  month?: Dayjs;
  availableDates?: { [key in string]?: unknown };
  isLoading?: boolean;
  style?: StyleProp<ViewStyle>;
};

export const Calendar: React.FC<CalendarProps> = props => {
  const [styles] = useTheme(themedStyles);

  const month = props.month || dayjs().startOf('month');
  const weeks = useMemo(() => dateAndTimeUtilities.generateWeeks(month), [month]);

  return (
    <View style={[styles.calendarWrapper, props.style]}>
      <Header month={month} />
      <WeekHeader />
      {weeks.map((week, index) => (
        <Week
          key={index}
          week={week}
          selectedDate={props.date}
          availableDates={props.availableDates}
          onDayPress={props.onDateSelect}
          isFirst={index === 0}
          isLast={index === weeks.length - 1}
          isLoading={props.isLoading}
        />
      ))}
    </View>
  );
};

export * from './styles';
