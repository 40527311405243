import { useCallback } from 'react';

import { patientsApi } from '~modules/api';
import { useSendEmail } from '~modules/emails';
import { useRouteNavigation } from '~modules/routing';

import type { CONFIRM_PURCHASE_PAGE_NAME } from '~types';

export const useCreateAccount = () => {
  const { dispatch } = useRouteNavigation<typeof CONFIRM_PURCHASE_PAGE_NAME>();
  const sendEmail = useSendEmail();

  return useCallback(async () => {
    await patientsApi.createAccount({
      welcomeMessageType: 'baseline_welcome_message',
      conversationStatus: 'ongoing',
      extraTags: ['Baseline Program'],
    });
    await sendEmail();

    try {
      const insurance = await patientsApi.retrieveInsurance();

      await dispatch('submit', insurance);
    } catch (e) {
      await dispatch('submit', { memberId: undefined, isAccepted: false });
    }
  }, [dispatch, sendEmail]);
};
