import React from 'react';

import { useTranslation } from '@almond/localization';
import { useFormContext } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { errorsAtom } from '~modules/state';
import { Error as NativeError } from '~modules/ui';

export type ErrorProps = { formError?: string; backendError?: string };

const Error: React.FC<ErrorProps> = props => {
  const { t } = useTranslation();
  const errorsState = useRecoilValue(errorsAtom);
  const formContext = useFormContext();
  const formError = props.formError || t('formError');
  const hasValidationErrors = !!Object.keys(formContext.formState.errors).length;
  const backendError = props.backendError || errorsState.error;
  const currentError = hasValidationErrors ? `* ${formError}` : backendError;
  const isVisible = hasValidationErrors || !!backendError;

  if (!isVisible) return null;

  return <NativeError testID="FormError">{currentError}</NativeError>;
};

export default Error;
