import { atom } from 'recoil';

import { AUTH_STATE_KEY, DEFAULT_AUTH_STATE, ROUTING_ALREADY_INITIALIZED_KEY } from './config';

export const authAtom = atom({
  key: AUTH_STATE_KEY,
  default: DEFAULT_AUTH_STATE,
});

export const routingAlreadyInitializedAtom = atom({ key: ROUTING_ALREADY_INITIALIZED_KEY, default: false });
