import type { PatientProductOut, PatientTypeformOut } from '@almond/api-types';

export function isBaselineProduct(product: string | undefined | null): boolean {
  return product === 'baseline';
}

export function hasBaselineProduct(products: readonly PatientProductOut[] | undefined): boolean {
  if (!products) {
    return false;
  }

  return products.some(product => isBaselineProduct(product.almondProductKey));
}

export function isBaselineIntakeFormCompleted(intakeForms: readonly PatientTypeformOut[] | undefined): boolean {
  if (!intakeForms) {
    return false;
  }

  return intakeForms.some(form => form.formType === 'baseline_intake');
}
