import React from 'react';

import { Table, useController, useFormContext } from '@almond/ui';

import type { TableProps } from '@almond/ui';

export type ConnectedTableProps = Omit<TableProps, 'value' | 'onSelect'> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedTable: React.FC<ConnectedTableProps> = props => {
  const { name, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, ...restField } = field;

  return <Table {...restField} {...restProps} onSelect={id => onChange(id)} />;
};

export default ConnectedTable;
