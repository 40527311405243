import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    alignItems: 'center',
    marginBottom: sizes.SPACING_S,
  },
  textInput: {
    height: 60,
  },
  textInputContainer: {
    width: '100%',
  },
  textInputComponentContainer: {
    width: '100%',
  },
  // Remove space between input and list
  helperTextContainerStyle: {
    display: 'none',
  },
  divider: {
    width: '100%',
    height: 1,
  },
  description: {
    color: theme.darkText,
  },
  icon: {
    marginTop: sizes.SPACING_M,
  },
  results: {
    ...getShadow(),
    width: '100%',
    borderBottomLeftRadius: sizes.SPACING_S,
    borderBottomRightRadius: sizes.SPACING_S,
    overflow: 'hidden',
    marginTop: -sizes.SPACING_S,
  },
  empty: {
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: sizes.SPACING_XXL,
  },
  listView: {
    height: 'auto',
  },
}));
