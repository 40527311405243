import { useEffect } from 'react';

import { usePathname } from 'expo-router';

import { log } from '../logger';

export const useLogCurrentPage = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (!pathname) return;

    log(`Current page:: ${pathname}`);
  }, [pathname]);
};
