import { useLocalSearchParams } from 'expo-router';
import useSWRImmutable from 'swr/immutable';

import { PRODUCTS_PATH, productsApi } from '~modules/api';

export const useStripeProduct = () => {
  const { product } = useLocalSearchParams();
  const { data, ...restResponse } = useSWRImmutable(PRODUCTS_PATH, productsApi.getProducts);

  return {
    data: data?.[product || 'gynecology_membership'],
    ...restResponse,
  };
};
