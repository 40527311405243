import { I18n } from '@almond/localization';

import env from '~env';
import { dataTransformsUtilities } from '~modules/dataTransforms';

import type { AxiosRequestConfig } from 'axios';

const AXIOS_REQUEST_TIMEOUT = 8000;

// Specific API requests are known to take longer than
// the default timeout (especially those which interact with external services)
// so let's double our timeout for only those requests.
export const AXIOS_LONG_REQUEST_TIMEOUT = 16000;

export const axiosConfig: AxiosRequestConfig = {
  baseURL: env.API_BASE_URL,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  headers: { 'Content-Type': 'application/json' },
  timeout: AXIOS_REQUEST_TIMEOUT,
  timeoutErrorMessage: I18n.t('error.timeout'),
  paramsSerializer: dataTransformsUtilities.serializeParameters,
};
export const API_PATH = '/api/v2/' as const;
export const UUID_TEMPLATE = '{patient_uuid}' as const;
export const PATIENTS_PATH = `${API_PATH}patients/${UUID_TEMPLATE}/` as const;
