import React from 'react';
import { View } from 'react-native';

import { Text, useAdaptive, useBrowserTypeMap, useTheme } from '@almond/ui';

import LocalImage from '../LocalImage';

import themedStyles from './styles';

import type { Images } from '~assets';
import type { StyleProp, ViewStyle } from 'react-native';

type ImageDimensions = {
  width: number;
  height: number;
};

export type MessageWithImageV2Props = {
  image: keyof Images;
  imageDimensions: ImageDimensions;
  mobileImageDimensions: ImageDimensions;
  title: string;
  subtitle: string;
  footer?: React.ReactNode;
  isMessageOverImage?: boolean;
  messageContainerStyle?: StyleProp<ViewStyle>;
};

export const MessageWithImageV2 = (props: MessageWithImageV2Props) => {
  const {
    image,
    imageDimensions,
    mobileImageDimensions,
    title,
    subtitle,
    footer,
    isMessageOverImage,
    messageContainerStyle,
  } = props;
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const [styles] = useTheme(themedStyles);
  const titleSize = useAdaptive('xxxl', 'xxl');
  const subtitleSize = useAdaptive('m', 's');

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      {isMobile && <LocalImage source={image} style={[styles.imageMobile, mobileImageDimensions]} />}
      <View
        style={[
          styles.messageContainer,
          isMobile && styles.messageContainerMobile,
          isMessageOverImage && styles.messageOver,
          messageContainerStyle,
        ]}
      >
        <Text family="PlayfairDisplay" fontStyle="bold" size={titleSize} style={isMobile && styles.center}>
          {title}
        </Text>
        <Text style={isMobile && styles.center} size={subtitleSize}>
          {subtitle}
        </Text>
        {isDesktop && footer && <View style={styles.footerDesktop}>{footer}</View>}
      </View>
      {isDesktop && <LocalImage source={image} style={[styles.image, imageDimensions]} />}
      {isMobile && footer}
    </View>
  );
};
