import { useCallback } from 'react';

import { dateAndTimeParsers } from '@almond/date-and-time';
import dayjs from 'dayjs';

import { profilesApi } from '../../api';

import type { FormValues } from '@almond/ui';
import type { DemographicFormValues } from '~types';

export const transformBirthday = (birthday: string) => {
  // We don't need to transform MM/DD/YYYY format, because it can be parsed correctly.
  if (birthday.length === 10) {
    return birthday;
  }

  // At this point we're sure that the birthday format is MM/DD/YY.
  // Extracting the year from the birthday.
  const year = Number.parseInt(birthday.slice(birthday.length - 2), 10);
  const currentYearFull = dayjs().get('year');
  const currentYear = currentYearFull % 100;
  const currentCentury = Math.floor(currentYearFull / 100);

  // Default to the previous century.
  let century = currentCentury - 1;

  // If the birthday year is between 0 and the current year (inclusive), use the current century.
  if (year <= currentYear) {
    century = currentCentury;
  }

  return `${birthday.substring(0, birthday.length - 2)}${century}${year.toString().padStart(2, '0')}`;
};

const useCreateProfile = () => {
  return useCallback(async (values: FormValues<DemographicFormValues>) => {
    const smsOptInStatus = values.isOptedIntoSms ? 'opted_in' : 'opted_out';
    const response = await profilesApi.createProfile({
      firstName: values.firstName,
      preferredFirstName: values.preferredFirstName === values.firstName ? undefined : values.preferredFirstName,
      lastName: values.lastName,
      email: values.email,
      birthday: dateAndTimeParsers.toRemoteDate(transformBirthday(values.birthday)),
      phone: values.phone,
      smsOptInStatus,
    });

    return { profile_uuid: response.uuid, email: values.email };
  }, []);
};

export default useCreateProfile;
