import React from 'react';

import { useTranslation } from '@almond/localization';
import { Link } from '@almond/ui';

import { useArtemisRedirectUrl } from '~modules/integration';

export const Footer = () => {
  const { t } = useTranslation();
  const artemisRedirectUrl = useArtemisRedirectUrl();

  return <Link url={`${artemisRedirectUrl}/messages`}>{t('tellUsMore.messageCareTeam')}</Link>;
};
