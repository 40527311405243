import React, { useEffect } from 'react';

import { Button } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { userAtom } from '~modules/state';

import { useAuth0 } from '../../hooks';

import type { UserDetail } from '@almond/api-types';
import type { RedirectLoginOptions } from '~types';

export type AuthButtonProps = React.PropsWithChildren<{
  onIsAuthenticated?: (user?: UserDetail) => void;
  options?: RedirectLoginOptions;
  isLoading?: boolean;
  testID?: string;
}>;

const AuthButton: React.FC<AuthButtonProps> = props => {
  const { onIsAuthenticated } = props;
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const userState = useRecoilValue(userAtom);

  useEffect(() => {
    if (isLoading || !isAuthenticated || !onIsAuthenticated) return;

    onIsAuthenticated(userState.user);
  }, [isAuthenticated, isLoading, onIsAuthenticated, userState.user]);

  return (
    <Button
      isLoading={props.isLoading || isLoading}
      isDisabled={props.isLoading || isLoading}
      onPress={() => loginWithRedirect(props.options)}
      testID={props.testID}
    >
      {props.children}
    </Button>
  );
};

export default AuthButton;
