import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  container: {
    rowGap: sizes.SPACING_S,
  },

  input: {
    rowGap: sizes.SPACING_S,
  },
}));
