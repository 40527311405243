import React from 'react';

import { BaseButton, useTheme } from '@almond/ui';

import { ChevronRight } from '~assets';

import themedStyles from './styles';

export type ArrowButtonProps = {
  direction: 'left' | 'right';
  onPress: () => void;
  testID?: string;
};

export const ArrowButton = (props: ArrowButtonProps) => {
  const [styles] = useTheme(themedStyles);

  return (
    <BaseButton
      role="button"
      onPress={props.onPress}
      style={[styles.arrowButton, styles[props.direction]]}
      testID={props.testID}
    >
      <ChevronRight />
    </BaseButton>
  );
};
