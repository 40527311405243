import React from 'react';

import { Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

export type SecondaryMessageSubtitleProps = {
  left: string;
  right: string;
};

const SecondaryMessageSubtitle: React.FC<SecondaryMessageSubtitleProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <Text style={styles.text}>
      {props.left}
      <Text style={styles.text}>{props.right}</Text>
    </Text>
  );
};

export default SecondaryMessageSubtitle;
