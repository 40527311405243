.links {
  padding: 20px;
  width: 100%;
  height: 100%;
  background: var(--color-light-accent);
}

.iframe {
  border: none;
  width: 100%;
  height: 100%;
}
