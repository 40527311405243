import { atom } from 'recoil';

import {
  ACCOUNT_SETUP_STATE_KEY,
  ADDRESS_STATE_KEY,
  DEFAULT_ACCOUNT_SETUP_STATE,
  DEFAULT_ADDRESS_STATE,
} from './config';

export const accountSetupAtom = atom({
  key: ACCOUNT_SETUP_STATE_KEY,
  default: DEFAULT_ACCOUNT_SETUP_STATE,
});

export const addressAtom = atom({
  key: ADDRESS_STATE_KEY,
  default: DEFAULT_ADDRESS_STATE,
});
