import React from 'react';

import { useTranslation } from '@almond/localization';
import { useBrowserTypeMap, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { Container } from '~modules/layout';
import { isBaselineProduct } from '~modules/product';
import { MessageWithImageV2 } from '~modules/ui';

import themedStyles from './styles';

export const ConfirmEmailPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { isDesktop } = useBrowserTypeMap();
  const searchParams = useLocalSearchParams();
  const isBaseline = isBaselineProduct(searchParams.product);
  const subtitle = t('confirmEmail.message.subtitle', {
    buttonTitle: isBaseline
      ? t('confirmEmail.message.baselineEmailButton')
      : t('confirmEmail.message.gynecologyEmailButton'),
  });

  return (
    <Container id="confirmEmail" contentContainerStyle={styles.contentContainer} shouldHideCloseButton>
      <MessageWithImageV2
        image="womanLookingAhead"
        imageDimensions={{ width: 463, height: 408 }}
        mobileImageDimensions={{ width: 201, height: 177 }}
        title={t('confirmEmail.message.title')}
        subtitle={subtitle}
        messageContainerStyle={isDesktop && styles.messageContainer}
      />
    </Container>
  );
};
