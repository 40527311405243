import React, { useCallback, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useSetRecoilState } from 'recoil';

import { MainForm, SelectInputGroup } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { PREGNANCIES_OPTIONS } from '../../config';
import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HistoryState, PREGNANCIES_PAGE_NAME } from '~types';

export const PregnanciesPage: React.FC = () => {
  const { t } = useTranslation();
  const setHistoryState = useSetRecoilState(historyAtom);
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const [pregnanciesLength, setPregnanciesLength] = useState<number>();
  const { dispatch } = useRouteNavigation<typeof PREGNANCIES_PAGE_NAME>();

  const handleLengthChange = useCallback((length: number) => {
    setPregnanciesLength(length);
  }, []);

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState> | null) => {
      if (!formValues) {
        setHistoryState(prevState => ({ ...prevState, pregnancies: undefined }));
      }

      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'pregnancies', 'pregnancies', []);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, setHistoryState, saveHistory, dispatch]
  );

  return (
    <MainForm
      id="pregnancies"
      title={t('pregnancies.title')}
      submitButtonTitle={t('continue')}
      onSubmit={handleSubmit}
      secondaryButtonTitle={t('pregnancies.skipTitle')}
      onSecondaryPress={() => handleSubmit(null)}
      atom={historyAtom}
      requiredFields={['pregnancies']}
      scrollToBottomTrigger={pregnanciesLength}
      isLoading={isLoading}
    >
      <SelectInputGroup
        name="pregnancies"
        options={PREGNANCIES_OPTIONS}
        keyTitle={t('pregnancies.year')}
        valueTitle={t('pregnancies.outcome')}
        keyTestID="Year"
        valueTestID="Outcome"
        onLengthChange={handleLengthChange}
      />
    </MainForm>
  );
};
