import { atom } from 'recoil';

import {
  DEFAULT_PATIENT_PROGRESS_STATE,
  DEFAULT_PATIENT_STATE,
  LOADED_PATIENT_UUID_STATE_KEY,
  PATIENT_PROGRESS_STATE_KEY,
  PATIENT_STATE_KEY,
} from './config';

export const patientAtom = atom({
  key: PATIENT_STATE_KEY,
  default: DEFAULT_PATIENT_STATE,
});

export const patientProgressAtom = atom({
  key: PATIENT_PROGRESS_STATE_KEY,
  default: DEFAULT_PATIENT_PROGRESS_STATE,
});

export const loadedPatientUuidAtom = atom<undefined | string>({
  key: LOADED_PATIENT_UUID_STATE_KEY,
  default: undefined,
});
