import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Modal, ScrollView, sizes, Text, useTheme } from '@almond/ui';

import { VisitReasonIcon } from '~assets';

import { useSchedulingVisitReasonsModal } from '../../hooks';

import { modalThemedStyles } from './styles';

export type VisitReasonModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export const VisitReasonModal: React.FC<VisitReasonModalProps> = props => {
  const { isVisible, onClose } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(modalThemedStyles);
  const { text, visitOutcome, isNewMember, visitReason } = useSchedulingVisitReasonsModal();

  if (!visitReason || !visitOutcome || !text) {
    return null;
  }

  return (
    <Modal isVisible={isVisible} onRequestClose={onClose} containerStyle={styles.container} showClose>
      <ScrollView>
        <View style={styles.content}>
          <View style={styles.iconContainer}>
            <VisitReasonIcon visitReason={visitReason.code} width={sizes.SPACING_XXL} height={sizes.SPACING_XXL} />
          </View>
          <View style={styles.titleContainer}>
            <Text fontStyle="bold" size="xxxl" style={[styles.title, styles.center]}>
              {text.visitReasonTitle}
            </Text>
            <Text size="m" style={styles.center}>
              {`${t(`visitType.${visitOutcome?.isTelehealth ? 'videoVisit' : 'officeVisit'}`)}${
                visitOutcome?.providerTypes
                  ? ` ${t(`scheduling.visitReasonModal.withProvider`, { provider: text.providerTitleText })}`
                  : ''
              }`}
            </Text>
          </View>
          <View style={styles.bodyWrapper}>{text.modalBody}</View>
          <Text size="m" style={styles.paragraph}>
            {t(`scheduling.visitReasonModal.endSentence.${isNewMember ? 'newMember' : 'returningMember'}`)}
          </Text>
        </View>

        <Button style={styles.button} onPress={onClose}>
          {t('done')}
        </Button>
      </ScrollView>
    </Modal>
  );
};
