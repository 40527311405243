import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { patientsApi } from '~modules/api';
import { useAuth0 } from '~modules/auth/hooks';
import {
  useCreateInvoice,
  useCreatePaymentMethod,
  useCreateSubscription,
  useGetOrCreateStripeCustomer,
} from '~modules/payment';
import { isBaselineProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { creditCardAtom, profileProgressAtom } from '~modules/state';

import type { ProductOut } from '@almond/api-types';
import type { CREDIT_CARD_PAGE_NAME } from '~types';

export type UseSubmitCreditCardArgs = {
  product: ProductOut;
  resetErrors: () => void;
};

const useSubmitCreditCard = (args: UseSubmitCreditCardArgs) => {
  const { doAsync, isLoading } = useAsync();
  const { resetErrors, product } = args;
  const { dispatch, routingConfigId } = useRouteNavigation<typeof CREDIT_CARD_PAGE_NAME>();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const createPaymentMethod = useCreatePaymentMethod();
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const getOrCreateStripeCustomer = useGetOrCreateStripeCustomer();
  const createSubscription = useCreateSubscription();
  const createInvoice = useCreateInvoice();
  const searchParams = useLocalSearchParams();
  const isOnboarding = routingConfigId === 'postBookingAdmin' || routingConfigId === 'postBookingSelf';

  const submitCreditCard = useRecoilCallback(
    recoilInterface => async () => {
      const toCall = async (): Promise<void> => {
        resetErrors();

        recoilInterface.set(creditCardAtom, prevState => ({ ...prevState, product }));

        await getOrCreateStripeCustomer();

        const paymentMethod = await createPaymentMethod();

        recoilInterface.set(profileProgressAtom, prevState => ({ ...prevState, creditCard: true }));
        trackAnalyticsEvent('cc_added');

        // If we're in one of our post booking routing configs,
        // we need to charge the patient on this page itself.
        if (isOnboarding) {
          // We're already on the credit card page so if creating the subscription / invoice
          // causes a payment error, we simply need to stay on the current page
          const createPayment = isBaselineProduct(searchParams.product) ? createInvoice : createSubscription;

          await createPayment(paymentMethod);

          try {
            // Already booked - must be in admin-booked post-booking flow
            const insurance = await patientsApi.retrieveInsurance();

            return dispatch('submit', insurance);
          } catch (e) {
            return dispatch('submit', { memberId: undefined, isAccepted: false });
          }
        }

        if (isAuthenticated) {
          // One of the following:
          // 1. Admin booked (admin is logged in)
          // 2. Resubscribing member
          // 3. New member with a declined card
          return dispatch('submit');
        }

        // New patient is booking - redirect to signup
        await loginWithRedirect({
          authorizationParams: { screen_hint: 'signup' },
          dispatch: 'submit',
          restoreRecoilState: true,
        });
      };

      doAsync(toCall);
    },
    [
      createInvoice,
      createPaymentMethod,
      createSubscription,
      dispatch,
      doAsync,
      getOrCreateStripeCustomer,
      isAuthenticated,
      isOnboarding,
      loginWithRedirect,
      product,
      resetErrors,
      searchParams.product,
      trackAnalyticsEvent,
    ]
  );

  return { submitCreditCard, isLoading };
};

export default useSubmitCreditCard;
