import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  container: {
    minHeight: 280,
    paddingVertical: sizes.SPACING_XL,
    paddingHorizontal: sizes.SPACING_XXL,
    justifyContent: 'center',
    rowGap: sizes.SPACING_XL,
  },
  title: {
    textAlign: 'center',
  },
  link: {
    textAlign: 'center',
    marginTop: sizes.SPACING_S,
  },
}));
