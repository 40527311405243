import '~env';
import '@almond/localization';
import '@almond/date-and-time/dist/extendDayjs';
import 'expo-router/entry';

import { initMaterialIcons } from '@almond/ui';

import { materialIcons } from '~assets';

initMaterialIcons(materialIcons);
