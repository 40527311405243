import { I18n } from '@almond/localization';

import { patientsApi } from '~modules/api';
import { dataTransformsUtilities } from '~modules/dataTransforms';

import { fieldsWithDefaultValues } from '../config';

import type { PatientHistoryStateEnum } from '@almond/api-types';
import type { FormValues } from '@almond/ui';
import type { HistoryState } from '~types';

const saveHistory = async (
  values: FormValues<HistoryState> | null,
  currentState: PatientHistoryStateEnum,
  key?: keyof HistoryState,
  defaultValue?: any
): Promise<void> => {
  let updatedValues: FormValues<HistoryState> | null = values ? { ...values } : null;

  // If the user pressed on the secondary button which means that she skipped the current step, then we want to set
  // a default value instead of undefined (which will be `Patient did not fill out` on the backend).
  if (!values && key && defaultValue) {
    updatedValues = { [key]: defaultValue };
  }

  if (!updatedValues) {
    throw new Error('Missing default history values.');
  }

  const data = {
    ...updatedValues,
    pregnancies:
      updatedValues.pregnancies &&
      dataTransformsUtilities.transformSelectInputValuesToApiEnumList(updatedValues.pregnancies),
    needHealthRecords: !!updatedValues.needHealthRecords,
    doctorInfo: updatedValues.needHealthRecords ? updatedValues.doctorInfo : undefined,
    perniciousHabits:
      updatedValues.perniciousHabits &&
      dataTransformsUtilities.transformSelectInputValuesToApiEnumObject(updatedValues.perniciousHabits),
    currentState,
  };

  // If the key isn't specified, then the whole `data` object will be synced with the backend instead of a single field.
  // In this case, all the fields with default values (when the secondary button was pressed) will be empty.
  // That's because we don't store the default values inside the local state, and only sync them with the backend.
  // So, we need to restore the default values.
  if (!key) {
    fieldsWithDefaultValues.forEach(field => {
      data[field] = data[field] || I18n.t(`${field}.skipTitle`);
    });
  }

  // Only save the data for the key that was changed
  await patientsApi.saveHistory(key ? { [key]: data?.[key] ?? null, currentState } : data);
};

const useSaveHistory = (): typeof saveHistory => saveHistory;

export default useSaveHistory;
