import React from 'react';
import { View } from 'react-native';

import { Modal, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { ModalProps } from '@almond/ui';
import type { StyleProp, ViewStyle } from 'react-native';

export type BaseModalProps = Omit<ModalProps, 'showClose'> & {
  title: string;
  subtitle?: string;
  titlesStyle?: StyleProp<ViewStyle>;
};

export const BaseModal = (props: BaseModalProps) => {
  const { title, subtitle, children, titlesStyle, ...restProps } = props;
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <Modal showClose containerStyle={[styles.modalContainer, isMobile && styles.modalContainerMobile]} {...restProps}>
      <View style={titlesStyle}>
        <Text style={[styles.selfCenter, styles.centerText]} size="xxxl" fontStyle="medium">
          {title}
        </Text>
        {subtitle && (
          <Text style={[styles.selfCenter, styles.subtitle, styles.centerText]} size="m">
            {subtitle}
          </Text>
        )}
      </View>
      {children}
    </Modal>
  );
};
