import { cypressUtilities } from '@almond/utils';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { authAtom } from '../auth';
import { logError } from '../logger';
import { atomsToSerialize } from './config';

import type { AppState } from '~types';
import type { RecoilState } from 'recoil';

/**
 * Used for restoring a state object into the above atoms. This is useful when redirecting back
 * to the SPA from elsewhere after serializing the state with getState() above. This happens when
 *  logging in or creating accounts with Auth0.
 * @param setAtomValue An atom setter, can be gotten from useRecoilCallback(), recoilInterface.set
 * @param state An object to restore, with atom keys as keys and values to serialize as values
 */
const setState = async (
  setAtomValue: (atom: RecoilState<any>, value: unknown) => void,
  state: Record<string, unknown>
) => {
  const entries = Object.entries(state);

  if (entries.length !== atomsToSerialize.length) {
    // Don't throw, but record the error. This may happen when changing the list of atoms above.
    // A user can use an old version of the app when redirecting to Auth0, and be redirected back
    // into a new version of the app.
    logError(
      new Error(`Trying to restore ${entries.length} state values into ${atomsToSerialize.length} available atoms.`)
    );
  }

  Object.entries(state).forEach(([atomKey, value]) => {
    const atom = atomsToSerialize.find(s => s.key === atomKey);

    if (!atom) {
      // Don't throw, but record the error. This may happen when changing the list of atoms above.
      // A user can use an old version of the app when redirecting to Auth0, and be redirected back
      // into a new version of the app.
      return logError(new Error(`Found state for ${atomKey}, but no atom found for that state`));
    }

    setAtomValue(atom, value);
  });
};

const useSetAppState = (): ((appState: AppState) => void) => {
  const setAuthState = useSetRecoilState(authAtom);

  return useRecoilCallback(
    recoilInterface => (appState: AppState) => {
      const { recoilState, mockedDataLayer } = appState;

      if (recoilState) {
        const { authState, ...restRecoilState } = recoilState;

        setAuthState(authState);

        setState(recoilInterface.set, restRecoilState);
      }

      if (cypressUtilities.isCypressRunning() && mockedDataLayer) {
        window.mockedDataLayer = mockedDataLayer;
      }
    },
    [setAuthState]
  );
};

export default useSetAppState;
