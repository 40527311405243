{
  "nm": "Comp 1",
  "ddd": 0,
  "h": 844,
  "w": 390,
  "meta": { "g": "@lottiefiles/toolkit-js 0.33.2" },
  "layers": [
    {
      "ty": 4,
      "nm": "heart Outlines 8",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40, 40, 100], "t": 64 },
            { "s": [120, 120, 100], "t": 168 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.855 },
              "s": [138, 879, 0],
              "t": 64,
              "ti": [-38.167, 97.667, 0],
              "to": [-36.167, -45.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.191 },
              "i": { "x": 0.833, "y": 0.839 },
              "s": [37, 598, 0],
              "t": 89,
              "ti": [0.5, 77.667, 0],
              "to": [37.69, -96.448, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.174 },
              "i": { "x": 0.833, "y": 0.829 },
              "s": [141, 337, 0],
              "t": 119,
              "ti": [3.124, 64.593, 0],
              "to": [-0.5, -77.667, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.161 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [34, 132, 0],
              "t": 146,
              "ti": [0, 0, 0],
              "to": [-4.667, -96.5, 0]
            },
            { "s": [113, -50, 0], "t": 168 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.962 }, "s": [-20], "t": 64 },
            { "o": { "x": 0.167, "y": -0.091 }, "i": { "x": 0.833, "y": 1.095 }, "s": [0], "t": 89 },
            { "o": { "x": 0.167, "y": 0.062 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-10], "t": 119 },
            { "s": [15], "t": 168 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 90, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9137, 0.4745, 0.3922], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "heart Outlines 4",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40, 40, 100], "t": 12 },
            { "s": [120, 120, 100], "t": 116 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.855 },
              "s": [138, 879, 0],
              "t": 12,
              "ti": [-38.167, 97.667, 0],
              "to": [-36.167, -45.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.191 },
              "i": { "x": 0.833, "y": 0.839 },
              "s": [37, 598, 0],
              "t": 37,
              "ti": [0.5, 77.667, 0],
              "to": [37.69, -96.448, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.174 },
              "i": { "x": 0.833, "y": 0.829 },
              "s": [141, 337, 0],
              "t": 67,
              "ti": [3.124, 64.593, 0],
              "to": [-0.5, -77.667, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.161 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [34, 132, 0],
              "t": 94,
              "ti": [0, 0, 0],
              "to": [-4.667, -96.5, 0]
            },
            { "s": [113, -50, 0], "t": 116 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.962 }, "s": [-20], "t": 12 },
            { "o": { "x": 0.167, "y": -0.091 }, "i": { "x": 0.833, "y": 1.095 }, "s": [0], "t": 37 },
            { "o": { "x": 0.167, "y": 0.062 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-10], "t": 67 },
            { "s": [15], "t": 116 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 90, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9137, 0.4745, 0.3922], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 2
    },
    {
      "ty": 4,
      "nm": "heart Outlines 3",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60, 60, 100], "t": 19 },
            { "s": [140, 140, 100], "t": 133 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.843 },
              "s": [220, 869, 0],
              "t": 19,
              "ti": [-38.167, 97.667, 0],
              "to": [-24.167, -47.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.177 },
              "i": { "x": 0.833, "y": 0.839 },
              "s": [151, 608, 0],
              "t": 48,
              "ti": [3.167, 82.333, 0],
              "to": [37.69, -96.448, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.174 },
              "i": { "x": 0.833, "y": 0.834 },
              "s": [213, 335, 0],
              "t": 81,
              "ti": [7.667, 64.5, 0],
              "to": [-3.167, -82.333, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.168 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [132, 114, 0],
              "t": 111,
              "ti": [0, 0, 0],
              "to": [-7.667, -64.5, 0]
            },
            { "s": [167, -52, 0], "t": 133 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.961 }, "s": [-20], "t": 19 },
            { "o": { "x": 0.167, "y": -0.089 }, "i": { "x": 0.833, "y": 1 }, "s": [0], "t": 48 },
            { "o": { "x": 0.167, "y": 0 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-10], "t": 81 },
            { "s": [0], "t": 133 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.3961], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 3
    },
    {
      "ty": 4,
      "nm": "heart Outlines 13",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60, 60, 100], "t": 61 },
            { "s": [140, 140, 100], "t": 156 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.844 },
              "s": [326, 871, 0],
              "t": 61,
              "ti": [39, 116, 0],
              "to": [11.833, -85.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.179 },
              "i": { "x": 0.833, "y": 0.812 },
              "s": [305, 606, 0],
              "t": 87,
              "ti": [-4.833, 80.667, 0],
              "to": [-39, -116, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.148 },
              "i": { "x": 0.833, "y": 0.838 },
              "s": [251, 381, 0],
              "t": 113,
              "ti": [-10, 110, 0],
              "to": [4.833, -80.667, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.173 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [334, 122, 0],
              "t": 137,
              "ti": [0, 0, 0],
              "to": [10, -110, 0]
            },
            { "s": [255, -56, 0], "t": 156 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.958 }, "s": [-20], "t": 61 },
            { "o": { "x": 0.167, "y": -0.083 }, "i": { "x": 0.833, "y": 1 }, "s": [0], "t": 87 },
            { "o": { "x": 0.167, "y": 0 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-10], "t": 113 },
            { "s": [0], "t": 156 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7843, 0.8314, 0.9451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 4
    },
    {
      "ty": 4,
      "nm": "heart Outlines 12",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60, 60, 100], "t": 43 },
            { "s": [140, 140, 100], "t": 157 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.842 },
              "s": [96, 871, 0],
              "t": 43,
              "ti": [-33.195, 118.428, 0],
              "to": [-24.167, -47.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.175 },
              "i": { "x": 0.833, "y": 0.84 },
              "s": [61, 600, 0],
              "t": 72,
              "ti": [-7.833, 85, 0],
              "to": [46.503, -165.907, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.174 },
              "i": { "x": 0.833, "y": 0.836 },
              "s": [37, 319, 0],
              "t": 105,
              "ti": [2, 62.5, 0],
              "to": [7.833, -85, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.17 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [108, 90, 0],
              "t": 135,
              "ti": [0, 0, 0],
              "to": [-2, -62.5, 0]
            },
            { "s": [25, -56, 0], "t": 157 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.961 }, "s": [-20], "t": 43 },
            { "o": { "x": 0.167, "y": -0.089 }, "i": { "x": 0.833, "y": 1 }, "s": [0], "t": 72 },
            { "o": { "x": 0.167, "y": 0 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-10], "t": 105 },
            { "s": [0], "t": 157 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9137, 0.4745, 0.3922], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 5
    },
    {
      "ty": 4,
      "nm": "heart Outlines 2",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60, 60, 100], "t": 0 },
            { "s": [140, 140, 100], "t": 114 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.843 },
              "s": [128, 871, 0],
              "t": 0,
              "ti": [-38.167, 97.667, 0],
              "to": [-24.167, -47.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.176 },
              "i": { "x": 0.833, "y": 0.839 },
              "s": [93, 600, 0],
              "t": 29,
              "ti": [-7.833, 85, 0],
              "to": [37.69, -96.448, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.174 },
              "i": { "x": 0.833, "y": 0.836 },
              "s": [69, 319, 0],
              "t": 62,
              "ti": [2, 62.5, 0],
              "to": [7.833, -85, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.17 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [140, 90, 0],
              "t": 92,
              "ti": [0, 0, 0],
              "to": [-2, -62.5, 0]
            },
            { "s": [57, -56, 0], "t": 114 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.83 }, "s": [-20], "t": 0 },
            { "o": { "x": 0.167, "y": 0.162 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 63 },
            { "s": [17], "t": 114 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9686, 0.8667, 0.7725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 6
    },
    {
      "ty": 4,
      "nm": "heart Outlines 7",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30, 30, 100], "t": 60 },
            { "s": [80, 80, 100], "t": 178 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.828 },
              "s": [226, 883, 0],
              "t": 60,
              "ti": [-27.5, 105.667, 0],
              "to": [-30.167, -131.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.161 },
              "i": { "x": 0.833, "y": 0.847 },
              "s": [291, 628, 0],
              "t": 90,
              "ti": [-3.5, 81.333, 0],
              "to": [21.478, -82.529, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.184 },
              "i": { "x": 0.833, "y": 0.836 },
              "s": [223, 351, 0],
              "t": 120,
              "ti": [0.333, 69.167, 0],
              "to": [3.5, -81.333, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.169 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [312, 140, 0],
              "t": 149,
              "ti": [0, 0, 0],
              "to": [-0.333, -69.167, 0]
            },
            { "s": [221, -64, 0], "t": 178 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.958 }, "s": [-20], "t": 60 },
            { "o": { "x": 0.167, "y": -0.333 }, "i": { "x": 0.833, "y": 0.773 }, "s": [0], "t": 90 },
            { "o": { "x": 0.167, "y": 0.106 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-5], "t": 150 },
            { "s": [-10], "t": 178 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 70, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7843, 0.8314, 0.9451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 7
    },
    {
      "ty": 4,
      "nm": "heart Outlines 11",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30, 30, 100], "t": 37 },
            { "s": [80, 80, 100], "t": 155 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [162, 887, 0],
              "t": 37,
              "ti": [-27.5, 105.667, 0],
              "to": [-30.167, -131.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.166 },
              "i": { "x": 0.833, "y": 0.839 },
              "s": [281, 636, 0],
              "t": 67,
              "ti": [-0.167, 83.333, 0],
              "to": [21.478, -82.529, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.173 },
              "i": { "x": 0.833, "y": 0.842 },
              "s": [171, 371, 0],
              "t": 97,
              "ti": [2.333, 71.833, 0],
              "to": [0.167, -83.333, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.177 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [282, 136, 0],
              "t": 126,
              "ti": [0, 0, 0],
              "to": [-2.333, -71.833, 0]
            },
            { "s": [157, -60, 0], "t": 155 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.958 }, "s": [-20], "t": 37 },
            { "o": { "x": 0.167, "y": -0.333 }, "i": { "x": 0.833, "y": 0.773 }, "s": [0], "t": 67 },
            { "o": { "x": 0.167, "y": 0.106 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-5], "t": 127 },
            { "s": [-10], "t": 155 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 70, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9137, 0.4745, 0.3922], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 8
    },
    {
      "ty": 4,
      "nm": "heart Outlines 10",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30, 30, 100], "t": 4 },
            { "s": [80, 80, 100], "t": 122 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [50, 885, 0],
              "t": 4,
              "ti": [-27.5, 105.667, 0],
              "to": [-30.167, -131.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.166 },
              "i": { "x": 0.833, "y": 0.839 },
              "s": [169, 634, 0],
              "t": 34,
              "ti": [-0.167, 83.333, 0],
              "to": [21.478, -82.529, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.173 },
              "i": { "x": 0.833, "y": 0.842 },
              "s": [59, 369, 0],
              "t": 64,
              "ti": [2.333, 71.833, 0],
              "to": [0.167, -83.333, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.177 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [170, 134, 0],
              "t": 93,
              "ti": [0, 0, 0],
              "to": [-2.333, -71.833, 0]
            },
            { "s": [45, -62, 0], "t": 122 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.958 }, "s": [-20], "t": 4 },
            { "o": { "x": 0.167, "y": -0.333 }, "i": { "x": 0.833, "y": 0.773 }, "s": [0], "t": 34 },
            { "o": { "x": 0.167, "y": 0.106 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-5], "t": 94 },
            { "s": [-10], "t": 122 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 70, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.3961], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 9
    },
    {
      "ty": 4,
      "nm": "heart Outlines 14",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30, 30, 100], "t": 29 },
            { "s": [80, 80, 100], "t": 134 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.824 },
              "s": [266, 883, 0],
              "t": 29,
              "ti": [-27.5, 105.667, 0],
              "to": [-30.167, -131.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.158 },
              "i": { "x": 0.833, "y": 0.85 },
              "s": [331, 628, 0],
              "t": 56,
              "ti": [-3.5, 81.333, 0],
              "to": [21.478, -82.529, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.187 },
              "i": { "x": 0.833, "y": 0.836 },
              "s": [263, 351, 0],
              "t": 82,
              "ti": [0.333, 69.167, 0],
              "to": [3.5, -81.333, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.169 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [352, 140, 0],
              "t": 108,
              "ti": [0, 0, 0],
              "to": [-0.333, -69.167, 0]
            },
            { "s": [261, -64, 0], "t": 134 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.958 }, "s": [-20], "t": 29 },
            { "o": { "x": 0.167, "y": -0.331 }, "i": { "x": 0.833, "y": 0.774 }, "s": [0], "t": 56 },
            { "o": { "x": 0.167, "y": 0.107 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-5], "t": 109 },
            { "s": [-10], "t": 134 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 70, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7843, 0.8314, 0.9451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 10
    },
    {
      "ty": 4,
      "nm": "heart Outlines 6",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30, 30, 100], "t": 26 },
            { "s": [80, 80, 100], "t": 144 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.828 },
              "s": [104, 885, 0],
              "t": 26,
              "ti": [-27.5, 105.667, 0],
              "to": [-30.167, -131.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.161 },
              "i": { "x": 0.833, "y": 0.847 },
              "s": [169, 630, 0],
              "t": 56,
              "ti": [-3.5, 81.333, 0],
              "to": [21.478, -82.529, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.184 },
              "i": { "x": 0.833, "y": 0.836 },
              "s": [101, 353, 0],
              "t": 86,
              "ti": [0.333, 69.167, 0],
              "to": [3.5, -81.333, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.169 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [190, 142, 0],
              "t": 115,
              "ti": [0, 0, 0],
              "to": [-0.333, -69.167, 0]
            },
            { "s": [99, -62, 0], "t": 144 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.958 }, "s": [-20], "t": 26 },
            { "o": { "x": 0.167, "y": -0.333 }, "i": { "x": 0.833, "y": 0.773 }, "s": [0], "t": 56 },
            { "o": { "x": 0.167, "y": 0.106 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-5], "t": 116 },
            { "s": [-10], "t": 144 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 70, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9137, 0.4745, 0.3922], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 11
    },
    {
      "ty": 4,
      "nm": "heart Outlines 5",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40, 40, 100], "t": 0 },
            { "s": [100, 100, 100], "t": 118 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.834 },
              "s": [276, 881, 0],
              "t": 0,
              "ti": [-43.5, 93.667, 0],
              "to": [-22.167, -91.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.839 },
              "s": [323, 622, 0],
              "t": 30,
              "ti": [-6.5, 80.667, 0],
              "to": [35.92, -77.344, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.173 },
              "i": { "x": 0.833, "y": 0.839 },
              "s": [283, 363, 0],
              "t": 60,
              "ti": [5.333, 69.5, 0],
              "to": [6.5, -80.667, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.173 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [362, 138, 0],
              "t": 89,
              "ti": [0, 0, 0],
              "to": [-5.333, -69.5, 0]
            },
            { "s": [251, -54, 0], "t": 118 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.958 }, "s": [-20], "t": 0 },
            { "o": { "x": 0.167, "y": -0.333 }, "i": { "x": 0.833, "y": 0.773 }, "s": [0], "t": 30 },
            { "o": { "x": 0.167, "y": 0.106 }, "i": { "x": 0.833, "y": 0.833 }, "s": [-5], "t": 90 },
            { "s": [-10], "t": 118 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 90, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 0.6863, 0.3961], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 12
    },
    {
      "ty": 4,
      "nm": "heart Outlines 9",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60, 60, 100], "t": 30 },
            { "s": [140, 140, 100], "t": 179 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.821 },
              "s": [216, 879, 0],
              "t": 30,
              "ti": [-22.833, 57.667, 0],
              "to": [-16.167, -25.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.156 },
              "i": { "x": 0.833, "y": 0.837 },
              "s": [173, 694, 0],
              "t": 60,
              "ti": [27.333, 84.333, 0],
              "to": [16.906, -42.697, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.17 },
              "i": { "x": 0.833, "y": 0.844 },
              "s": [231, 485, 0],
              "t": 90,
              "ti": [-18.807, 57.318, 0],
              "to": [-20.518, -63.305, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.179 },
              "i": { "x": 0.833, "y": 0.83 },
              "s": [145, 294, 0],
              "t": 120,
              "ti": [5.177, 56.434, 0],
              "to": [24.247, -73.896, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.163 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [218, 124, 0],
              "t": 150,
              "ti": [0, 0, 0],
              "to": [-6.667, -72.667, 0]
            },
            { "s": [137, -44, 0], "t": 179 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.867 }, "s": [-20], "t": 30 },
            { "o": { "x": 0.167, "y": 0.222 }, "i": { "x": 0.833, "y": 1.035 }, "s": [0], "t": 60 },
            { "o": { "x": 0.167, "y": 0.057 }, "i": { "x": 0.833, "y": 0.833 }, "s": [12], "t": 90 },
            { "s": [-10], "t": 179 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9137, 0.4745, 0.3922], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 13
    },
    {
      "ty": 4,
      "nm": "heart Outlines",
      "sr": 1,
      "st": 0,
      "op": 600,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [45.5, 42.5, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60, 60, 100], "t": 0 },
            { "s": [140, 140, 100], "t": 149 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.821 },
              "s": [318, 873, 0],
              "t": 0,
              "ti": [-22.833, 57.667, 0],
              "to": [-16.167, -25.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.156 },
              "i": { "x": 0.833, "y": 0.837 },
              "s": [275, 688, 0],
              "t": 30,
              "ti": [27.333, 84.333, 0],
              "to": [16.906, -42.697, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.17 },
              "i": { "x": 0.833, "y": 0.844 },
              "s": [333, 479, 0],
              "t": 60,
              "ti": [-18.807, 57.318, 0],
              "to": [-20.518, -63.305, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.179 },
              "i": { "x": 0.833, "y": 0.83 },
              "s": [247, 288, 0],
              "t": 90,
              "ti": [5.177, 56.434, 0],
              "to": [24.247, -73.896, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.163 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [320, 118, 0],
              "t": 120,
              "ti": [0, 0, 0],
              "to": [-6.667, -72.667, 0]
            },
            { "s": [239, -50, 0], "t": 149 }
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.867 }, "s": [-20], "t": 0 },
            { "o": { "x": 0.167, "y": 0.222 }, "i": { "x": 0.833, "y": 1.035 }, "s": [0], "t": 30 },
            { "o": { "x": 0.167, "y": 0.057 }, "i": { "x": 0.833, "y": 0.833 }, "s": [12], "t": 60 },
            { "s": [-10], "t": 149 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-5.307, 0.003],
                    [-3.283, -1.914],
                    [-0.966, -0.65],
                    [-3.222, 2.1],
                    [-4.745, 0.312],
                    [-2.189, -8.306],
                    [5.454, -6.092],
                    [6.226, -6.482],
                    [3.151, -2.829],
                    [2.764, 2.587],
                    [4.099, 4.251],
                    [4.846, 5.486],
                    [-8.872, 8.853]
                  ],
                  "o": [
                    [3.59, -0.304],
                    [1.006, 0.587],
                    [3.195, 2.149],
                    [3.769, -2.457],
                    [10.096, -0.663],
                    [2.121, 8.046],
                    [-5.993, 6.693],
                    [-2.937, 3.057],
                    [-2.825, 2.535],
                    [-4.308, -4.034],
                    [-5.083, -5.273],
                    [-8.429, -9.541],
                    [3.361, -3.353]
                  ],
                  "v": [
                    [-17.432, -33.504],
                    [-7.132, -30.469],
                    [-4.167, -28.618],
                    [5.385, -28.635],
                    [17.719, -33.562],
                    [37.602, -18.247],
                    [32.361, 3.109],
                    [13.834, 22.695],
                    [4.794, 31.641],
                    [-3.715, 31.639],
                    [-16.21, 19.079],
                    [-31.294, 3.105],
                    [-30.361, -28.432]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7843, 0.8314, 0.9451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [44.354, 40.522], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 14
    }
  ],
  "v": "5.6.3",
  "fr": 30,
  "op": 180,
  "ip": 0,
  "assets": []
}
