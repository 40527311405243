import { type RefObject, useEffect, useState } from 'react';

import type { Dayjs } from 'dayjs';
import type { FlatList } from 'react-native';

/**
 * Scroll to the given date. If that date doesn't exist in the list yet,
 * update the list, and only scroll to the given date once the list has
 * rerendered to have the necessary dates rendered to successfuly scroll
 * to it.
 * @param scrollContainerRef The ref of the <FlatList>
 * @param renderedDateStart The first date rendered in the list
 * @param renderedDateEnd The current last date rendered in the list
 * @param setRenderedDateEnd Change renderedDateEnd and rerender
 * @param visibleDays The number of days visible in the list at a time
 * @returns A function to scroll to a given date
 */
export const useScrollToDate = (
  scrollContainerRef: RefObject<FlatList<Dayjs>>,
  renderedDateStart: Dayjs,
  renderedDateEnd: Dayjs,
  setRenderedDateEnd: (renderedDateEnd: Dayjs) => void,
  visibleDays: number
) => {
  const [scrollTo, setScrollTo] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (!scrollTo) {
      return;
    }

    if (renderedDateEnd.diff(scrollTo, 'day') < visibleDays) {
      setRenderedDateEnd(scrollTo.add(visibleDays, 'days'));
    } else {
      const index = scrollTo.diff(renderedDateStart, 'day');

      scrollContainerRef.current?.scrollToIndex({
        animated: false,
        index,
      });
      setScrollTo(null);
    }
  }, [renderedDateEnd, renderedDateStart, scrollContainerRef, scrollTo, setRenderedDateEnd, visibleDays]);

  return setScrollTo;
};
