import React, { useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useToggle } from '@almond/utils';
import { useGlobalSearchParams } from 'expo-router';

import { FEEDBACK_PATH, feedbackApi } from '~modules/api';
import { useAsync } from '~modules/promises';

import { useRedirectBackToArtemis } from '../../hooks';
import { logError } from '../../logger';
import { ConfirmationModal } from '../ConfirmationModal';
import { ContactUsModal } from '../ContactUsModal';
import { FeedbackModal } from '../FeedbackModal';
import { IdleModal } from '../IdleModal';
import { useRunOnUserIdle } from './useRunOnUserIdle';
import { useShouldDisplayIdleModal } from './useShouldDisplayIdleModal';

type ActiveModal = {
  id: 'idle' | 'contactUs' | 'feedback' | 'confirmation';
  onBeforeSubmit?: () => Promise<string>;
};

export const IdleModalWrapper = () => {
  const [isVisible, toggleIsVisible] = useToggle(false);
  const shouldDisplayIdleModal = useShouldDisplayIdleModal();
  const [activeModal, setActiveModal] = useState<ActiveModal>({ id: 'idle' });
  const [feedbackUuid, setFeedbackUuid] = useState<string>();
  const { doAsync, isLoading } = useAsync({});
  const redirectBackToArtemis = useRedirectBackToArtemis();
  const { t } = useTranslation();
  const searchParams = useGlobalSearchParams();

  useRunOnUserIdle(toggleIsVisible, shouldDisplayIdleModal && !isVisible);

  if (!shouldDisplayIdleModal || !isVisible) {
    return null;
  }

  const handlePress = (key: string, text: string) => {
    const toCall = async () => {
      const patientResponse: { text: string; patientUuid?: string } = { text };

      if (searchParams.is_new_member !== 'true') {
        patientResponse.patientUuid = searchParams.patient_uuid;
      }

      try {
        const response = await feedbackApi.createFeedback()({
          eventType: 'idle',
          response: patientResponse,
        });

        setFeedbackUuid(response.uuid);

        if (key === 'somethingElse') {
          setActiveModal({ id: 'feedback' });
        } else if (key === 'contactUs') {
          if (searchParams.is_new_member === 'true') {
            setActiveModal({ id: 'contactUs' });
          } else {
            toggleIsVisible();
            redirectBackToArtemis('/messages');
          }
        } else {
          setActiveModal({ id: 'confirmation' });
        }
      } catch (err) {
        if (key === 'somethingElse') {
          setActiveModal({
            id: 'feedback',
            onBeforeSubmit: async () => {
              const response = await feedbackApi.createFeedback()({
                eventType: 'idle',
                response: patientResponse,
              });

              return response.uuid;
            },
          });
        } else {
          // Don't block user from completing their action if the API request fails, but log
          // a useful error to Sentry
          logError(`Call to idle ${FEEDBACK_PATH} failed`, `Payload: ${JSON.stringify(patientResponse)}`);
        }
      }
    };

    doAsync(toCall);
  };

  const onRequestClose = () => {
    toggleIsVisible();
    setActiveModal({ id: 'idle' });
  };

  const feedbackModalUuid = feedbackUuid || activeModal.onBeforeSubmit;

  return (
    <>
      <IdleModal
        isVisible={activeModal.id === 'idle'}
        isLoading={isLoading}
        onRequestClose={onRequestClose}
        onPress={handlePress}
      />
      <ContactUsModal
        isVisible={activeModal.id === 'contactUs'}
        onRequestClose={onRequestClose}
        onBack={() => setActiveModal({ id: 'idle' })}
        onPress={() => setActiveModal({ id: 'confirmation' })}
      />
      {feedbackModalUuid && (
        <FeedbackModal
          isVisible={activeModal.id === 'feedback'}
          onRequestClose={onRequestClose}
          feedbackUuid={feedbackModalUuid}
          onSubmit={() => setActiveModal({ id: 'confirmation' })}
          eventType="idle"
          onBack={() => setActiveModal({ id: 'idle' })}
        />
      )}
      <ConfirmationModal
        isVisible={activeModal.id === 'confirmation'}
        onRequestClose={onRequestClose}
        onPress={onRequestClose}
        submitTitle={t('done')}
      />
    </>
  );
};
