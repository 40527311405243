.standardWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 22px;
}

.desktopBox {
  margin-top: 20px;
  margin-bottom: 10px;
}

.bodyWrapper {
  gap: 14px;
}

.mobileSubtitle {
  align-self: center;
}
