import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';
import { useSetRecoilState } from 'recoil';

import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HistoryState, MEDICATIONS_PAGE_NAME } from '~types';

export const MedicationsPage: React.FC = () => {
  const { t } = useTranslation();
  const setHistoryState = useSetRecoilState(historyAtom);
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const { dispatch } = useRouteNavigation<typeof MEDICATIONS_PAGE_NAME>();

  const handleHistorySubmit = useCallback(
    (formValues: FormValues<HistoryState> | null) => {
      if (!formValues) {
        setHistoryState(prevState => ({ ...prevState, medications: undefined }));
      }

      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'medications', 'medications', t('medications.skipTitle'));
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, setHistoryState, saveHistory, t, dispatch]
  );

  return (
    <MainForm
      id="medications"
      title={t('medications.title')}
      submitButtonTitle={t('continue')}
      onSubmit={handleHistorySubmit}
      secondaryButtonTitle={t('medications.skipTitle')}
      onSecondaryPress={() => handleHistorySubmit(null)}
      atom={historyAtom}
      requiredFields={['medications']}
      isLoading={isLoading}
    >
      <ConnectedTextInput
        name="medications"
        multiline
        placeholder={t('medications.placeholder')}
        testID="Medications"
      />
    </MainForm>
  );
};
