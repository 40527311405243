import React from 'react';

import { useController, useFormContext } from '@almond/ui';

import { StateCodeInput } from '~modules/ui';

import type { StateCodeInputProps } from '~modules/ui';

export type ConnectedStateCodeInputProps = Omit<StateCodeInputProps, 'onSelect'> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedStateCodeInput: React.FC<ConnectedStateCodeInputProps> = props => {
  const { name, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, ...restField } = field;

  return <StateCodeInput {...restField} {...restProps} onSelect={item => onChange(item)} />;
};

export default ConnectedStateCodeInput;
