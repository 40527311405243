import React, { useState } from 'react';

import { infectionMessage, SANDBOX_RESPONSE_ID } from '~modules/typeform';

import styles from './MockWidget.module.css';

import type { WidgetProps } from '@typeform/embed-react';

export const MockWidget = (props: WidgetProps) => {
  // eslint-disable-next-line react-native/no-inline-styles
  const [selectedOutcome, setSelectedOutcome] = useState<string | null>(null);

  const onSubmit = (outcome: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setSelectedOutcome(outcome);

    // If the dataset.responseId (data-response-id attribute) is present on the clicked anchor
    // element, use it as the responseId. This is useful for Cypress to mock a responseId
    // to then verify gets sent to the server when completing the booking.
    const responseId =
      event.target instanceof HTMLAnchorElement && event.target.dataset.responseId
        ? event.target.dataset.responseId
        : SANDBOX_RESPONSE_ID;

    props.onSubmit?.({ formId: props.id, responseId });
  };

  if (selectedOutcome) {
    return (
      <iframe
        src={`${window.location.origin}/typeform-response?outcome=${selectedOutcome}`}
        className={styles.iframe}
      />
    );
  }

  return (
    <div className={styles.links}>
      <h1>{'Click a link to simulate a typeform response'}</h1>
      <ul>
        {Object.keys(infectionMessage.shape.outcome.enum).map(outcome => {
          return (
            <li key={outcome}>
              <a onClick={onSubmit(outcome)} href="#" data-outcome={outcome}>
                {outcome}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
