import React from 'react';

import { useTranslation } from '@almond/localization';
import { Stack } from 'expo-router';

import { IdleModalWrapper } from '~modules/integration';

import { useDisableForwardNavigation, useLogCurrentPage, useResetErrorState } from '../../hooks';

const ApplicationStack: React.FC = () => {
  const { t } = useTranslation();

  useDisableForwardNavigation();
  useLogCurrentPage();
  useResetErrorState();

  return (
    <>
      <Stack screenOptions={{ headerShown: false, headerTitle: undefined, title: t('navigation.title') }} />
      <IdleModalWrapper />
    </>
  );
};

export default ApplicationStack;
