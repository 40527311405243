import React, { useCallback, useReducer } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { IconButton, useAdaptive, useTheme } from '@almond/ui';
import { useFocusEffect, useNavigation, useRouter } from 'expo-router';

import themedStyles from './styles';

import type { StyleProp, ViewStyle } from 'react-native';

export type BackButtonProps = {
  onBack?: () => void;
  style?: StyleProp<ViewStyle>;
};

export const BackButton: React.FC<BackButtonProps> = props => {
  const router = useRouter();
  const navigation = useNavigation();
  const [styles] = useTheme(themedStyles);
  const { onBack } = props;
  const { t } = useTranslation();
  const buttonContainerStyle = useAdaptive(styles.buttonContainer, {});

  // In some situations, when clicking "back" to the first page in the stack
  // (where navigation.canGoBack() is false), the newly rendered page will not
  // rerender, which means that the navigation.canGoBack() value is still
  // true. This causes the back button to be visible when it shouldn't be.
  //
  // This hook makes sure that every time useFocusEffect() is called, we rerender
  const [, rerender] = useReducer(() => ({}), {});

  useFocusEffect(rerender);

  useFocusEffect(
    useCallback(() => {
      if (!onBack) return;

      return navigation.addListener('beforeRemove', e => {
        if (e.data.action.type === 'RESET') {
          // Don't trigger with navigation.reset(), just
          // with a legit back button press
          return;
        }

        e.preventDefault();
        onBack();
        navigation.dispatch(e.data.action);
      });
    }, [navigation, onBack])
  );

  if (!router.canGoBack()) return <View style={[buttonContainerStyle, styles.fakeButton]} testID="FakeBack" />;

  return (
    <View style={[buttonContainerStyle, styles.buttonContainerLeft, props.style]}>
      <IconButton
        source="arrow-back"
        onPress={router.back}
        color="text"
        testID="Back"
        accessibilityLabel={t('previousScreen')}
      />
    </View>
  );
};
