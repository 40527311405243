import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  fakeButton: {
    minWidth: sizes.SPACING_M * 3,
  },
  buttonContainer: {
    width: sizes.SPACING_XXL * 4,
  },
  buttonContainerLeft: {
    alignItems: 'flex-start',
    paddingLeft: sizes.SPACING_S,
  },
}));
