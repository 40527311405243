import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';

import { Caption } from './Caption';
import { ClearButton } from './ClearButton';
import { SignaturePad } from './SignaturePad';
import { Title } from './Title';

import themedStyles from './styles';

import type { SignaturePadProps } from './SignaturePad';

export type SignatureProps = SignaturePadProps & {
  onClearPress: () => void;
};

export const Signature: React.FC<SignatureProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <>
      <Title />
      <View style={styles.signatureContainer}>
        <View style={styles.signature}>
          <SignaturePad {...props} />
        </View>
        <Caption />
        <ClearButton onPress={props.onClearPress} />
      </View>
    </>
  );
};
