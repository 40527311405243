import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    rowGap: sizes.SPACING_M,
    minHeight: 240,
  },
  containerDesktop: {
    paddingHorizontal: 80,
  },
  image: {
    height: 120,
    aspectRatio: 1,
    marginBottom: sizes.SPACING_S,
  },
  center: {
    textAlign: 'center',
  },
}));
