import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { KnowYourProviderOut } from '@almond/api-types';

export type PhysicianInfoProps = {
  info: KnowYourProviderOut;
  isExpanded?: boolean;
  isLargerText?: boolean;
  onToggleExpand?: () => void;
};

export const PhysicianInfo: React.FC<PhysicianInfoProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { info, isExpanded, onToggleExpand, isLargerText } = props;

  const textSize = isLargerText ? 'm' : 's';

  return (
    <View style={[styles.bioContainer, isExpanded && styles.bioContainerExpanded]}>
      <Text numberOfLines={isExpanded ? undefined : 5} size={textSize} style={styles.darkText}>
        {info.bio}
        {'\n\n'}
        {info.experience && (
          <>
            <Text fontStyle="bold" size={textSize} style={styles.darkText}>
              {t('scheduling.availabilitySection.experience')}
            </Text>
            <Text size={textSize} style={styles.darkText}>
              {info.experience}
            </Text>
            {'\n\n'}
          </>
        )}
        {info.education && (
          <>
            <Text fontStyle="bold" size={textSize} style={styles.darkText}>
              {t('scheduling.availabilitySection.education')}
            </Text>
            <Text size={textSize} style={styles.darkText}>
              {info.education}
            </Text>
          </>
        )}
      </Text>
      {onToggleExpand && (
        <Button
          onPress={onToggleExpand}
          type="secondary"
          size="xxs"
          fontStyle="bold"
          uppercase
          style={styles.bioBtn}
          labelStyle={styles.bioBtnLabel}
        >
          {t(`scheduling.availabilitySection.${isExpanded ? 'readLess' : 'readMore'}`)}
        </Button>
      )}
    </View>
  );
};
