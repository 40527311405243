import { useCallback } from 'react';

import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useSetRecoilState } from 'recoil';

import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom } from '~modules/state';

import type { VisitReasonOut } from '@almond/api-types';
import type { VISIT_REASONS_PAGE_NAME } from '~types';

export const useSubmitVisitReason = (visitReasons?: readonly VisitReasonOut[]) => {
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const { dispatch } = useRouteNavigation<typeof VISIT_REASONS_PAGE_NAME>();
  const setAppointmentParamsState = useSetRecoilState(appointmentParamsAtom);

  return useCallback(
    async (item: string) => {
      const acuityBookingUrl = visitReasons?.find(vr => vr.code === item)?.acuityBookingUrl;

      if (acuityBookingUrl) {
        window.open(acuityBookingUrl, '_blank', 'noopener');

        return;
      }

      setAppointmentParamsState(prevState => ({
        ...prevState,
        visitReason: item,
      }));

      trackAnalyticsEvent('visit_reasons', { visitReasons: item });

      await dispatch('submit', undefined, { visit_reason: item });
    },
    [dispatch, setAppointmentParamsState, trackAnalyticsEvent, visitReasons]
  );
};
