import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  text: {
    color: theme.darkText,
  },
  messageDescription: {
    marginVertical: sizes.SPACING_M,
  },
  bottom: {
    marginTop: sizes.SPACING_M,
  },
}));
