import type {
  AppState,
  RedirectLoginOptions as NativeRedirectLoginOptions,
  useAuth0 as nativeUseAuth0,
} from '@auth0/auth0-react';

export type RedirectLoginOptions = NativeRedirectLoginOptions<AppState> & {
  navigateTo?: any;
  dispatch?: string;
  restoreRecoilState?: boolean;
};

export type UseAuth0Return = ReturnType<typeof nativeUseAuth0> & {
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
};
