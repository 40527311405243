import { useCallback } from 'react';

import { adminApi } from '~modules/api';

const useRetreieveByEmail = () => {
  return useCallback(async (email: string) => {
    try {
      return await adminApi.retrievePatientByEmail(email);
    } catch (error) {
      return null;
    }
  }, []);
};

export default useRetreieveByEmail;
