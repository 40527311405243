import { I18n } from '@almond/localization';

import AppError from './AppError';
import { errorsParsers, errorsUtils } from './services';

import type { ErrorDetails, ErrorResponse } from '~types';

export default class HttpRequestError extends AppError {
  status?: number;

  errorCode?: string;

  constructor(httpStatusCode?: number, errorDetails: ErrorDetails = {}, message?: string) {
    const errorCode = errorsUtils.translateHttpStatusCode(httpStatusCode);
    const errorResponse = errorDetails.errors
      ? errorsParsers.parseErrorResponse(errorDetails as ErrorResponse)
      : message;
    const errorMessage = errorResponse || I18n.t('defaultError');

    super(errorCode, errorMessage, errorDetails);

    this.status = httpStatusCode;
    this.errorCode = errorsParsers.parseErrorCode(errorDetails as ErrorResponse);
  }

  static fromOriginal(
    originalError: unknown,
    httpStatusCode?: number,
    errorDetails: ErrorDetails = {},
    message?: string
  ): HttpRequestError {
    if (originalError instanceof Error) {
      const httpRequestError = new HttpRequestError(httpStatusCode, errorDetails, message);

      httpRequestError.stack = originalError.stack;
      httpRequestError.originalError = originalError;

      return httpRequestError;
    }

    return new HttpRequestError(httpStatusCode, errorDetails, message);
  }
}
