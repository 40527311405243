import { useMemo } from 'react';

import { dateAndTimeParsers } from '@almond/date-and-time';
import dayjs from 'dayjs';
import useSWRInfinite from 'swr/infinite';

import { dailyAvailabilityApi, MONTHLY_AVAILABILITY_PATH } from '~modules/api';
import { dataTransformsUtilities } from '~modules/dataTransforms';

import { MONTHLY_API_PAGE_SIZE, TODAY } from './config';

import type { AvailabilityByMonth, GetMonthlyAvailabilityParams } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

export type MonthlyParams = Omit<GetMonthlyAvailabilityParams, 'start_date'>;

/**
 * Get the SWR key for a request using the `monthsOffset` and `index`.
 */
const getKey = (
  index: number,
  previousPageData: AvailabilityByMonth[] | undefined,
  params: MonthlyParams | null | undefined
) => {
  // Indicates no more pages to load.
  if ((previousPageData && !previousPageData.length) || params == null) {
    return null;
  }

  const startDate = dayjs()
    .startOf('day')
    // Calculating the correct `startDate` using the `index` and `monthsOffset`.
    .add(index * MONTHLY_API_PAGE_SIZE, 'months');
  const copiedParams: GetMonthlyAvailabilityParams = {
    ...params,
    start_date: dateAndTimeParsers.toRemoteDate(startDate),
  };

  return [MONTHLY_AVAILABILITY_PATH, copiedParams] as const;
};

/**
 * Take the SWR key and make the request for monthly availability.
 */
export const fetcher = async ([, params]: [string, GetMonthlyAvailabilityParams]) => {
  const paramsWithUndefinedStrippedOut = dataTransformsUtilities.omitNullishFields(params);
  const response = await dailyAvailabilityApi.getMonthlyAvailability(paramsWithUndefinedStrippedOut);

  return response.monthlyAvailability;
};

/**
 * This hook fetches and returns available dates using the `monthsOffset`.
 * @param params The parameters to send to the API
 * @returns List of available dates
 */
export const useMonthlyRequest = (params: MonthlyParams | null) => {
  const { data, isLoading, error, mutate, isValidating, size, setSize } = useSWRInfinite(
    (index, previousPageData?: AvailabilityByMonth[]) => getKey(index, previousPageData, params),
    fetcher,
    { revalidateFirstPage: false }
  );

  const lastLoadedDate = useMemo(
    // Subtract 1 because we're starting from the current (0th) month
    () => (data ? TODAY.add(data.length * MONTHLY_API_PAGE_SIZE - 1, 'month').endOf('month') : null),
    [data]
  );

  const availableDates = useMemo(() => {
    const monthlyAvailability = data?.flat(1);

    if (!monthlyAvailability) return;

    return monthlyAvailability.reduce(
      (accumulator, availability) => {
        availability.physicianAvailability.forEach(physicianAvailability => {
          physicianAvailability.availableDays.forEach(availableDay => {
            accumulator[availableDay] = accumulator[availableDay] || [];
            accumulator[availableDay].push(physicianAvailability);
          });
        });

        return accumulator;
      },
      {} as Record<string, Pick<AvailabilityByMonth['physicianAvailability'][number], 'location' | 'physician'>[]>
    );
  }, [data]);
  const isLoadingMore = isValidating && !!data;

  // Subtract 1 because we're starting from the current (0th) month
  const lastRequestedDay = useMemo(() => TODAY.add(size * MONTHLY_API_PAGE_SIZE - 1, 'months').endOf('month'), [size]);

  return {
    error,
    retry: mutate,
    availableDates,
    isLoading,
    isLoadingMore,
    lastLoadedDate,
    requestDataAt: (date: Dayjs) => {
      if (!date || !date.isAfter(lastRequestedDay, 'day')) {
        // Don't need to load more data, since we've already requested
        // data that includes the requested date
        return;
      }

      // Calculate from start of months to avoid the fact that "December 20 diff November 30"
      // calculates to 0 months, but we want that calculation to return a diff of 1
      // Add 1 at the end because we need to include the current month.
      const numberOfMonthsToLoad = date.startOf('month').diff(TODAY.startOf('month'), 'months') + 1;

      setSize(Math.ceil(numberOfMonthsToLoad / MONTHLY_API_PAGE_SIZE));
    },
  };
};
