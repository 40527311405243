import React, { useMemo } from 'react';
import { View } from 'react-native';

import { MaterialIcon, ScrollView, sizes, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import dayjs from 'dayjs';

import { ErrorMessage } from '~modules/errors';
import { DisabledCalendarBar } from '~modules/ui';

import { calendarThemedStyles, schedulingThemedStyles } from './styles';

export const SchedulingErrorPage = (props: Parameters<typeof ErrorMessage>[0]) => {
  const [styles] = useTheme(schedulingThemedStyles);
  const [calendarButtonStyles] = useTheme(calendarThemedStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const selectedDate = useMemo(() => dayjs(), []);

  return (
    <ScrollView>
      <View style={[styles.calendarContainer, isMobile && styles.calendarContainerMobile]}>
        <View style={isDesktop && [styles.schedulingContentDesktop, styles.schedulingContentHeaderDesktop]}>
          <View style={[calendarButtonStyles.calendarButton, styles.errorDisabled]}>
            <Text fontStyle="bold" style={calendarButtonStyles.calendarButtonText}>
              {selectedDate.format('MMMM YYYY')}
            </Text>
            <MaterialIcon source="keyboard-arrow-down" color="secondaryTextDark" size={sizes.SPACING_L} />
          </View>

          <DisabledCalendarBar selectedDate={selectedDate} />
        </View>
      </View>
      <View style={isDesktop && [styles.schedulingContentDesktop, styles.schedulingContentBodyDesktop]}>
        <ErrorMessage error={props.error} onTryAgain={props.onTryAgain} isFull={false} />
      </View>
    </ScrollView>
  );
};
