import React, { useCallback, useState } from 'react';

import { useTranslation } from '@almond/localization';

import { MainForm, SelectInputGroup } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { PERNICIOUS_HABITS_OPTIONS } from '../../config';
import { useCompleteHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HistoryState, PERNICIOUS_HABITS_PAGE_NAME } from '~types';

export const PerniciousHabitsPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const completeHistory = useCompleteHistory();
  const [perniciousHabitsLength, setPerniciousHabitsLength] = useState<number>();
  const { dispatch } = useRouteNavigation<typeof PERNICIOUS_HABITS_PAGE_NAME>();

  const handleLengthChange = useCallback((length: number) => {
    setPerniciousHabitsLength(length);
  }, []);

  const handleSubmit = useCallback(
    (values: FormValues<HistoryState>) => {
      const toCall = async (): Promise<void> => {
        await completeHistory(values);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [completeHistory, dispatch, doAsync]
  );

  return (
    <MainForm
      id="perniciousHabits"
      title={t('perniciousHabits.title')}
      submitButtonTitle={t('continue')}
      atom={historyAtom}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      requiredFields={['perniciousHabits']}
      scrollToBottomTrigger={perniciousHabitsLength}
    >
      <SelectInputGroup
        name="perniciousHabits"
        options={PERNICIOUS_HABITS_OPTIONS}
        keyTestID="Habit"
        valueTestID="Period"
        onLengthChange={handleLengthChange}
        keyTitle={t('perniciousHabits.other')}
      />
    </MainForm>
  );
};
