import { useEffect, useState } from 'react';

import { useCanSkipLogout } from '~modules/routing';

import useAuth0 from './useAuth0';

// This needs to NOT depend on our useAuth0() wrapper, or on the `user` object at all,
// because this needs to be resolved *before* we make the call for the user object
const useRestoreCurrentSession = (skipRestore?: boolean) => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
  const canSkipLogout = useCanSkipLogout();
  const [isGettingAccessToken, setIsGettingAccessToken] = useState(canSkipLogout && !skipRestore);

  useEffect(() => {
    if (skipRestore) {
      return;
    }

    // `getAccessTokenSilently` checks under the hood if the current auth0 user is authenticated.
    // We need to call it if `skipLogout` is specified.
    const toCall = async () => {
      try {
        await getAccessTokenSilently();
      } catch (e) {
        // Coming to Demi from Artemis, but there's no access token!
        // Redirect to the login page, which will bring the user back
        // to this page to retry.
        // Leave isGettingAccessToken as `true` while waiting for the
        // redirect to kick in.
        // This also applies when going to `/lab` without an existing
        // Auth token
        loginWithRedirect();
      }
    };

    if (canSkipLogout) {
      // Before finishing, make sure the Auth0 `isAuthenticated` flag is `true`. Otherwise, this
      // can lead to edge cases with the Axios interceptor and useSWR requests on the initial page.
      // LabOrders is an example of this.
      if (isAuthenticated) {
        setIsGettingAccessToken(false);
      } else {
        toCall();
      }
    }
  }, [canSkipLogout, getAccessTokenSilently, isAuthenticated, loginWithRedirect, skipRestore]);

  return isGettingAccessToken;
};

export default useRestoreCurrentSession;
