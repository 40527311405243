import { useCallback } from 'react';

import { useNavigation } from 'expo-router';

import { navigationPathToRouterPath, useNavigateTo } from '~modules/routing';

import type { AppState } from '~types';

const useProcessNavigationState = () => {
  const navigation = useNavigation();
  const navigateTo = useNavigateTo();

  return useCallback(
    async (appState: AppState, preventNavigation?: boolean) => {
      const { recoilState, navigationState } = appState;

      // If we're manually restoring the state, it's because the user hit the browser
      // "Back" button. Therefore, we want to go back to the page the user was on
      // previously, instead of the upcoming page.
      if (preventNavigation) {
        navigation.reset(navigationState);

        return;
      }

      const dispatch = recoilState?.authState.dispatch;

      // Auth redirect not part of a routing transition, so just go back to the page
      // from which the redirect was triggered
      if (!dispatch) {
        navigation.reset(navigationState);

        return;
      }

      // Navigate to the next route, *without* restoring the prior pages
      // TODO is leaving /auth-redirect in the route stack problematic?
      const currentPage = navigationState.routes.slice(-1)[0];
      const routerPath = navigationPathToRouterPath(currentPage.name);

      await navigateTo(dispatch, routerPath, currentPage.params);
    },
    [navigateTo, navigation]
  );
};

export default useProcessNavigationState;
