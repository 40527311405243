import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

const ITEM_SIZE = 50;

export default styleSheetFactory(theme => ({
  item: {
    height: 80,
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_S,
    padding: sizes.SPACING_M,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: sizes.SPACING_XS,
    flexDirection: 'row',
    ...getShadow(false),
  },
  itemCenter: {
    flex: 1,
    marginHorizontal: sizes.SPACING_M,
  },
  itemIcon: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.lightAccent,
    borderRadius: ITEM_SIZE / 2,
  },
  itemTitle: {
    color: theme.darkText,
    userSelect: 'none',
  },
  itemSubtitle: {
    color: theme.secondaryText,
    userSelect: 'none',
    marginTop: sizes.SPACING_XS,
  },
}));
