import { useCallback } from 'react';

import { useLocalSearchParams } from 'expo-router';

import { profilesApi } from '~modules/api';

import { logError } from '../logger';

export const useGetOrCreateStripeCustomer = () => {
  const searchParams = useLocalSearchParams();

  return useCallback(async () => {
    if (!searchParams.profile_uuid) {
      logError('`profile_uuid` is not available - cannot getOrCreate Stripe customer');

      return;
    }

    await profilesApi.getOrCreateStripeCustomer(searchParams.profile_uuid)();
  }, [searchParams.profile_uuid]);
};
