import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

export default () => {
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);

  if (searchParams.get('is_admin') === 'true') {
    searchParams.set('is_new_member', searchParams.get('is_new_member') === 'true' ? 'true' : 'false');

    return <Redirect href={`/visit-categories?${searchParams}`} />;
  }

  return <Redirect href={`/visit-reasons?${searchParams}`} />;
};
