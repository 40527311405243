import React, { useState } from 'react';
import { View } from 'react-native';

import { IconButton, sizes, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { useSetThemeColor } from '../../hooks';

import themedStyles from './styles';

export type NotificationStatusBarProps = {
  type: 'info' | 'accent' | 'error';
  content: string | React.JSX.Element;
  isVisible?: boolean;
};

export const NotificationStatusBar = (props: NotificationStatusBarProps) => {
  const [styles, theme] = useTheme(themedStyles);
  const isVisibleExternal = props.isVisible !== false && !!props.type;
  const [isVisibleInternal, setIsVisibleInternal] = useState(true);
  const { isDesktop } = useBrowserTypeMap();
  const isVisible = isVisibleExternal && isVisibleInternal;
  const color = {
    info: theme.info,
    accent: theme.lightAccent,
    error: theme.lightError,
  }[props.type];

  useSetThemeColor(color);

  if (!isVisible) {
    return null;
  }

  return (
    <View
      style={[styles.container, color ? { backgroundColor: color } : null, isVisible && styles.containerVisible]}
      role="note"
    >
      <View style={[styles.content, isDesktop && styles.contentDesktop]}>
        {typeof props.content === 'string' ? (
          <Text style={styles.contentText} size="m">
            {props.content}
          </Text>
        ) : (
          props.content
        )}
      </View>
      {isDesktop && (
        <IconButton
          source="close"
          size={sizes.SPACING_M}
          aria-label="Close notice"
          color="background"
          style={styles.close}
          onPress={() => setIsVisibleInternal(false)}
        />
      )}
    </View>
  );
};
