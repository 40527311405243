import React from 'react';
import { View } from 'react-native';

import { ColumnContainer, sizes, SkeletonLoader, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { LoadingReadBioButton, PhysicianInfo, ReadBioButton } from '../ReadBioButton';
import { Headshot } from './Headshot';
import { Tags } from './Tags';

import { themedContainerStyles, themedLoadingStyles, themedPhysicianStyles, themedTagsStyles } from './styles';

import type { PhysicianInfoProps } from '../ReadBioButton';
import type { PhysicianFindOut, VisitLocationEnum } from '@almond/api-types';

export type PhysicianProps = Pick<PhysicianInfoProps, 'isExpanded' | 'onToggleExpand'> & {
  physician: PhysicianFindOut;
  duration: number;
  location: VisitLocationEnum;
  isLoading: false;
  isPrimary: boolean;
};

export type PhysicianLoadingProps = Omit<Partial<PhysicianProps>, 'isLoading'> & { isLoading: true };

const LoadingPhysician: React.FC = () => {
  const [containerStyles] = useTheme(themedContainerStyles);
  const [physicianStyles] = useTheme(themedPhysicianStyles);
  const [tagsStyles] = useTheme(themedTagsStyles);
  const [loadingStyles] = useTheme(themedLoadingStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const tags = Array.from({ length: 2 }, (_, index) => ({ id: index }));

  return (
    <View style={[containerStyles.leftContainer, isMobile && containerStyles.leftContainerMobile]}>
      {isMobile && (
        <ColumnContainer
          renderItem={() => <SkeletonLoader.RoundedRectangle width={60} height={20} roundness={100} />}
          keyExtractor={item => item.id.toString()}
          numColumns={2}
          style={[tagsStyles.tags, tagsStyles.tagsMobile]}
          horizontalPaddingPercentage={4}
        >
          {tags}
        </ColumnContainer>
      )}
      <View style={physicianStyles.row}>
        <SkeletonLoader.Circle diameter={sizes.SPACING_XXL} />
        <View style={physicianStyles.column}>
          <SkeletonLoader.Rectangle width="50%" height={sizes.SPACING_M} />
          <SkeletonLoader.Rectangle width="50%" height={sizes.SPACING_M} />
        </View>
        {isMobile && <LoadingReadBioButton />}
      </View>
      {isDesktop && (
        <View style={loadingStyles.bioContainer}>
          <View style={loadingStyles.bio}>
            <SkeletonLoader.Rectangle width="100%" height={11.2} />
            <SkeletonLoader.Rectangle width="100%" height={11.2} />
            <SkeletonLoader.Rectangle width="100%" height={11.2} />
            <SkeletonLoader.Rectangle width="100%" height={11.2} />
            <SkeletonLoader.Rectangle width="100%" height={11.2} />
          </View>
          <SkeletonLoader.RoundedRectangle width={80} height={sizes.SPACING_L} roundness={sizes.SPACING_L} />
        </View>
      )}
    </View>
  );
};

export const Physician: React.FC<PhysicianProps | PhysicianLoadingProps> = props => {
  const [containerStyles] = useTheme(themedContainerStyles);
  const [physicianStyles] = useTheme(themedPhysicianStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();

  if (props.isLoading) {
    return <LoadingPhysician />;
  }

  const { physician, isPrimary, duration, location, isLoading, ...restProps } = props;

  return (
    <View style={[containerStyles.leftContainer, isMobile && containerStyles.leftContainerMobile]}>
      {isMobile && <Tags isPrimary={isPrimary} duration={duration} location={location} />}
      <View style={physicianStyles.row}>
        <Headshot physician={physician} location={location} />
        <View style={physicianStyles.column}>
          <Text
            style={isMobile ? physicianStyles.physicianTitleMobile : physicianStyles.physicianTitle}
            fontStyle="bold"
            size="m"
          >
            {`${physician.firstName} ${physician.lastName}`}
          </Text>
          <Text style={physicianStyles.physicianSubTitle}>{physician.providerType !== 'CG' && physician.title}</Text>
        </View>
        {isMobile && <ReadBioButton physician={physician} />}
      </View>
      {isDesktop && <PhysicianInfo info={physician.knowYourProvider} {...restProps} />}
    </View>
  );
};
