import { type PropsWithChildren, type ReactNode } from 'react';

import { useSetUser } from '../../hooks';

type UserSetterProps = PropsWithChildren<{ loadingEle: ReactNode }>;

const UserSetter = (props: UserSetterProps) => {
  const isLoading = useSetUser();

  if (isLoading) {
    return props.loadingEle;
  }

  return props.children;
};

export default UserSetter;
