import React, { useState } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Button, TextInput, useTheme } from '@almond/ui';
import { useEvent } from '@almond/utils';

import { formsUtilities } from '~modules/forms';

import themedStyles from './styles';

import type { PromotionCode } from '~types';

type CodeEntryFormProps = {
  isLoading: boolean;
  onNotFound: (code: string | null) => void;
  notFoundCode: string | null;
  onCheckCode: (code: string) => Promise<PromotionCode | undefined>;
};
export const CodeEntryForm = (props: CodeEntryFormProps) => {
  const { isLoading, onNotFound, notFoundCode, onCheckCode } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();

  const [textInputValue, setTextInputValue] = useState('');

  const submitEnteredCode = useEvent(async (code: string) => {
    if (!code) {
      return;
    }

    let sanitizedCode = code;
    const match = sanitizedCode.match(/\/refer\/([^/?#]+)/);

    if (match) {
      [, sanitizedCode] = match;
    }

    const result = await onCheckCode(sanitizedCode);

    if (result?.state === 'not-found') {
      onNotFound(code);
    } else {
      onNotFound(null);
      setTextInputValue('');
    }
  });

  let textFieldError;

  if (notFoundCode) {
    textFieldError = {
      type: 'invalid_code',
      message: t('creditCard.promotion.invalidCode', { code: notFoundCode.toUpperCase() }),
    };
  }

  return (
    <View style={styles.codeEntryForm}>
      <TextInput
        testID="PromotionCodeInput"
        value={textInputValue}
        label={t('creditCard.promotion.label')}
        onChangeText={(text: string) => setTextInputValue(text.toLowerCase())}
        containerStyle={styles.codeEntryInput}
        onKeyPress={formsUtilities.callOnEnterPress(() => submitEnteredCode(textInputValue))}
        error={textFieldError}
        uppercase
        size="m"
      />
      <Button
        onPress={() => submitEnteredCode(textInputValue)}
        isDisabled={isLoading}
        isSquared
        fixedWidth={false}
        fontStyle="bold"
        size="xl"
      >
        {isLoading ? <ActivityIndicator /> : t('creditCard.promotion.apply')}
      </Button>
    </View>
  );
};
