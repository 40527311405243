/* eslint max-lines: "off" */

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedContainerStyles = styleSheetFactory(theme => ({
  container: {
    backgroundColor: theme.background,
    padding: sizes.SPACING_L,
    rowGap: sizes.SPACING_L,
    columnGap: 100,
    flexDirection: 'row',
    flex: 1,
    flexGrow: 0,
    minHeight: 'auto',
  },

  containerMobile: {
    flexDirection: 'column',
    paddingHorizontal: 0,
    paddingVertical: 20,
    flex: 0,
    rowGap: 15,
  },

  containerMobileWithoutTimeSlots: {
    rowGap: 12,
  },

  leftContainer: {
    flex: 1,
    rowGap: 10.4,
  },

  leftContainerMobile: {
    paddingHorizontal: sizes.SPACING_L,
    rowGap: 15,
    flex: 2.5,
  },

  rightContainer: {
    flex: 1.3,
    rowGap: sizes.SPACING_M,
  },

  rightContainerMobile: {
    flex: 1,
    rowGap: 20.8,
  },

  withoutTimeslotsGap: {
    rowGap: sizes.SPACING_XXL,
  },

  withoutTimeslotsGapMobile: {
    flexBasis: 'auto',
    flexShrink: 0,
    flexGrow: 0,
    rowGap: sizes.SPACING_XXL,
  },
}));

export const themedPhysicianStyles = styleSheetFactory(theme => ({
  row: {
    flexDirection: 'row',
    columnGap: sizes.SPACING_S,
    alignItems: 'center',
  },

  column: {
    rowGap: sizes.SPACING_XS,
    flex: 1,
  },

  headshot: {
    width: sizes.SPACING_XXL,
    height: sizes.SPACING_XXL,
    backgroundColor: theme.primary,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },

  headshotImage: {
    width: '85%',
    height: '85%',
    borderRadius: 20,
  },

  cameraIcon: {
    position: 'absolute',
    top: 0,
    left: -7,
  },

  physicianTitle: {
    color: theme.darkText,
  },

  physicianTitleMobile: {
    color: theme.darkText,
    textWrap: 'balance',
  },

  physicianSubTitle: {
    color: theme.secondaryTextDark,
  },
}));

export const themedTimeSlotsStyles = styleSheetFactory(theme => ({
  timeSlots: {
    flex: 1,
    rowGap: 10,
    columnGap: sizes.SPACING_M,
    marginBottom: 10,
  },

  timeSlotsMobileContent: {
    columnGap: 12,
    alignItems: 'center',
    paddingHorizontal: sizes.SPACING_L,
  },

  timeSlot: {
    backgroundColor: theme.secondaryTextDark,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: sizes.SPACING_M,
    paddingHorizontal: sizes.SPACING_S,
    width: 80,
    height: sizes.SPACING_XL,
  },

  timeSlotText: {
    color: theme.lightText,
    textAlign: 'center',
  },

  noVisitsText: {
    color: theme.text,
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: 30,
  },

  noVisitsTextMobile: {
    color: theme.secondaryTextDark,
    marginBottom: 10,
  },

  noVisitsContainerDesktop: {
    justifyContent: 'center',
  },

  goToNextDate: {
    alignSelf: 'center',
  },
}));

export const themedLoadingStyles = styleSheetFactory(() => ({
  bioContainer: {
    rowGap: sizes.SPACING_M,
  },
  bio: {
    rowGap: sizes.SPACING_XS,
  },
}));

export const themedTagsStyles = styleSheetFactory(theme => ({
  tags: {
    flexDirection: 'row',
    gap: 5,
    justifyContent: 'flex-end',
  },

  tagsMobile: {
    justifyContent: 'flex-start',
  },

  tag: {
    whiteSpace: 'wrap',
    paddingVertical: 5,
    paddingHorizontal: 7,
    textTransform: 'uppercase',
    borderRadius: 100,
    backgroundColor: theme.lightPrimary,
    color: theme.secondaryTextDark,
  },
}));
