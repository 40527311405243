import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  copyMessage: {
    opacity: 0,
    textAlign: 'right',
  },
  showCopyMessage: {
    opacity: 1,
  },
}));
