import { styleSheetFactory } from '@almond/ui';

import { waveBackground } from '../../config';

export default styleSheetFactory(theme => ({
  container: {
    flex: 1,
    backgroundColor: theme.lightSecondaryBackground,
    overflow: 'hidden',
  },
  containerDesktop: {
    ...waveBackground,
  },
  containerMobile: {
    backgroundColor: theme.lightAccent,
  },
}));
