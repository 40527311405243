import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator as NativeActivityIndicator, sizes, Text, useTheme } from '@almond/ui';

import { LocalImage } from '~modules/ui';

import themedStyles from './styles';

const ActivityIndicator: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container}>
      <NativeActivityIndicator size={320} lineWidth={sizes.SPACING_M} />
      <LocalImage source="universe2" style={styles.image} />
      <Text style={styles.bottom}>{t('createPatient.bottom')}</Text>
    </View>
  );
};

export default ActivityIndicator;
