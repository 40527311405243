import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  header: {
    textAlign: 'center',
    marginBottom: 12,
  },
  loaderContainer: {
    rowGap: sizes.SPACING_S,
  },
}));
