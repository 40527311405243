import React from 'react';

import { CheckboxGroup, useController, useFormContext } from '@almond/ui';

import type { CheckboxGroupItem, CheckboxGroupProps } from '@almond/ui';

export type ConnectedCheckboxGroupProps<T extends string, Item extends CheckboxGroupItem<T>> = Omit<
  CheckboxGroupProps<T, Item>,
  'selectedItems' | 'onSelect'
> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedCheckboxGroup = <T extends string, Item extends CheckboxGroupItem<T>>(
  props: ConnectedCheckboxGroupProps<T, Item>
) => {
  const { name, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, value, ...restField } = field;

  return <CheckboxGroup {...restField} {...restProps} selectedItems={value} onSelect={onChange} />;
};

export default ConnectedCheckboxGroup;
