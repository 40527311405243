import React from 'react';
import { View } from 'react-native';

import { PulseProvider, sizes, SkeletonLoader, Text, useAdaptive, useBrowserType, useTheme } from '@almond/ui';

import { AlmondLogo } from '~assets';

import { BackButton } from '../BackButton';
import CloseButton from '../CloseButton';

import themedStyles from './styles';

import type { BackButtonProps } from '../BackButton';
import type { StyleProp, ViewStyle } from 'react-native';

export type NavigationBarProps = Pick<BackButtonProps, 'onBack'> & {
  shouldHideCloseButton?: boolean;
  isAbsoluteOnDesktop?: boolean;
  center?: React.ReactNode;
  bannerText?: string;
  isLoading?: boolean;
  style?: StyleProp<ViewStyle>;
};

export const NavigationBar: React.FC<NavigationBarProps> = props => {
  const { bannerText, isLoading, style } = props;
  const [styles] = useTheme(themedStyles);
  const isDesktop = useBrowserType() === 'desktop';
  const containerStyle = useAdaptive(null, styles.containerMobile);
  const logoContainerStyle = useAdaptive(styles.logoContainerDesktop, {});
  const headerTextStyle = useAdaptive({}, styles.headerTextMobile);
  const absoluteStyle = useAdaptive(styles.absoluteContainer, {});
  const hidePartOfHeader = props.isAbsoluteOnDesktop && isDesktop;

  let headerContent;

  if (isLoading) {
    headerContent = (
      <PulseProvider duration={1000}>
        <View style={styles.loadingContainer}>
          <View style={styles.row}>
            <SkeletonLoader.Rectangle height={sizes.SPACING_S} />
            <SkeletonLoader.Rectangle height={sizes.SPACING_S} />
          </View>
        </View>
      </PulseProvider>
    );
  } else if (bannerText) {
    headerContent = (
      <Text numberOfLines={1} fontStyle="bold" size="xxl" style={[styles.headerText, headerTextStyle]}>
        {bannerText}
      </Text>
    );
  } else {
    headerContent = <AlmondLogo />;
  }

  return (
    <View
      style={[styles.container, containerStyle, props.isAbsoluteOnDesktop && absoluteStyle, style]}
      accessibilityLabel="NavigationBar"
    >
      <BackButton onBack={props.onBack} />
      {props.center}
      {!hidePartOfHeader && !props.center && (
        <View style={[styles.logoContainer, logoContainerStyle]}>{headerContent}</View>
      )}
      <CloseButton isVisible={!props.shouldHideCloseButton && !hidePartOfHeader} />
    </View>
  );
};
