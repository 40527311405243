import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, SENTRY_UNMASK, useTheme } from '@almond/ui';

import { BaseModal } from '../BaseModal';

import { themedStyles } from './styles';

import type { BaseModalProps } from '../BaseModal';

export type ConfirmationModalProps = Omit<BaseModalProps, 'title' | 'subtitle'> & {
  onPress: () => void;
  submitTitle: string;
};

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { onPress, submitTitle, ...restProps } = props;

  return (
    <BaseModal
      title={t('confirmationModal.title')}
      subtitle={t('confirmationModal.subtitle')}
      titlesStyle={styles.titlesStyle}
      {...restProps}
    >
      <View style={styles.contentContainer}>
        <Button onPress={onPress} size="m" type="accent" testID="ConfirmationModal-Submit" style={SENTRY_UNMASK}>
          {submitTitle}
        </Button>
      </View>
    </BaseModal>
  );
};
