import { withRouteBehavior } from '~modules/navigation';
import { isBaselineProduct } from '~modules/product';
import { DemographicPage } from '~modules/profile';
import { configurations } from '~modules/routing';

export default withRouteBehavior(DemographicPage, {
  title: 'Basic Information',
  restoreAuth: true,
  clearAuth: true,
  allowDirectRouting: true,
  routingConfig: searchParams => {
    const product = searchParams.get('product');

    if (isBaselineProduct(product)) {
      return configurations.signup;
    }

    return null;
  },
});
