import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';

import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HEALTH_CONDITIONS_PAGE_NAME, HistoryState } from '~types';

export const HealthConditionsPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const { dispatch } = useRouteNavigation<typeof HEALTH_CONDITIONS_PAGE_NAME>();

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState>) => {
      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'health_conditions', 'healthConditions');
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, saveHistory, dispatch]
  );

  return (
    <MainForm
      id="healthConditions"
      title={t('healthConditions.title')}
      submitButtonTitle={t('continue')}
      onSubmit={handleSubmit}
      atom={historyAtom}
      requiredFields={['healthConditions']}
      isLoading={isLoading}
    >
      <ConnectedTextInput
        name="healthConditions"
        multiline
        placeholder={t('healthConditions.placeholder')}
        testID="HealthConditions"
      />
    </MainForm>
  );
};
