import { type PropsWithChildren } from 'react';

import { useClearCurrentSession, useRestoreCurrentSession } from '~modules/auth';

import type React from 'react';

export type AuthPersistenceProps = {
  loadingEle?: React.ReactNode;
  restoreAuth?: boolean;
  clearAuth?: boolean;
};

export const AuthPersistence = (props: PropsWithChildren<AuthPersistenceProps & { alreadyInitted: boolean }>) => {
  const isRestoringCurrentSession = useRestoreCurrentSession(!props.restoreAuth && !props.alreadyInitted);
  const isClearingCurrentSession = useClearCurrentSession(!!props.clearAuth, props.alreadyInitted);

  if (isRestoringCurrentSession || isClearingCurrentSession) {
    return props.loadingEle;
  }

  return props.children;
};
