import React, { useState } from 'react';

import { ActivityIndicator, BaseButton, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { BaseButtonProps } from '@almond/ui';

export type ModalButtonProps = Omit<BaseButtonProps, 'children'> & { children?: string; isLoading?: boolean };

export const ModalButton = (props: ModalButtonProps) => {
  const { children, style, isLoading, ...restProps } = props;
  const [styles, theme] = useTheme(themedStyles);
  const [buttonColor, setButtonColor] = useState(theme.background);
  const [textColor, setTextColor] = useState(theme.text);

  return (
    <BaseButton
      {...restProps}
      style={[styles.button, { backgroundColor: buttonColor }, style]}
      onPressIn={() => {
        setButtonColor(theme.accent);
        setTextColor(theme.lightText);
      }}
      onPressOut={() => {
        setTextColor(theme.text);
        setButtonColor(theme.background);
      }}
    >
      <Text style={{ color: textColor }}>{children}</Text>
      {isLoading && <ActivityIndicator size={25} lineWidth={3} style={styles.loading} />}
    </BaseButton>
  );
};
