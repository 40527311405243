import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { ViewProps } from 'react-native';

export type MessageContainerProps = React.PropsWithChildren<ViewProps>;

export const MessageContainer: React.FC<MessageContainerProps> = props => {
  const { style, ...restProps } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View {...restProps} style={[styles.message, style]}>
      {props.children}
    </View>
  );
};
