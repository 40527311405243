import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  subtitleContainer: {
    marginTop: sizes.SPACING_S,
    paddingHorizontal: sizes.SPACING_XXS,
  },
  subtitleLabel: {
    color: theme.secondaryText,
  },
}));
