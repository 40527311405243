import type { PromotionCode } from '../api';
import type { InsuranceCarrierChoicesEnum, ProductOut } from '@almond/api-types';
import type { SelectInputValue } from '@almond/ui';

export type ProfileState = {
  firstName?: string;
  preferredFirstName?: string;
  lastName?: string;
  birthday?: string;
};

export type DemographicState = {
  firstName?: string;
  preferredFirstName?: string;
  lastName?: string;
  birthday?: string;
  email?: string;
  phone?: string;
  // TODO: Move to the URL params as soon as ENG-3768 is merged.
  otp?: string;
  isOptedIntoSms?: boolean;
};

export type CreditCardState = {
  paymentMethod?: string;
  error?: string;
  promotionCode?: PromotionCode;
  friendReferralCode?: PromotionCode;
  product?: ProductOut;
};

type InsuranceCard = {
  front?: string;
  back?: string;
};

export type InsuranceState = {
  carrier: SelectInputValue<InsuranceCarrierChoicesEnum>;
  id?: string;
  card: InsuranceCard;
};

export type ProfileProgressState = {
  demographic?: boolean;
  creditCard?: boolean;
  insurance?: boolean;
  isInsuranceAccepted?: boolean;
  insuranceId?: boolean;
};
