import { useLocalSearchParams } from 'expo-router';

export const useCanSkipLogout = () => {
  const searchParams = useLocalSearchParams();

  return (
    // TODO move checking for /lab path into ./src/app/lab.tsx
    searchParams.is_admin === 'true' || searchParams.is_new_member === 'false' || window.location.pathname === '/lab'
  );
};
