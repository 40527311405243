import { useEvent } from '@almond/utils';
import { useNavigation, useRouter } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { useRouteContext } from '../context/routeContext';
import { getAllowedSearchParams, routerPathToNavigationPath } from '../services';
import { useRouteToConfig } from './useRouteToConfig';

import type routeConfigs from '../configurations';
import type { BaseParams, RouteConfig, StackParamList } from '~types';
import type { RecoilValue } from 'recoil';

type DefaultInferredRouteConfig = (typeof routeConfigs)[keyof typeof routeConfigs];

export const useNavigateTo = () => {
  const navigation = useNavigation();
  const router = useRouter();
  const routingConfigMap = useRouteContext<typeof routeConfigs>();
  const routeToConfig = useRouteToConfig();

  const getState = useRecoilCallback(
    callbackInterface =>
      async <T>(atom: RecoilValue<T>): Promise<T> => {
        return callbackInterface.snapshot.getPromise(atom);
      },
    []
  );

  return useEvent(
    // eslint-disable-next-line max-statements
    async (
      eventStr: string,
      pathname: string,
      searchParams: BaseParams,
      params?: Record<string, any>
    ): Promise<void> => {
      const routingConfigId = searchParams.routing_config_id;
      const allowedSearchParams = getAllowedSearchParams(searchParams);

      if (!routingConfigId) {
        throw new Error('No routing config ID found. Did you call routeToConfig()?');
      }

      const routingConfig: DefaultInferredRouteConfig | undefined =
        routingConfigMap[routingConfigId as unknown as keyof typeof routeConfigs];

      if (!routingConfig) {
        throw new Error(`No routing config found with ID "${routingConfigId}"`);
      }

      const routeConfig: RouteConfig<StackParamList> | undefined = (routingConfig.routes as any)[pathname];

      if (!routeConfig) {
        throw new Error(
          `No route config found for route "${pathname}" within config ID "${routingConfigId || '<empty>'}"`
        );
      }

      const events = routeConfig?.on;
      const eventTarget = events?.[eventStr];

      if (!eventTarget) {
        throw new Error(`Event "${eventStr}" not defined on route "${pathname}"`);
      }

      const result = await eventTarget(getState, searchParams, params);

      if (!result) {
        throw new Error(`Invalid destination for event "${eventStr}" on route "${pathname}"`);
      }

      if ('id' in result) {
        await routeToConfig(result, true, searchParams);
      } else {
        const nextState = (routingConfigMap[routingConfigId as unknown as keyof typeof routeConfigs].routes as any)[
          result.name
        ] as RouteConfig<StackParamList> | undefined;
        const navigationParams = {
          ...allowedSearchParams,
          ...result.params,
          routing_config_id: routingConfigId as unknown as keyof typeof routeConfigs,
        };

        if (!nextState) {
          throw new Error(`State not found - ${result.name}`);
        }

        if (nextState.reset) {
          const navigationPath = routerPathToNavigationPath(result.name);

          navigation.reset({
            routes: [{ name: navigationPath, params: navigationParams }],
            index: 0,
          });
        } else if (result.replace) {
          router.replace({ pathname: result.name, params: navigationParams });
        } else if (router.push) {
          router.push({ pathname: result.name, params: navigationParams });
        } else {
          router.navigate({ pathname: result.name, params: navigationParams });
        }
      }
    }
  );
};
