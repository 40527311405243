import './styles.css';

import React from 'react';

import { Button, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { ButtonProps } from '@almond/ui';

export type GradientButtonProps = ButtonProps;

export const GradientButton = (props: GradientButtonProps) => {
  const { style, ...restProps } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <Button
      {...restProps}
      className="gradient-button"
      style={[
        styles.button,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          // @ts-ignore
          $$css: true,
          _: 'gradient-button',
        },
      ]}
    />
  );
};
