import { useRecoilCallback } from 'recoil';

import { useGetOrCreateStripeCustomer } from '~modules/payment';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { creditCardAtom, profileProgressAtom } from '~modules/state';
import { useIsAdmin } from '~modules/user';

import type { ProductOut } from '@almond/api-types';
import type { CREDIT_CARD_PAGE_NAME } from '~types';

export type UseSkipCreditCardArgs = {
  product: ProductOut;
  resetErrors: () => void;
};

const useSkipCreditCard = (args: UseSkipCreditCardArgs) => {
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof CREDIT_CARD_PAGE_NAME>();
  const { resetErrors, product } = args;
  const isAdmin = useIsAdmin();
  const getOrCreateStripeCustomer = useGetOrCreateStripeCustomer();

  const skipCreditCard = useRecoilCallback(
    callbackInterface => async () => {
      const toCall = async (): Promise<void> => {
        callbackInterface.set(creditCardAtom, prevState => ({ ...prevState, product }));

        if (!isAdmin) return;

        resetErrors();
        callbackInterface.set(profileProgressAtom, prevState => ({ ...prevState, creditCard: false }));

        await getOrCreateStripeCustomer();

        return dispatch('submit');
      };

      doAsync(toCall);
    },
    [dispatch, doAsync, getOrCreateStripeCustomer, isAdmin, product, resetErrors]
  );

  return { isLoading, skipCreditCard };
};

export default useSkipCreditCard;
