import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { integrationAtom } from '~modules/state';
import { useIsAdmin } from '~modules/user';

export const useShouldDisplayIdleModal = () => {
  const isAdmin = useIsAdmin();
  const searchParams = useLocalSearchParams();
  const integrationState = useRecoilValue(integrationAtom);

  return (
    !isAdmin &&
    !searchParams.booking_uuid &&
    !integrationState.isIdleModalDisabled &&
    !integrationState.isAbandonModalVisible
  );
};
