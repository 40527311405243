import type { CreditCardState, DemographicState, InsuranceState, ProfileProgressState, ProfileState } from '~types';

export const PROFILE_STATE_KEY = 'profileState';
export const DEMOGRAPHIC_STATE_KEY = 'demographicState';
export const CREDIT_CARD_STATE_KEY = 'creditCardState';
export const INSURANCE_STATE_KEY = 'insuranceState';
export const PROFILE_PROGRESS_STATE_KEY = 'profileProgressState';

export const DEFAULT_PROFILE_STATE: ProfileState = {};

export const DEFAULT_DEMOGRAPHIC_STATE: DemographicState = {
  firstName: '',
  preferredFirstName: '',
  lastName: '',
  birthday: '',
  email: '',
  phone: '',
  otp: '',
  isOptedIntoSms: true,
};

export const DEFAULT_CREDIT_CARD_STATE: CreditCardState = {
  paymentMethod: undefined,
  error: undefined,
  promotionCode: undefined,
  friendReferralCode: undefined,
};

export const DEFAULT_INSURANCE_STATE: InsuranceState = {
  id: '',
  carrier: {},
  card: {},
};

export const DEFAULT_PROFILE_PROGRESS_STATE: ProfileProgressState = {};
