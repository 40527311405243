import { buildUrlSearchParams } from '@almond/utils';

import type { EmailSideEffects, SendEmailTemplateIn } from '@almond/api-types';
import type { SendEmailInParams } from '~types';

export const generateSendEmailIn = (
  linkPath: string,
  params: SendEmailInParams,
  sideEffect: EmailSideEffects
): SendEmailTemplateIn => {
  if (!Object.keys(params).length) return { linkPath, sideEffect };

  const urlSearchParams = new URLSearchParams();

  buildUrlSearchParams(urlSearchParams, params);

  // useSearchParams cannot be empty because we've already checked it above
  return { linkPath: `${linkPath}?${urlSearchParams.toString()}`, sideEffect };
};
