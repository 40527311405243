import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    backgroundColor: theme.background,
    padding: sizes.SPACING_XXL,
    justifyContent: 'center',
  },
  containerFull: {
    flex: 1,
  },
  containerPartial: {
    ...getShadow(),
  },
  image: {
    width: 300,
    height: 392,
    marginHorizontal: 'auto',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'center',
    marginBottom: sizes.SPACING_XS,
  },
  subtitle: {
    textAlign: 'center',
    color: theme.secondaryText,
    marginBottom: sizes.SPACING_L,
  },
  buttons: {
    flexDirection: 'row',
    gap: sizes.SPACING_M,
    justifyContent: 'center',
  },
  button: {
    width: 180,
  },
}));
