import { useCallback } from 'react';

import { adminApi } from '~modules/api';

const useRetreieveByPhone = () => {
  return useCallback(async (phone: string) => {
    try {
      return await adminApi.retrievePatientByPhone(phone);
    } catch (error) {
      return null;
    }
  }, []);
};

export default useRetreieveByPhone;
