import { dateAndTimeParsers } from '@almond/date-and-time';

import type { VisitLocationEnum } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

const MAX_DAY_INTO_FUTURE = 60;

export const getNextVisitInValue = (
  referenceDate: Dayjs,
  location: VisitLocationEnum,
  physicianUuid: string,
  availableDates?: Record<string, { physician: { uuid: string }; location: VisitLocationEnum }[]>
) => {
  if (!availableDates) return undefined;

  for (let index = 1; index <= MAX_DAY_INTO_FUTURE; index += 1) {
    const day = referenceDate.add(index, 'days');
    const dayString = dateAndTimeParsers.toRemoteDate(day);

    if (availableDates[dayString]) {
      const availability = availableDates[dayString];
      const locationAndProviderAvailability = availability.find(
        a => a.location === location && a.physician.uuid === physicianUuid
      );

      if (locationAndProviderAvailability) {
        // Provider+location has availability for day - return difference between it and current day
        return day;
      }
    }
  }

  return undefined;
};
