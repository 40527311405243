import React from 'react';
import { Image } from 'react-native';

import { useTranslation } from '@almond/localization';
import { SkeletonLoader, Text, useTheme } from '@almond/ui';
import { z } from 'zod';

import { combineClassNames, MessageContainer } from '~modules/ui';

import { ReadBioButton } from '../../components';
import { unstable_styles as cssStyles } from './provider.module.css';

import { themedStyles } from './styles';

import type { PhysicianFindOut } from '@almond/api-types';

type ProviderProps = {
  physician: PhysicianFindOut;
};

const providerTypesSchema = z.enum(['np', 'pa', 'ma', 'md']);

export const Provider = (props: ProviderProps) => {
  const { physician } = props;
  const { t } = useTranslation();

  const [styles] = useTheme(themedStyles);

  const parsedProviderType = providerTypesSchema.safeParse(physician.providerType?.toLowerCase());
  const providerType = (() => {
    if (!parsedProviderType.success) return null;

    // For now, we are hardcoding the fact that Christy is an IFM practitioner.
    // TODO include in the db/API
    if (physician.firstName === 'Christy' && physician.lastName === 'Evans') return 'mdIfm';

    return parsedProviderType.data;
  })();

  return (
    <MessageContainer style={[styles.wrapper, cssStyles.provider]}>
      <Image source={{ uri: physician.profileImage }} style={cssStyles.photo} role="presentation" />
      <Text size="m" fontStyle="bold" style={cssStyles.name}>
        {physician.profileLabel}
      </Text>
      {providerType && (
        <Text size="m" style={cssStyles.title}>
          {t(`recommendation.provider.${providerType}`)}
        </Text>
      )}
      <ReadBioButton style={cssStyles.button} physician={physician} />
    </MessageContainer>
  );
};

export const LoadingProvider = () => {
  const [styles] = useTheme(themedStyles);

  return (
    <MessageContainer style={[styles.wrapper, cssStyles.provider]}>
      <SkeletonLoader.Rectangle style={cssStyles.photo} />
      <SkeletonLoader.RoundedRectangle width="25%" height={16} roundness={5} style={cssStyles.name} />
      <SkeletonLoader.RoundedRectangle
        width="40%"
        height={14}
        roundness={5}
        style={combineClassNames([cssStyles.loadingTitle, cssStyles.title])}
      />
      <SkeletonLoader.RoundedRectangle width={80} height={20} roundness={100} style={cssStyles.button} />
    </MessageContainer>
  );
};
