import { useEffect } from 'react';

import { useDeferred } from '@almond/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalSearchParams } from 'expo-router';

import * as axiosBase from '../../axiosBase';

import type React from 'react';

const AxiosConfigurator: React.FC = () => {
  const authContext = useAuth0();
  const searchParams = useGlobalSearchParams();

  // If any requests start before the patientUuid value is populated,
  // wait for the value to be resolved within this promise, instead of
  // failing the requests outright.
  const patientUuidDeferred = useDeferred<string>([searchParams.patient_uuid]);

  useEffect(() => {
    if (searchParams.patient_uuid) {
      patientUuidDeferred.resolve(searchParams.patient_uuid);
    }
  }, [patientUuidDeferred, searchParams.patient_uuid]);

  useEffect(() => {
    // We need to update axios interceptors as soon as authContext is changed.
    // Otherwise, an incorrect access token might be passed to the requests.
    axiosBase.setInterceptors(authContext, patientUuidDeferred.promise);
  }, [authContext, patientUuidDeferred.promise]);

  return null;
};

export default AxiosConfigurator;
