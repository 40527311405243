import React from 'react';

import { Button, useFormContext } from '@almond/ui';

import type { ButtonProps, FormContext } from '@almond/ui';

export type ContextButtonProps = Omit<ButtonProps, 'onPress'> & {
  onPress?: (context: FormContext) => void;
};

const ContextButton: React.FC<ContextButtonProps> = props => {
  const formContext = useFormContext();
  const isDisabled = formContext.isLoading || formContext.isDisabled;

  return <Button isDisabled={isDisabled} {...props} onPress={() => props.onPress?.(formContext)} />;
};

export default ContextButton;
