import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  scrollContainer: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalPadding: {
    paddingHorizontal: sizes.SPACING_L,
  },
  verticalCenter: {
    flex: 1,
    maxWidth: '100%',
    paddingTop: 64,
    paddingBottom: sizes.SPACING_M,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    maxWidth: '100%',
    marginHorizontal: 'auto',
    justifyContent: 'center',
    marginTop: sizes.SPACING_M,
    width: 720,
  },
}));
