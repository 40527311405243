import { useCallback } from 'react';

import { useRouter } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { insuranceAtom, profileAtom, profileProgressAtom } from '~modules/state';

import { INSURANCE_OPTIONS } from '../config';
import { logAndCapture } from '../logger';

import type { ProfileDetail } from '@almond/api-types';

const useProcessProfileResponse = (): ((profile?: ProfileDetail) => void) => {
  const setProfileState = useSetRecoilState(profileAtom);
  const setInsuranceState = useSetRecoilState(insuranceAtom);
  const setProfileProgressState = useSetRecoilState(profileProgressAtom);
  const { setParams } = useRouter();

  return useCallback(
    (profile?: ProfileDetail) => {
      if (!profile) return logAndCapture("The `profile` is not specified, so can't process it.");

      setParams({ profile_uuid: profile.uuid });
      setProfileState(prevState => ({
        ...prevState,
        firstName: profile.firstName,
        preferredFirstName: profile.preferredFirstName,
        lastName: profile.lastName,
        birthday: profile.birthday,
      }));
      setInsuranceState(prevState => ({
        ...prevState,
        id: profile.insurance?.memberId,
        carrier: { value: INSURANCE_OPTIONS.find(insurance => insurance.value === profile.insurance?.carrier) },
      }));
      setProfileProgressState(prevState => ({
        ...prevState,
        demographic: true,
        creditCard: profile.creditCard,
        insurance: !!profile.insurance,
        isInsuranceAccepted: profile.insurance?.isAccepted,
        insuranceId: !!profile.insurance?.memberId,
      }));
    },
    [setInsuranceState, setParams, setProfileProgressState, setProfileState]
  );
};

export default useProcessProfileResponse;
