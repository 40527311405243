import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { ViewProps } from 'react-native';

export type CenterContainerProps = ViewProps;

const CenterContainer: React.FC<CenterContainerProps> = props => {
  const { children, ...restProps } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View {...restProps} style={styles.verticalCenter}>
      <View style={styles.content}>{children}</View>
    </View>
  );
};

export default CenterContainer;
