import { useCallback } from 'react';

import { useAuth0 as nativeUseAuth0 } from '@auth0/auth0-react';
import { useGlobalSearchParams } from 'expo-router';

import { useGetAppState } from '~modules/state';

import { log } from '../logger';

import type { RedirectLoginOptions, UseAuth0Return } from '~types';

// TODO replace this with useLoginWithRedirect(), to make it clear the
// rest of the useAuth0() functionality isn't being changed
const useAuth0 = (): UseAuth0Return => {
  const { loginWithRedirect, ...restAuth0Context } = nativeUseAuth0();
  const getAppState = useGetAppState();
  const searchParams = useGlobalSearchParams();

  const almondLoginWithRedirect = useCallback(
    async (options: RedirectLoginOptions = {}) => {
      const { dispatch, authorizationParams, openUrl, restoreRecoilState, ...restOptions } = options;

      log('Login with redirect has been called with the next options:', options);

      const appState = await getAppState(restoreRecoilState, dispatch);

      log('App state will be stored for the current user session:', appState);

      await loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
          screen_hint: 'login',
          login_hint: searchParams.email,
          ...authorizationParams,
        },
        appState,
        openUrl(url) {
          window.history.replaceState(
            {
              ...(window.history.state || {}),
              navigatingToAuth0: true,
            },
            '',
            window.location.href
          );

          if (openUrl) {
            openUrl(url);
          } else {
            window.location.assign(url);
          }
        },
        ...restOptions,
      });

      await new Promise((_, reject) => {
        // If the redirect to Auth0 hasn't happened in 10 seconds,
        // assume there's an error.
        setTimeout(() => {
          reject(new Error('Redirecting to the login page took too long. Please try again.'));
        }, 10000);
      });
    },
    [searchParams.email, getAppState, loginWithRedirect]
  );

  return { ...restAuth0Context, loginWithRedirect: almondLoginWithRedirect };
};

export default useAuth0;
