import { sizes, styleSheetFactory } from '@almond/ui';

export const calendarBarStyles = styleSheetFactory(() => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    paddingVertical: sizes.SPACING_S,
  },
  disabled: {
    opacity: 0,
  },
  scrollButton: {
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  leftArrowIcon: {
    transform: [{ scaleX: -1 }],
  },

  disabledFakeContainer: {
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
    opacity: 0.35,
  },
  disabledFakeTransparent: {
    opacity: 0,
  },
  disabledFakeDim: {
    opacity: 0.2,
  },
}));

export const DAY_WIDTH = sizes.SPACING_XXL;
export const DAY_WIDTH_MARGIN_RIGHT = sizes.SPACING_M;

export const dayStyles = styleSheetFactory(theme => ({
  container: {
    gap: sizes.SPACING_S,
    padding: sizes.SPACING_S,
    borderRadius: sizes.SPACING_XS,
    width: DAY_WIDTH,
    height: 60,
    alignItems: 'center',
    marginRight: DAY_WIDTH_MARGIN_RIGHT,
  },
  selected: {
    backgroundColor: theme.primary,
    color: theme.lightText,
  },
  weekdayText: {
    textTransform: 'uppercase',
  },
  disabled: {
    opacity: 0.5,
  },
  loading: {
    justifyContent: 'space-between',
  },
}));
