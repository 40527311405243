.curatedWrapper {
  align-items: center;

  padding-block: 20px;
  padding-inline: 20px;
  margin-inline: auto;
  gap: 20px;
  width: 100%;
  max-width: 540px;
}

.curatedImage {
  /* Need to set !important because react-native-web <Image> component
   * forces height inline style. Set aspect-ratio to keep height correct
   */
  height: initial !important;
  /* TODO: don't hardcode this */
  aspect-ratio: 1309 / 1441;

  max-height: 400px;
}

.text {
  text-align: center;
  margin-bottom: 20px;
}

.ctaWrapper {
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.navigationBarMobile {
  background-color: var(--color-light-accent);
}

.backgroundMobile {
  background-color: var(--color-light-secondary-background);
}
