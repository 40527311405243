import * as axiosBase from '../axiosBase';
import { APPOINTMENT_PATH, APPOINTMENTS_PATH, BOOKING_PATH, LAB_SUBMIT_PATH, PAST_COUNT_PATH } from './config';

export const createAppointment = axiosBase.post(APPOINTMENTS_PATH);

export const createAppointmentBooking = (appointmentUuid: string, skipConfirmationMessages: boolean) =>
  axiosBase.post(BOOKING_PATH(appointmentUuid), { timeout: 16000 })({ skipConfirmationMessages });

export const getAppointment = (appointmentUuid: string) => axiosBase.get(APPOINTMENT_PATH(appointmentUuid));

export const submitLabOrders = (appointmentUuid: string) => axiosBase.post(LAB_SUBMIT_PATH(appointmentUuid));

export const getPastAppointmentCount = (patientUuid: string) => axiosBase.get(PAST_COUNT_PATH(patientUuid));
