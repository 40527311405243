import { useLocalSearchParams, useRouter } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { appointmentsApi } from '~modules/api';
import { appointmentAvailabilityMetadataAtom, appointmentParamsAtom, creditCardAtom } from '~modules/state';

const useCreateAppointment = () => {
  const searchParams = useLocalSearchParams();
  const { setParams } = useRouter();

  return useRecoilCallback(
    callbackInterface => async () => {
      const creditCardState = await callbackInterface.snapshot.getPromise(creditCardAtom);
      const appointmentParamsState = await callbackInterface.snapshot.getPromise(appointmentParamsAtom);
      const appointmentAvailabilityMetadataState = await callbackInterface.snapshot.getPromise(
        appointmentAvailabilityMetadataAtom
      );
      const price = creditCardState.product?.price.id;

      if (!searchParams.visit_reason) {
        throw new Error("Appointment `visitReason` is not specified, so can't create the appointment.");
      } else if (!appointmentParamsState.type) {
        throw new Error("Appointment `type` is not specified, so can't create the appointment.");
      } else if (!appointmentParamsState.visitTime?.value) {
        throw new Error("Appointment `visitTime` is not specified, so can't create the appointment.");
      } else if (!appointmentParamsState.careTeamMember) {
        throw new Error("Appointment `careTeamMember` is not specified, so can't create the appointment.");
      }

      const firstMonthFree = creditCardState.promotionCode?.code === 'firstmonthfree';

      const response = await appointmentsApi.createAppointment({
        price,
        appointmentType: appointmentParamsState.type,
        appointmentTime: appointmentParamsState.visitTime.value,
        careTeamMember: appointmentParamsState.careTeamMember,
        appointmentReasons: [searchParams.visit_reason],
        firstMonthFree,
        promotionCode:
          creditCardState.promotionCode?.state === 'success' && !firstMonthFree
            ? creditCardState.promotionCode.id
            : undefined,
        friendReferralCode:
          creditCardState.friendReferralCode?.state === 'success' ? creditCardState.friendReferralCode.id : undefined,
        availabilityMetadata: appointmentAvailabilityMetadataState,
      });

      setParams({ appointment_uuid: response.appointment });

      return response.details;
    },
    [searchParams.visit_reason, setParams]
  );
};

export default useCreateAppointment;
