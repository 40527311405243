import { useCallback } from 'react';

import { useTranslation } from '@almond/localization';

import { patientsApi } from '~modules/api';
import { dataTransformsUtilities } from '~modules/dataTransforms';

import { fieldsWithDefaultValues } from '../config';

import type { FormValues } from '@almond/ui';
import type { HistoryState } from '~types';

const useCompleteHistory = (): ((values: FormValues<HistoryState>) => Promise<void>) => {
  const { t } = useTranslation();

  return useCallback(
    async (values: FormValues<HistoryState>) => {
      await patientsApi.completeHistory({
        ...values,
        // We don't store the default values inside the local state, and only sync them with the backend.
        // So, we need to restore them when completing the history, otherwise they will be replaced with the empty ones.
        ...fieldsWithDefaultValues.reduce((acc, cur) => {
          return { ...acc, [cur]: values[cur] || t(`${cur}.skipTitle`) };
        }, {}),
        pregnancies:
          values.pregnancies && dataTransformsUtilities.transformSelectInputValuesToApiEnumList(values.pregnancies),
        needHealthRecords: !!values.needHealthRecords,
        doctorInfo: values.needHealthRecords ? values.doctorInfo : undefined,
        perniciousHabits:
          values.perniciousHabits &&
          dataTransformsUtilities.transformSelectInputValuesToApiEnumObject(values.perniciousHabits),
        currentState: 'completed',
      });
    },
    [t]
  );
};

export default useCompleteHistory;
