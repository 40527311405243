import {
  ALLERGIES_PAGE_NAME,
  DOCTOR_INFO_PAGE_NAME,
  DRUG_ALLERGIES_PAGE_NAME,
  FAMILY_HISTORY_PAGE_NAME,
  HEALTH_CONDITIONS_PAGE_NAME,
  HOSPITALIZATION_PAGE_NAME,
  MEDICATIONS_PAGE_NAME,
  OCCUPATION_PAGE_NAME,
  PERNICIOUS_HABITS_PAGE_NAME,
  PREGNANCIES_PAGE_NAME,
  PRIMARY_CARE_PROVIDER_PAGE_NAME,
  SURGERIES_PAGE_NAME,
} from '~types';

import { accountSetupRoutes } from './accountSetup';

import type { RoutingConfig, StackParamList } from '~types';

export const historyRoutes = {
  id: 'history' as const,
  initial: () => ({ name: HEALTH_CONDITIONS_PAGE_NAME }),
  routes: {
    [HEALTH_CONDITIONS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: MEDICATIONS_PAGE_NAME }),
      },
    },
    [MEDICATIONS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: DRUG_ALLERGIES_PAGE_NAME }),
      },
    },
    [DRUG_ALLERGIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: ALLERGIES_PAGE_NAME }),
      },
    },
    [ALLERGIES_PAGE_NAME]: {
      on: {
        submit: (_1, _2, params?: { isObAppointment: boolean }) => {
          if (params?.isObAppointment) return { name: FAMILY_HISTORY_PAGE_NAME };

          return { name: SURGERIES_PAGE_NAME };
        },
      },
    },
    [SURGERIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PREGNANCIES_PAGE_NAME }),
      },
    },
    [PREGNANCIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: HOSPITALIZATION_PAGE_NAME }),
      },
    },
    [HOSPITALIZATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: FAMILY_HISTORY_PAGE_NAME }),
      },
    },
    [FAMILY_HISTORY_PAGE_NAME]: {
      on: {
        submit: () => ({ name: DOCTOR_INFO_PAGE_NAME }),
      },
    },
    [DOCTOR_INFO_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PRIMARY_CARE_PROVIDER_PAGE_NAME }),
      },
    },
    [PRIMARY_CARE_PROVIDER_PAGE_NAME]: {
      on: {
        submit: () => ({ name: OCCUPATION_PAGE_NAME }),
      },
    },
    [OCCUPATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PERNICIOUS_HABITS_PAGE_NAME }),
      },
    },
    [PERNICIOUS_HABITS_PAGE_NAME]: {
      on: {
        submit: () => accountSetupRoutes,
      },
    },
  },
} satisfies RoutingConfig<StackParamList>;
