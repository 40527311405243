import { type PropsWithChildren, useEffect } from 'react';

import { useProcessInitialUrl, useProcessReferrals } from '../../hooks';

import type { BaseParamsWithoutRouting, RoutingConfig, StackParamList } from '~types';

export type RouteBehaviorProps =
  | { allowDirectRouting?: false; routingConfig?: never; forceSearchParams?: never }
  | {
      allowDirectRouting: true;
      /**
       * The routing config to attach if directly navigated to, or a function that returns
       * the routing config to navigate to based on the URL search params.
       *
       * Refreshing a page will only keep it on the same routing config from pre-reload if
       * that routing config is passed here or returned when called with searchParams. Otherwise,
       * it will act as though the routingConfig wasn't specified at all and redirect to the
       * beginning of the flow.
       */
      routingConfig:
        | RoutingConfig<StackParamList>
        | ((searchParams: URLSearchParams) => RoutingConfig<StackParamList> | null);

      forceSearchParams?: BaseParamsWithoutRouting;
    };

export const RouteBehavior = (props: PropsWithChildren<RouteBehaviorProps & { alreadyInitted: boolean }>) => {
  const processInitialUrl = useProcessInitialUrl();

  useProcessReferrals(!props.alreadyInitted);

  useEffect(() => {
    if (props.alreadyInitted) {
      return;
    }

    processInitialUrl(props.allowDirectRouting ?? false, props.routingConfig, props.forceSearchParams);
  }, [processInitialUrl, props.allowDirectRouting, props.alreadyInitted, props.routingConfig, props.forceSearchParams]);

  return props.children;
};
