import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { IconButton, useTheme } from '@almond/ui';

import { VisitReasonSwitcher } from '../VisitReasonSwitcher';

import { visitReasonsThemedStyles } from './styles';

import type { Key } from 'react';

type VisitReasonHeaderMobileProps = {
  onInformationPress?: () => void;
  disableSwitching?: boolean;
  onSelectionChange?: (reason: Key) => void;
};

export const VisitReasonHeaderMobile = (props: VisitReasonHeaderMobileProps) => {
  const { t } = useTranslation();
  const { onSelectionChange } = props;
  const [styles] = useTheme(visitReasonsThemedStyles);

  return (
    <>
      <View style={styles.visitReasonHeaderMobile}>
        <VisitReasonSwitcher hideIcon onSelectionChange={onSelectionChange} disableSwitching={props.disableSwitching} />
      </View>
      {props.onInformationPress && (
        <IconButton
          source="info"
          color="primary"
          role="presentation"
          onPress={props.onInformationPress}
          aria-label={t('scheduling.mobileInfoHelperText')}
          style={styles.mobileIcon}
        />
      )}
    </>
  );
};
