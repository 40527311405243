import React from 'react';

import { Container } from '~modules/layout';
import { withRouteBehavior } from '~modules/navigation';
import { PatientLoginPage } from '~modules/patient';
import { configurations } from '~modules/routing';
// `/login` path redirected to from Auth0 on reset password,
// so this path must always stay at `/login`
export default withRouteBehavior(PatientLoginPage, {
  allowDirectRouting: true,
  routingConfig: configurations.postBookingAdmin,
  restoreAuth: true,
  loadingEle: <Container id="login" isLoading />,
  title: 'Member Login',
});
