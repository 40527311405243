import React, { useCallback, useEffect } from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useAdaptive, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Stars } from '~assets';
import { ContainerWithSidebar } from '~modules/layout';
import { isBaselineProduct, useStripeProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom, creditCardAtom, patientProgressAtom } from '~modules/state';
import { NotificationStatusBar } from '~modules/ui';

import { useDetermineFirstMonthFree } from '../../hooks';
import { Baseline } from './Baseline';
import { Content } from './Content';
import { SidebarContent } from './SidebarContent';

import themedStyles from './styles';

import type { PRICING_EXPLANATION_PAGE_NAME } from '~types';

export const PricingExplanationPage: React.FC = () => {
  const { dispatch } = useRouteNavigation<typeof PRICING_EXPLANATION_PAGE_NAME>();
  const { doAsync, isLoading } = useAsync();
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { subscription } = useRecoilValue(patientProgressAtom);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const isResubscribe = !subscription && !isNewMemberRemote;
  const setCreditCardState = useSetRecoilState(creditCardAtom);
  const { product } = useLocalSearchParams();
  const isBaseline = isBaselineProduct(product);
  const { data } = useStripeProduct();
  const defaultAmount = data?.price?.amount;
  const defaultPrice = formatPriceInCents(defaultAmount ?? 0, true);
  const subtitleStyles = useAdaptive(styles.subtitle, [styles.subtitle, styles.subtitleMobile]);

  const title = isBaseline
    ? t('pricingExplanation.baseline.title', { price: defaultPrice })
    : t('pricingExplanation.title');

  const subtitle = isBaseline ? (
    <Text size="l" style={subtitleStyles}>
      {t('pricingExplanation.baseline.subtitle')}
    </Text>
  ) : (
    <Text>{t('pricingExplanation.subtitle')}</Text>
  );

  useEffect(() => {
    if (isResubscribe) {
      setCreditCardState(prev => ({ ...prev, promotionCode: undefined }));
    }
  }, [isResubscribe, setCreditCardState]);

  // If a user accidentally clears FIRSTMONTHFREE code or clicks a link with an invalid code,
  // they can go back to the PricingExplanation page and the code will be reapplied
  useDetermineFirstMonthFree(isResubscribe);

  const handleSubmit = useCallback(() => {
    const toCall = () => dispatch('submit');

    doAsync(toCall);
  }, [doAsync, dispatch]);

  return (
    <ContainerWithSidebar
      id="pricingExplanation"
      statusBar={
        isResubscribe && <NotificationStatusBar type="info" content={t(`pricingExplanation.resubscribeBanner`)} />
      }
      content={
        isBaseline ? (
          <Baseline onSubmitPress={handleSubmit} isLoading={isLoading} />
        ) : (
          <Content onSubmitPress={handleSubmit} isLoading={isLoading} />
        )
      }
      sidebarContent={!isBaseline && <SidebarContent />}
      title={
        <Text
          testID={`pricingExplanation:${t('pricingExplanation.title')}`}
          role="heading"
          aria-level={1}
          fontStyle="medium"
          size="xxxxl"
          style={styles.centerText}
        >
          <Stars style={styles.stars} />
          {title}
        </Text>
      }
      subtitle={subtitle}
    />
  );
};
