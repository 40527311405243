import { cypressUtilities } from '@almond/utils';
import { useNavigation } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { atomsToSerialize } from './config';

import type { AppState } from '~types';
import type { RecoilState } from 'recoil';

/**
 * Used for serializing the state of all of the above atoms. This is useful when redirecting away
 * from the SPA and wanting the state to be restored upon return. This happens when logging in or
 * creating accounts with Auth0.
 * @param getAtomValue An atom getter, can be gotten from useRecoilCallback(), recoilInterface.snapshot.getPromise
 * @returns An object to serialize, with atom keys as keys and values to serialize as values
 */
const getState = async (getAtomValue: (atom: RecoilState<any>) => Promise<unknown>) => {
  return Object.fromEntries(
    await Promise.all(
      atomsToSerialize.map(async atom => {
        return [atom.key, await getAtomValue(atom)] as const;
      })
    )
  );
};

const useGetAppState = () => {
  const navigation = useNavigation();

  return useRecoilCallback(
    recoilInterface =>
      async (restoreRecoilState: boolean | undefined, dispatch?: string): Promise<AppState> => {
        const { snapshot } = recoilInterface;

        return {
          navigationState: navigation.getState(),
          recoilState: restoreRecoilState
            ? {
                ...(await getState(snapshot.getPromise)),
                authState: { isRedirected: true, dispatch },
              }
            : undefined,
          mockedDataLayer: cypressUtilities.isCypressRunning() ? window.mockedDataLayer : undefined,
        };
      },
    [navigation]
  );
};

export default useGetAppState;
