import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { themedTagsStyles } from './styles';

import type { VisitLocationEnum } from '@almond/api-types';

export type TagsProps = {
  isPrimary: boolean;
  duration: number;
  location: VisitLocationEnum;
};

export const Tags: React.FC<TagsProps> = props => {
  const [tagsStyles] = useTheme(themedTagsStyles);
  const { t } = useTranslation();
  const { isPrimary, duration, location } = props;
  const { isMobile } = useBrowserTypeMap();

  return (
    <View style={[tagsStyles.tags, isMobile && tagsStyles.tagsMobile]}>
      {isPrimary && (
        <Text style={tagsStyles.tag} fontStyle="bold" size="xxs">
          {t('scheduling.availabilitySection.recommended')}
        </Text>
      )}
      <Text style={tagsStyles.tag} fontStyle="bold" size="xxs">
        {`${duration} ${t('scheduling.availabilitySection.durationMinutes')}`}
      </Text>
      <Text style={tagsStyles.tag} fontStyle="bold" size="xxs">
        {t(`visitType.${location === 'Office' ? 'officeVisit' : 'videoVisit'}`)}
      </Text>
    </View>
  );
};
