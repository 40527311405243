import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  contentContainer: {
    width: '100%',
  },

  messageContainer: {
    paddingEnd: '30%',
  },
}));
