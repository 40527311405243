import { useEffect } from 'react';

/**
 * In the browser, the browser "back" button works, *but* if the user then tries to
 * use the browser "forward" button the state will reset and bring the user back to
 * the beginning of the flow. This hook disables the browser "forward" button.
 */
const useDisableForwardNavigation = (): void => {
  useEffect(() => {
    if (!window) return;

    // Taken from https://stackoverflow.com/a/52609108
    const onPopState = (event: PopStateEvent): void => {
      if (!event.state || typeof event.state !== 'object') return;

      // In order to remove any "forward"-history (i.e. disable forward
      // button), this popstate's event history state (having been navigated
      // back to) must be inserted _again_ as a new history state, thereby
      // making it the new most forwad history state.
      // This leaves the problem that to have this popstate event's history
      // state to become the new top, it would now be multiple in the history
      //
      // Effectively history would be:
      //  * [states before..] ->
      //  * [popstate's event.state] ->
      //  * [*newly pushed _duplicate_ of popstate's event.state ]
      //
      // To remove the annoyance/confusion for the user to have duplicate
      // history states, meaning it has to be clicked at least twice to go
      // back, we pursue the strategy of marking the current history state
      // as "obsolete", as it has been inserted _again_ as to be the most
      // forward history entry.
      //
      // the popstate EventListener will hence have to distinguish 2 cases:
      //
      // case A: the user clicked "back" and encounters one of the duplicate
      // history event entries that are "obsolete" now.
      if (event.state.obsolete) {
        // ...in which case we simply go "back" once more
        window.history.back();
        // by this resolving the issue/problem that the user would
        // be counter-intuively needing to click back multiple times.
        // > we skip over the obsolete duplicates, that have been
        // the result of necessarily pushing a history state to "disable
        // forward navigation"
      }

      // case B: "popstate event is _not_ an obsolete duplicate"...
      else {
        // ...so we _firstly_ mark this state as "obsolete",
        // which can be done using the history API's replaceState method
        window.history.replaceState({ ...event.state, obsolete: true }, '');
        // and _secondly_ push this state _one_more_time_ to the history
        // which will cause the desired "disable forward button"
        window.history.pushState(event.state, '');
      }
    };

    // Set up the popstate EventListener that reacts to browser history events
    window.addEventListener('popstate', onPopState, false);

    return () => {
      window.removeEventListener('popstate', onPopState, false);
    };
  }, []);
};

export default useDisableForwardNavigation;
