import { type MutableRefObject, type RefObject, useRef } from 'react';

import { useEvent, WAIT_FOR_ABORT_ERROR, waitFor } from '@almond/utils';

import type { Dayjs } from 'dayjs';
import type { FlatList, ViewToken } from 'react-native';

export const useScrollControls = (
  scrollContainerRef: RefObject<FlatList<Dayjs>>,
  viewableItemsRef: MutableRefObject<Pick<ViewToken, 'index' | 'item'>[]>,
  onSelectDate: (date: Dayjs) => void,
  dateStatus: (date: Dayjs) => boolean | null
) => {
  const abortControllerRef = useRef<AbortController>();
  // Select the given date. If it doesn't have any available times, loop through
  // each visible date, selecting the first one with non-zero availability.
  // If none have availability (should be very rare), select the first date
  const trySelectingDate = useEvent(async (date: Dayjs) => {
    // If the previous attempt is still waiting, cancel it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    // Don't select the new date until it's fully loaded.
    // Otherwise it may appear to be unavailable even if it is available
    try {
      await waitFor(() => typeof dateStatus(date) === 'boolean', { signal: abortControllerRef.current.signal });
    } catch (e) {
      // Swallow abort errors
      if (e === WAIT_FOR_ABORT_ERROR) {
        return;
      }

      throw e;
    }

    // Try all visible days, except the last one (which is likely only partially visible)
    for (let i = 0; i < viewableItemsRef.current.length - 1; i += 1) {
      const dateCandidate = date.add(i, 'days');

      if (dateStatus(dateCandidate)) {
        return onSelectDate(dateCandidate);
      }
    }

    onSelectDate(date);
  });

  const scrollLeft = useEvent(() => {
    const currentVisible = viewableItemsRef.current[0];

    if (typeof currentVisible?.index !== 'number') {
      return;
    }

    // Don't scroll farther back than "today"
    const index = Math.max(0, currentVisible.index - viewableItemsRef.current.length + 1);

    scrollContainerRef.current?.scrollToIndex({ animated: true, index });

    // Calculate either 1 page back, or today if that's in the past from today
    const dateToSelect = (currentVisible.item as Dayjs).subtract(currentVisible.index - index, 'days');

    trySelectingDate(dateToSelect);
  });

  const scrollRight = useEvent(() => {
    const currentVisible = viewableItemsRef.current[0];

    if (typeof currentVisible?.index !== 'number') {
      return;
    }

    const index = currentVisible.index + viewableItemsRef.current.length - 1;

    scrollContainerRef.current?.scrollToIndex({ animated: true, index });

    trySelectingDate((currentVisible.item as Dayjs).add(index - currentVisible.index, 'days'));
  });

  return { scrollLeft, scrollRight };
};
