.gradient-button {
  background: linear-gradient(-45deg, #ffb064, #eaa05b, #dc9756, #c2834c, #b67e48);
  background-size: 400%;
  animation: gradient 4s ease infinite;
}

@media (prefers-reduced-motion) {
  .gradient-button {
    animation: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
