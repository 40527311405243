import React from 'react';
import { View } from 'react-native';

import { BaseModal, IconButton, useTheme } from '@almond/ui';

import { calendarThemedStyles } from './styles';

import type { BaseModalProps } from '@almond/ui';

export type BottomSheetProps = BaseModalProps;

export const BottomSheet = (props: BottomSheetProps) => {
  const [styles] = useTheme(calendarThemedStyles);
  const { children, ...restProps } = props;

  return (
    <BaseModal {...restProps}>
      <View style={styles.bottomSheetContent}>
        <IconButton source="close" onPress={restProps.onRequestClose} style={styles.close} color="secondaryTextDark" />
        {children}
      </View>
    </BaseModal>
  );
};
