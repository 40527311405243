import { useCallback, useEffect } from 'react';

import { usePathname } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { errorsAtom } from '~modules/state';

const useError = (): ((error: string) => void) => {
  const setErrorsState = useSetRecoilState(errorsAtom);
  const pathname = usePathname();

  useEffect(() => {
    if (!pathname) return;
    // We need to clear the current error as soon as the navigation state is changed.
    // For example, when the user opens a new page.
    setErrorsState({ error: undefined });
  }, [pathname, setErrorsState]);

  return useCallback(
    (error: string) => {
      setErrorsState({ error });
    },
    [setErrorsState]
  );
};

export default useError;
