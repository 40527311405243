import { useCallback } from 'react';

import { useLocalSearchParams } from 'expo-router';

import { profilesApi } from '~modules/api';

import { logAndCapture } from '../logger';

import type { FormValues } from '@almond/ui';
import type { InsuranceIdFormValues } from '~types';

const useUploadInsuranceCard = (): ((values: FormValues<InsuranceIdFormValues>) => Promise<void>) => {
  const searchParams = useLocalSearchParams();

  return useCallback(
    async (values: FormValues<InsuranceIdFormValues>) => {
      if (!searchParams.profile_uuid) {
        return logAndCapture("`profile_uuid` is not specified, so can't upload the insurance card.");
      }

      await profilesApi.uploadInsuranceCardFront(searchParams.profile_uuid, values.card.front);

      if (values.card.back) await profilesApi.uploadInsuranceCardBack(searchParams.profile_uuid, values.card.back);
    },
    [searchParams.profile_uuid]
  );
};

export default useUploadInsuranceCard;
