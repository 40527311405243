import * as axiosBase from '../axiosBase';
import { MONTHLY_AVAILABILITY_PATH, MULTIDAY_AVAILABILITY_PATH } from './config';

import type { GetMonthlyAvailabilityParams, GetMultidayAvailabilityParams } from '@almond/api-types';

export const getMultidayAvailability = (params: GetMultidayAvailabilityParams) =>
  axiosBase.get(MULTIDAY_AVAILABILITY_PATH, { params });

export const getMonthlyAvailability = (params: GetMonthlyAvailabilityParams) =>
  axiosBase.get(MONTHLY_AVAILABILITY_PATH, { params });
