import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  bottom: {
    marginTop: sizes.SPACING_S,
    marginStart: sizes.SPACING_M,
    color: theme.darkText,
  },
  newMemberDisclaimer: {
    color: theme.secondaryTextDark,
    marginBottom: sizes.SPACING_M,
    textAlign: 'center',
  },
}));
