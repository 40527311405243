import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

export default () => {
  const { code, ...localSearchParams } = useLocalSearchParams();

  const searchParams = new URLSearchParams(localSearchParams);

  searchParams.set('refer', code);
  searchParams.delete('is_new_member');

  return <Redirect href={`/visit-reasons?${searchParams}`} />;
};
