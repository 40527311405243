import type { AuthState } from './auth';
import type { AppState as NativeAppState } from '@auth0/auth0-react/dist/auth0-provider';

export * from './accountSetup';
export * from './appointment';
export * from './auth';
export * from './empty';
export * from './errors';
export * from './history';
export * from './integration';
export * from './patient';
export * from './profile';
export * from './questionnaire';
export * from './user';

export type AppState = NativeAppState & {
  recoilState?: Record<string, unknown> & {
    authState: AuthState;
  };
  navigationState?: any;
  mockedDataLayer?: Record<string, any>[];
};
