import React from 'react';

import { useMessageEvent, useTheme } from '@almond/ui';

import { srcDoc } from './srcDoc';

import themedStyles from './styles';

export type SignaturePadProps = { onChange: (signature: string) => void };

export const SignaturePad: React.FC<SignaturePadProps> = props => {
  const [styles] = useTheme(themedStyles);

  useMessageEvent('endStroke', event => {
    props.onChange(event.data.signature);
  });

  return React.createElement('iframe', {
    id: 'iframe',
    style: styles.iframe,
    srcDoc,
  });
};
