import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  footerText: {
    color: theme.secondaryTextDark,
    paddingHorizontal: sizes.SPACING_XXS,
    textAlign: 'center',
  },
  linkText: {
    color: theme.primary,
    textDecorationLine: 'underline',
  },
}));
