import { useCallback, useEffect, useMemo, useState } from 'react';

import { HOME_SITE_HREF } from '@almond/ui';
import { useRecoilState } from 'recoil';

import useAuth0 from '~modules/auth/hooks/useAuth0';
import { integrationAtom } from '~modules/state';

import { useRedirectBackToArtemis } from '../../hooks';

export const useCloseButton = () => {
  const { isAuthenticated } = useAuth0();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [integrationState, setIntegrationState] = useRecoilState(integrationAtom);
  const text: 'backToDashboard' | 'backToWebsite' = (() => {
    if (isAuthenticated) return 'backToDashboard';

    return 'backToWebsite';
  })();
  const redirectBackToArtemis = useRedirectBackToArtemis();

  const toggleModal = useCallback(() => {
    setIsModalVisible(prevState => !prevState);
  }, []);

  const backTo = useCallback(() => {
    if (isAuthenticated) {
      redirectBackToArtemis();

      return;
    }

    window.location.assign(HOME_SITE_HREF);
  }, [isAuthenticated, redirectBackToArtemis]);

  const onPress = useCallback(() => {
    if (integrationState.isAbandonModalDisabled) {
      backTo();

      return;
    }

    toggleModal();
  }, [backTo, integrationState.isAbandonModalDisabled, toggleModal]);

  const onLeavePress = useCallback(() => {
    toggleModal();
    backTo();
  }, [backTo, toggleModal]);

  useEffect(() => {
    setIntegrationState(prevVal => ({
      ...prevVal,
      // Disable the idle modal while the abandon modal is visible
      isAbandonModalVisible: isModalVisible,
    }));
  }, [isModalVisible, setIntegrationState]);

  return useMemo(
    () => ({ text, onPress, onLeavePress, toggleModal, isModalVisible }),
    [isModalVisible, onLeavePress, onPress, text, toggleModal]
  );
};
