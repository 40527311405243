import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  message: {
    ...getShadow(),
    backgroundColor: theme.background,
    borderRadius: 20,
    padding: sizes.SPACING_L,
  },
}));
