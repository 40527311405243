import React from 'react';

import { useController, useFormContext } from '@almond/ui';

import { Signature } from '~modules/ui';

import type { SignatureProps } from '~modules/ui';

export type ConnectedSignatureProps = Omit<SignatureProps, 'onChange'> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedSignature: React.FC<ConnectedSignatureProps> = props => {
  const { name, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, value, ...restField } = field;

  return <Signature {...restField} {...restProps} onChange={signature => onChange(signature)} />;
};

export default ConnectedSignature;
