import React, { useMemo } from 'react';
import { View } from 'react-native';

import { Form, SubmitButton, Text, useTheme } from '@almond/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Container } from '~modules/layout';
import { emptyAtom } from '~modules/state';

import ContextButton from '../ContextButton';
import Error from '../Error';

import themedStyles from './styles';

import type { ContextButtonProps } from '../ContextButton';
import type { ButtonType, FormContext, FormProps, FormValues, RequiredFieldsMode, SubmitButtonProps } from '@almond/ui';
import type { ContainerProps } from '~modules/layout';
import type { StyleProp, ViewStyle } from 'react-native';
import type { RecoilState } from 'recoil';
import type { ObjectSchema } from 'yup';

export type MainFormProps = Pick<FormProps, 'formReturn' | 'isLoading'> &
  React.PropsWithChildren<
    Pick<
      ContainerProps,
      | 'title'
      | 'contentBottomComponent'
      | 'shouldHideCloseButton'
      | 'scrollToBottomTrigger'
      | 'onBack'
      | 'id'
      | 'size'
      | 'statusBar'
      | 'header'
      | 'footer'
    > &
      Pick<FormProps, 'isLoading'> & {
        atom?: RecoilState<any>;
        submitButtonTitle?: string;
        submitButtonType?: ButtonType;
        onSubmit?: (values: FormValues) => void;
        submitButtonTestID?: string;
        submitButtonProps?: Omit<SubmitButtonProps, 'testID' | 'type' | 'requiredFieldsMode' | 'requiredFields'>;
        secondaryButtonTitle?: string;
        onSecondaryPress?: (context: FormContext) => void;
        secondaryButtonTestID?: string;
        secondaryButtonProps?: Omit<ContextButtonProps, 'onPress' | 'mode' | 'type' | 'testID'>;
        skipButtonTitle?: string;
        skipButtonType?: ButtonType;
        onSkipPress?: (context: FormContext) => void;
        isEmpty?: boolean;
        placeholder?: React.ReactNode;
        buttonsStyle?: StyleProp<ViewStyle>;
        isLoading?: boolean;
        isDataLoading?: boolean;
        formError?: React.ReactNode;
        requiredFields?: string[];
        requiredFieldsMode?: RequiredFieldsMode;
        validationSchema?: ObjectSchema<Record<string, any>>;
      }
  >;

const MainForm: React.FC<MainFormProps> = props => {
  const [styles] = useTheme(themedStyles);
  const atom = props.atom || emptyAtom;
  const state = useRecoilValue(atom);
  const {
    onSubmit,
    onSkipPress,
    onSecondaryPress,
    statusBar,
    submitButtonProps = {},
    secondaryButtonProps = {},
  } = props;
  const skipButtonType = props.skipButtonType || 'primary';

  const handleSubmit = useRecoilCallback(
    callbackInterface => async (values: FormValues) => {
      const snapshotState = await callbackInterface.snapshot.getPromise(atom);

      callbackInterface.set(atom, (prevValue: any) => ({ ...prevValue, ...values }));
      onSubmit?.({ ...snapshotState, ...values });
    },
    [onSubmit, atom]
  );

  const content = useMemo(() => {
    if (!props.isEmpty) return props.children;
    if (props.isEmpty && typeof props.placeholder === 'string')
      return (
        <Text size="l" style={styles.placeholder} accessibilityLabel="Empty text">
          {props.placeholder}
        </Text>
      );

    return props.placeholder;
  }, [props.children, props.isEmpty, props.placeholder, styles.placeholder]);

  return (
    <Form
      name="Main form"
      onSubmit={handleSubmit}
      defaultValues={state}
      isLoading={props.isLoading}
      isDisabled={props.isLoading || props.isDataLoading}
      formReturn={props.formReturn}
      shouldUnregister
      resolver={props.validationSchema ? yupResolver(props.validationSchema) : undefined}
    >
      <Container
        id={props.id}
        title={props.title}
        contentBottomComponent={props.contentBottomComponent}
        shouldHideCloseButton={props.shouldHideCloseButton}
        scrollToBottomTrigger={props.scrollToBottomTrigger}
        onBack={props.onBack}
        size={props.size}
        isLoading={props.isDataLoading}
        statusBar={statusBar}
        content={
          content && (
            <View style={styles.input} testID="MainForm">
              {content}
            </View>
          )
        }
        footer={
          <View style={[styles.buttons, props.buttonsStyle]}>
            {!!props.submitButtonTitle && (
              <SubmitButton
                testID={props.submitButtonTestID || 'Submit'}
                requiredFields={props.requiredFields}
                requiredFieldsMode={props.requiredFieldsMode}
                type={props.submitButtonType}
                {...submitButtonProps}
              >
                {props.submitButtonTitle}
              </SubmitButton>
            )}

            {!!props.secondaryButtonTitle && (
              <ContextButton
                type="secondary"
                onPress={onSecondaryPress}
                testID={props.secondaryButtonTestID || 'Secondary'}
                {...secondaryButtonProps}
              >
                {props.secondaryButtonTitle}
              </ContextButton>
            )}

            {!!props.skipButtonTitle && (
              <ContextButton mode="text" type={skipButtonType} onPress={onSkipPress} testID="Skip">
                {props.skipButtonTitle}
              </ContextButton>
            )}
            {typeof props.formError === 'string' || typeof props.formError === 'undefined' ? (
              <Error formError={props.formError} />
            ) : (
              props.formError
            )}

            {props.footer}
          </View>
        }
        header={props.header}
      />
    </Form>
  );
};

export default MainForm;
