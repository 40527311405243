import React, { useEffect, useRef } from 'react';

import env from '~env';
import { Container } from '~modules/layout';

import type { ScrollView } from 'react-native';

export const NewMemberSchedulingPage: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const scrollViewRef = useRef<ScrollView>(null);

  useEffect(() => {
    // The Acuity iframe sends the parent window message events when the page
    // inside the iframe changes. These events include the height of the content
    // inside the iframe, as well as instructions to scroll to the top of the
    // page when the internal page changes.
    // This script assumes the scrollable element is the window/html/body element.
    // However, because of React Native, our scrollable element is not the window,
    // but is an inner element. So we need to write our own event handler to process
    // these events and scroll the page to the top when appropriate.
    //
    // If we switch to using a window/html/body scrollable element, we can delete
    // this custom code and instead inject the Acuity script as commented out below.
    const onMessage = (event: MessageEvent) => {
      if (!iframeRef?.current || !event) return;
      if (
        typeof iframeRef.current.contentWindow === 'undefined' ||
        typeof event.source === 'undefined' ||
        iframeRef.current.contentWindow !== event.source
      ) {
        return;
      }

      if (typeof event.data !== 'string') return;

      // ✅ Message came frome the iframe
      // Only respond to "sizing:<num>" or "scrollTo:<num>"

      const match = event.data.match(/^(sizing|scrollTo):(\d+)$/);

      if (!match) {
        return;
      }

      const [, command, numberStr] = match;

      const number = parseInt(numberStr, 10);

      if (command === 'sizing') {
        iframeRef.current.style.height = `${number + 10}px`;
      } else if (command === 'scrollTo') {
        scrollViewRef?.current?.scrollTo(number);
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [scrollViewRef]);

  // Uncomment if the scrollable element becomes the window/html/body element
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.setAttribute('src', 'https://embed.acuityscheduling.com/js/embed.js');
  //   script.setAttribute('type', 'text/javascript');
  //   document.body.append(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <Container id={'newMemberSchedulingPage' as any} scrollViewRef={scrollViewRef}>
      <iframe
        ref={iframeRef}
        src={env.ACUITY_SCHEDULING_EMBED}
        title="Schedule Appointment"
        width="100%"
        height="800"
        frameBorder="0"
      />
    </Container>
  );
};
