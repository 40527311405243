import { useCallback } from 'react';

import { useLocalSearchParams } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { patientsApi } from '~modules/api';
import { patientAtom } from '~modules/state';

import { log, logAndCapture } from '../logger';
import useProcessPatientResponse from './useProcessPatientResponse';

import type { PatientDetailOut } from '@almond/api-types';

const useGetOrCreatePatient = () => {
  const processPatientResponse = useProcessPatientResponse();
  const searchParams = useLocalSearchParams();
  const setPatientState = useSetRecoilState(patientAtom);

  return useCallback(async () => {
    if (!searchParams.profile_uuid) {
      logAndCapture("`profile_uuid` is not specified so can't get or create the patient.");

      return;
    }

    let patientResponse: PatientDetailOut;

    if (searchParams.patient_uuid) {
      log('The current patient has been already created, so just retrieving him.');

      patientResponse = await patientsApi.retrieve();
    } else {
      log("The current patient doesn't exist, so creating a new one.");

      patientResponse = await patientsApi.createPatient(searchParams.profile_uuid);
      setPatientState(prevState => ({ ...prevState, createdPatientUuid: patientResponse.uuid }));
    }

    processPatientResponse(patientResponse, null);

    return patientResponse.uuid;
  }, [processPatientResponse, searchParams.patient_uuid, searchParams.profile_uuid, setPatientState]);
};

export default useGetOrCreatePatient;
