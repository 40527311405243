import { sizes, styleSheetFactory } from '@almond/ui';

export const CALENDAR_HEIGHT = 280;

export default styleSheetFactory(theme => ({
  header: {
    flexDirection: 'row',
    height: 48,
    alignItems: 'center',
  },
  headerTitle: {
    flex: 1,
    textAlign: 'center',
    color: theme.darkText,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  firstRow: {
    justifyContent: 'flex-end',
  },
  lastRow: {
    justifyContent: 'flex-start',
  },
  dayOfWeek: {
    flex: 1,
    textAlign: 'center',
    marginBottom: sizes.SPACING_S,
    color: theme.secondaryTextDark,
  },
  dateContainer: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: sizes.SPACING_S,
  },
  dateDefault: {
    width: sizes.SPACING_L,
    height: sizes.SPACING_L,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: sizes.SPACING_M,
  },
  dateDisabled: {
    cursor: 'default',
  } as any,
  dateCurrent: {},
  dateSelected: {
    backgroundColor: theme.primary,
  },
  dateTextDefault: {
    color: theme.text,
  },
  dateTextDisabled: {
    color: theme.placeholder,
  },
  dateTextCurrent: {
    color: theme.primary,
  },
  dateTextSelected: {
    color: theme.lightText,
  },
  calendarWrapper: {
    backgroundColor: theme.background,
    width: 246,
    maxHeight: CALENDAR_HEIGHT,
  },
}));
