import * as axiosBase from '../axiosBase';
import { PHYSICIANS_PATH } from './config';

import type { ProviderTypesEnum } from '@almond/api-types';

export const listPhysicians = (isActive?: boolean, providerTypes?: ProviderTypesEnum[]) => {
  const params: any = { is_active: isActive };

  // The API defaults to provider types of MD, NP, and PA.
  // If a request needs CG and MA data, provider_types needs to be explicitly set to all requested types.
  if (providerTypes) {
    params.provider_types = providerTypes;
  }

  return axiosBase.get(PHYSICIANS_PATH, { params });
};
