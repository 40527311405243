import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { PulseProvider, SkeletonLoader, useTheme } from '@almond/ui';

import { getVisitCategoryIcon } from '~assets';

import { BlockItemLink } from '../../components';
import { useVisitReasons } from '../../hooks';

import themedStyles from './styles';

type VisitCategoriesProps = {
  isLoading: boolean;
  onSubmit: (item: string) => void;
};

// TODO: Hardcoding categories for now, but they will be fetched from the backend in the future.
const categories = ['general', 'maternity', 'The PCOS Membership', 'Health Coaching', 'Baseline'] as const;

export const VisitCategories = (props: VisitCategoriesProps) => {
  const { isLoading, onSubmit } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const { visitReasons, isLoadingVisitReasons } = useVisitReasons();

  if (isLoading || isLoadingVisitReasons) {
    return (
      <PulseProvider duration={1500}>
        <View style={styles.loaderContainer}>
          <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
          <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
          <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
          <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        </View>
      </PulseProvider>
    );
  }

  return (
    <>
      {categories.map(category => {
        const categoryInfo = t(`visitCategories.categories.${category}`, { returnObjects: true });

        const categoryVisitReasons = visitReasons?.filter(v => v.category === category && v.isActive) ?? [];

        if (categoryVisitReasons.length === 0) {
          return null;
        }

        return (
          <BlockItemLink
            key={category}
            testID={category}
            title={categoryInfo.title}
            subtitle={categoryInfo.subtitle}
            icon={getVisitCategoryIcon(category)}
            onPress={() => onSubmit(category)}
          />
        );
      })}
    </>
  );
};
