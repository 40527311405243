import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  states: {
    marginHorizontal: sizes.SPACING_S,
    color: theme.primary,
  },
  itemText: {
    padding: sizes.SPACING_S,
  },
  placeholder: {
    textAlign: 'center',
  },
  results: {
    backgroundColor: theme.background,
    padding: sizes.SPACING_S,
    maxHeight: 208,
    marginBottom: sizes.SPACING_S,
    borderBottomStartRadius: sizes.SPACING_S,
    borderBottomEndRadius: sizes.SPACING_S,
  },
  inputWithResults: {
    borderBottomStartRadius: 0,
    borderBottomEndRadius: 0,
    borderBottomWidth: sizes.SPACING_XXS,
    borderBottomColor: theme.placeholder,
  },
}));
