import { useEffect } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { isBaselineProduct } from '~modules/product';
import { appointmentParamsAtom, creditCardAtom } from '~modules/state';

import type { CreditCardState } from '~types';

export const useProcessReferrals = (isEnabled: boolean) => {
  const [creditCardState, setCreditCardState] = useRecoilState(creditCardAtom);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const { searchParams } = new URL(window.location.href);

    const creditCardStateToUpdate: Pick<CreditCardState, 'friendReferralCode' | 'promotionCode'> = {};

    const referCode = searchParams.get('refer')?.toLowerCase();
    const existingReferCode = creditCardState.friendReferralCode?.code;

    if (referCode && referCode !== existingReferCode) {
      creditCardStateToUpdate.friendReferralCode = { code: referCode, state: 'init' };
    }

    // When coming back from Auth0, `code` and `state` params are passed in. This is a naming
    // clash with the promotion code param, and due to a race condition, sometimes we get here
    // with an unconsumed auth redirect in the URL still.
    // If there is also a `state` param, we know the `code` is actually for Auth0, so we ignore
    // it here.
    let promotionCode = searchParams.get('state') ? null : searchParams.get('code')?.toLowerCase();
    const existingPromotionCode = creditCardState.promotionCode?.code;

    if (!promotionCode && isNewMemberRemote && !isBaselineProduct(searchParams.get('product'))) {
      promotionCode = 'firstmonthfree';
    }

    if (promotionCode && promotionCode !== existingPromotionCode) {
      creditCardStateToUpdate.promotionCode = { code: promotionCode, state: 'init' };
    }

    if (Object.keys(creditCardStateToUpdate).length) {
      setCreditCardState(prevState => ({ ...prevState, ...creditCardStateToUpdate }));
    }
  }, [
    creditCardState.friendReferralCode,
    creditCardState.promotionCode,
    isEnabled,
    isNewMemberRemote,
    setCreditCardState,
  ]);
};
