import React, { useCallback } from 'react';
import { FlatList, View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import { ResultItem } from './ResultItem';

import themedStyles from './styles';

import type { SelectOption } from '@almond/ui';
import type { ListRenderItemInfo } from 'react-native';

export type ResultsProps<T extends string> = {
  data: SelectOption<T>[];
  onSelect: (value: SelectOption<T>) => void;
  query?: string;
  isHidden?: boolean;
};

export const Results = <T extends string>(props: ResultsProps<T>) => {
  const { t } = useTranslation();
  const { onSelect } = props;
  const [styles] = useTheme(themedStyles);

  const keyExtractor = useCallback((item: SelectOption<T>) => item.value, []);

  const renderItem = useCallback(
    (itemInfo: ListRenderItemInfo<SelectOption<T>>) => <ResultItem item={itemInfo.item} onPress={onSelect} />,
    [onSelect]
  );

  if (!props.query || props.isHidden) return null;
  if (!props.data.length)
    return (
      <View style={styles.results}>
        <Text style={styles.placeholder}>{t('noStates')}</Text>
      </View>
    );

  return (
    <View style={styles.results}>
      <Text style={styles.states}>{t('states')}</Text>
      <FlatList data={props.data} renderItem={renderItem} keyExtractor={keyExtractor} />
    </View>
  );
};
