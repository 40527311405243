import React, { useRef, useState } from 'react';
import { View } from 'react-native';

import { BaseButton, MaterialIcon, Popover, sizes, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { ScrollableCalendar } from '~modules/ui';

import { BottomSheet } from './BottomSheet';

import { calendarThemedStyles } from './styles';

import type { useMonthlyRequest } from './useMonthlyRequest';
import type { ScrollableCalendarProps } from '~modules/ui';
import type { Dayjs } from 'dayjs';

export type CalendarButtonProps = Pick<ScrollableCalendarProps, 'onDateSelect' | 'numVisible'> &
  Pick<ReturnType<typeof useMonthlyRequest>, 'availableDates' | 'requestDataAt'> & {
    selectedDate: Dayjs;
  };

export const CalendarButton = (props: CalendarButtonProps) => {
  const [styles] = useTheme(calendarThemedStyles);
  const { onDateSelect, numVisible, availableDates, requestDataAt, selectedDate } = props;
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { isDesktop, isMobile } = useBrowserTypeMap();

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };

  const handleDateSelect = (date: Dayjs) => {
    toggleVisibility();
    onDateSelect?.(date);
  };

  const calendar = (
    <ScrollableCalendar
      date={selectedDate}
      onDateSelect={handleDateSelect}
      numVisible={numVisible}
      availableDates={availableDates}
      requestDataAt={requestDataAt}
    />
  );

  return (
    <>
      <View ref={ref}>
        <BaseButton
          style={styles.calendarButton}
          onPress={toggleVisibility}
          aria-expanded={isVisible}
          role="button"
          aria-controls="calendarPopover"
          testID="Scheduling_OpenCalendar"
        >
          <Text fontStyle="bold" style={styles.calendarButtonText}>
            {selectedDate.format('MMMM YYYY')}
          </Text>
          <MaterialIcon source="keyboard-arrow-down" color="secondaryTextDark" size={sizes.SPACING_L} />
        </BaseButton>
      </View>
      {isDesktop && (
        <Popover anchorElement={ref} isVisible={isVisible} onRequestClose={toggleVisibility}>
          <View style={styles.popover} id="calendarPopover">
            {calendar}
          </View>
        </Popover>
      )}
      {isMobile && (
        <BottomSheet isVisible={isVisible} onRequestClose={toggleVisibility}>
          {calendar}
        </BottomSheet>
      )}
    </>
  );
};
