import AppError from './AppError';
import { errorsParsers } from './services';

import type { ErrorCode, ErrorDetails } from '~types';

export default class RuntimeError extends AppError {
  constructor(code: ErrorCode, message?: string, details: ErrorDetails = {}) {
    super(code, message, details);
  }

  static fromOriginal(originalError: unknown, code: ErrorCode, message?: string): RuntimeError {
    if (originalError instanceof Error) {
      const runtimeError = new RuntimeError(code, message || originalError.message);

      runtimeError.stack = originalError.stack;

      return runtimeError;
    }

    return new RuntimeError(code, errorsParsers.getErrorMessageWithFallback(message, originalError));
  }
}
