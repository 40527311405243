import { useEffect } from 'react';

import { usePathname } from 'expo-router';
import { useResetRecoilState } from 'recoil';

import { errorsAtom } from '~modules/state';

export const useResetErrorState = () => {
  const pathname = usePathname();
  const resetErrorsState = useResetRecoilState(errorsAtom);

  useEffect(() => {
    if (!pathname) return;

    resetErrorsState();
  }, [pathname, resetErrorsState]);
};
