import { useEffect, useMemo } from 'react';

import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';
import useSWRImmutable from 'swr/immutable';

import { VISIT_REASONS_PATH, visitReasonsApi } from '~modules/api';
import { useAuth0 } from '~modules/auth';
import { navigationUtilities } from '~modules/navigation';
import { useRouteToConfig } from '~modules/routing';
import { appointmentParamsAtom } from '~modules/state';
import { useIsAdmin } from '~modules/user';

import { getVisitOutcome } from '../services';

import type { VisitOutcome } from '../services';
import type { GetVisitReasonsParams, VisitReasonOut } from '@almond/api-types';

export type VisitReason = Omit<VisitReasonOut, 'configuration'> & { visitOutcome: VisitOutcome | undefined };

export const useVisitReasons = () => {
  const { isAuthenticated, isLoading: isAuthenticating } = useAuth0();
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const isAdmin = useIsAdmin();
  const searchParams = useLocalSearchParams();
  const routeToConfig = useRouteToConfig();

  const key: [GetVisitReasonsParams, string] | null = useMemo(() => {
    if (isAuthenticating || (!isAuthenticated && !isNewMemberRemote)) {
      // Prevent the network request from happening
      return null;
    }

    return [
      // Pass in directly what goes out to the API request
      {
        member_types: isNewMemberRemote ? ['new'] : ['established'],
        bookable_by_roles: isAdmin ? ['internal', 'patient'] : ['patient'],
      },
      VISIT_REASONS_PATH,
    ];
  }, [isAdmin, isAuthenticated, isAuthenticating, isNewMemberRemote]);
  const { data, isLoading, error, mutate } = useSWRImmutable(key, async ([params]) => {
    const result = await Promise.all([
      visitReasonsApi.getVisitReasons({ ...params, is_active: true }),
      visitReasonsApi.getVisitReasons({ ...params, is_active: false }),
    ]);

    return result.map(r => r.visitReasons).flat();
  });

  // We want to tell if a visit reason is associated with a PCOS membership.
  // Reference the category, since "PCOS Care" in "general" category should not be included
  const visitReasons = useMemo(
    () =>
      data?.map(d => ({
        ...d,
        isPcos: d.category.toLowerCase().includes('pcos'),
        visitOutcome: getVisitOutcome(d, {
          infectionOutcome: searchParams.infectionOutcome === 'true',
          showAll: searchParams.showAll === 'true',
        }),
      })) satisfies VisitReason[] | undefined,
    [data, searchParams.infectionOutcome, searchParams.showAll]
  );

  const visitReason = useMemo(() => {
    return visitReasons?.find(reason => reason.code === searchParams.visit_reason);
  }, [searchParams.visit_reason, visitReasons]);

  useEffect(() => {
    if (searchParams.visit_reason && visitReasons && !visitReason) {
      // Visit reason was not found, so it must be invalid. Send user back to beginning of flow
      const config = navigationUtilities.getInitialConfig(searchParams.is_new_member !== 'false');
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { visit_reason, ...searchParamsWithoutVisitReason } = searchParams;

      routeToConfig(config, false, searchParamsWithoutVisitReason);
    }
  }, [routeToConfig, searchParams, visitReason, visitReasons]);

  return {
    visitReason,
    visitReasons,
    isLoadingVisitReasons: isLoading,
    visitReasonsError: error,
    mutateVisitReasons: mutate,
  };
};
