import React from 'react';
import { View } from 'react-native';

import { PulseProvider, sizes, SkeletonLoader, Text, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';

import themedStyles from './styles';

import type { TextProps } from '@almond/ui';

type LineItemProps = {
  title: string;
  amount: number;
  isLoading?: boolean;
  size: Exclude<TextProps['size'], undefined>;
  amountTestID?: string;
};

export const LineItem = (props: LineItemProps) => {
  const { title, amount, isLoading, size, amountTestID } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.priceContainerItem}>
      <View style={styles.priceContainerLeftColumn}>
        <Text size={size}>{title}</Text>
      </View>
      {isLoading ? (
        <PulseProvider duration={1000}>
          <SkeletonLoader.RoundedRectangle
            roundness={3}
            width={60}
            height={sizes[`FONT_SIZE_${size.toUpperCase() as Uppercase<typeof size>}`]}
          />
        </PulseProvider>
      ) : (
        <Text size={size} fontStyle="bold" style={styles.priceContainerRightColumn} testID={amountTestID}>
          {formatPriceInCents(amount)}
        </Text>
      )}
    </View>
  );
};
