import React, { useCallback, useMemo, useState } from 'react';

import {
  IMAGE_MANIPULATOR_HEIGHT,
  IMAGE_MANIPULATOR_WIDTH,
  IMAGE_PICKER_ASPECT_RATIO,
  IMAGE_PICKER_QUALITY,
  TextInput,
} from '@almond/ui';
import { cypressUtilities } from '@almond/utils';
import * as ImageManipulator from 'expo-image-manipulator';
import { launchImageLibraryAsync, MediaTypeOptions } from 'expo-image-picker';

import type { TextInputProps } from '@almond/ui';
import type { ActionResize } from 'expo-image-manipulator';
import type { TextInput as NativeTextInput } from 'react-native';

export type ImageInputProps = Omit<TextInputProps, 'onChangeText'> & {
  onSelect: (uri: string) => void;
  iconTestID?: string;
};

export const ImageInput = React.forwardRef<NativeTextInput, ImageInputProps>((props, ref) => {
  const { onSelect, value, ...restProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(!!value);

  const rightIcon = useMemo(() => {
    if (isLoading) return 'progress-activity';

    if (isLoaded) return 'check';

    return 'add-a-photo';
  }, [isLoaded, isLoading]);

  const rightIconColor = useMemo(() => {
    if (isLoaded) return 'primary';

    return 'placeholder';
  }, [isLoaded]);

  const handlePress = useCallback(async () => {
    // TODO: Pick an image natively in s separate PR.
    if (cypressUtilities.isCypressRunning()) {
      return onSelect(
        // eslint-disable-next-line max-len
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII'
      );
    }

    try {
      const pickerResult = await launchImageLibraryAsync({
        mediaTypes: MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: IMAGE_PICKER_ASPECT_RATIO,
        quality: IMAGE_PICKER_QUALITY,
      });

      if (pickerResult.canceled) return;

      setIsLoading(true);

      const asset = pickerResult.assets[0];
      const resize: ActionResize['resize'] =
        asset.width / asset.height >= IMAGE_MANIPULATOR_WIDTH / IMAGE_MANIPULATOR_HEIGHT
          ? { width: IMAGE_MANIPULATOR_WIDTH }
          : { height: IMAGE_MANIPULATOR_HEIGHT };

      const result = await ImageManipulator.manipulateAsync(asset.uri, [{ resize }], {
        format: ImageManipulator.SaveFormat.PNG,
      });

      setIsLoaded(true);
      onSelect?.(result.uri);
    } catch (error: any) {
      // eslint-disable-next-line no-alert
      alert(error.message);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [onSelect]);

  return (
    <TextInput
      {...restProps}
      ref={ref}
      isLoading={isLoading}
      isDisabled={isLoading}
      onPress={handlePress}
      testID={props.iconTestID}
      right={{ source: rightIcon, color: rightIconColor }}
    />
  );
});
