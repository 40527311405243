import React from 'react';
import { View } from 'react-native';

import { Text, useBrowserTypeMap } from '@almond/ui';

import { InfoMessage } from '../InfoMessage';
import { unstable_styles as styles } from './RecommendationMessage.module.css';

import type { PropsWithChildren } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

type RecommendationMessageProps = PropsWithChildren<{
  subtitle: string;
  bodyText: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}>;

export const RecommendationMessage = (props: RecommendationMessageProps) => {
  const { isMobile, isDesktop } = useBrowserTypeMap();
  const { subtitle, bodyText, style } = props;

  return (
    <View style={[styles.standardWrapper, style]}>
      {isMobile && (
        <Text style={styles.mobileSubtitle} size="m">
          {subtitle}
        </Text>
      )}
      {isDesktop && (
        <InfoMessage
          title={subtitle}
          style={styles.desktopBox}
          description={<View style={styles.bodyWrapper}>{bodyText}</View>}
        />
      )}
      {isMobile && <View style={styles.bodyWrapper}>{bodyText}</View>}
      {props.children}
    </View>
  );
};
