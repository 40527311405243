import { hasBaselineProduct, isBaselineProduct } from '~modules/product';
import { patientProgressAtom } from '~modules/state';
import {
  CONFIRMED_PAGE_NAME,
  CONSENT_SIGNATURE_PAGE_NAME,
  CREDIT_CARD_PAGE_NAME,
  INSURANCE_ID_PAGE_NAME,
  PATIENT_LOGIN_PAGE_NAME,
} from '~types';

import { historyRoutes } from './history';

import type { InsuranceDetail } from '@almond/api-types';
import type { RoutingConfig, StackParamList } from '~types';

export const postBookingAdminRoutes = {
  id: 'postBookingAdmin' as const,
  initial: () => ({ name: PATIENT_LOGIN_PAGE_NAME }),
  routes: {
    [PATIENT_LOGIN_PAGE_NAME]: {
      reset: true,
      on: {
        signin: () => ({ name: CONSENT_SIGNATURE_PAGE_NAME, replace: true }),
      },
    },
    [CONSENT_SIGNATURE_PAGE_NAME]: {
      on: {
        submit: async (getState, searchParams, insurance: Pick<InsuranceDetail, 'isAccepted'>) => {
          const patientProgressState = await getState(patientProgressAtom);
          const { products, subscription } = patientProgressState;

          if (isBaselineProduct(searchParams.product)) {
            const hasPurchasedBaseline = hasBaselineProduct(products);

            if (!hasPurchasedBaseline) {
              return { name: CREDIT_CARD_PAGE_NAME };
            }
          } else if (!subscription) {
            return { name: CREDIT_CARD_PAGE_NAME };
          }

          if (insurance.isAccepted) {
            return { name: INSURANCE_ID_PAGE_NAME };
          }

          return { name: CONFIRMED_PAGE_NAME };
        },
      },
    },
    [CREDIT_CARD_PAGE_NAME]: {
      on: {
        submit: (_1, _2, insurance: Pick<InsuranceDetail, 'isAccepted'>) => {
          if (insurance.isAccepted) {
            return { name: INSURANCE_ID_PAGE_NAME };
          }

          return { name: CONFIRMED_PAGE_NAME };
        },
      },
    },
    [INSURANCE_ID_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CONFIRMED_PAGE_NAME }),
      },
    },
    [CONFIRMED_PAGE_NAME]: {
      on: {
        submit: () => historyRoutes,
      },
    },
  },
} satisfies RoutingConfig<StackParamList>;
