import { useCallback } from 'react';

import { useFocusEffect } from 'expo-router';

export const useSetThemeColor = (color?: string | null) => {
  useFocusEffect(
    useCallback(() => {
      if (!color) {
        return;
      }

      const ele = document.createElement('meta');

      ele.setAttribute('name', 'theme-color');
      ele.setAttribute('content', color);
      document.head.appendChild(ele);

      return () => {
        document.head.removeChild(ele);
      };
    }, [color])
  );
};
