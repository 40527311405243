import type { GooglePlaceDetail } from 'react-native-google-places-autocomplete';

export type Address = {
  name: string;
  placesId: string;
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  postalCode: string;
  region?: string;
  formattedAddress: string;
};

export type GoogleMapApiResult = Omit<GooglePlaceDetail, 'id'> & {
  plus_code?: { compound_code: string; global_code: string };
};

export type Coordinates = { latitude: number; longitude: number };

export type LocationAttributes = Address & Coordinates;
