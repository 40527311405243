import { useEffect, useRef } from 'react';

import { dateAndTimeParsers } from '@almond/date-and-time';
import { useEvent } from '@almond/utils';

import { MONTHLY_API_PAGE_SIZE, TODAY } from './config';

import type { AvailabilityByMonth } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

// Exported so it can be unit tested
export const getInitialDateWithAvailability = (
  availableDates: Record<string, Pick<AvailabilityByMonth['physicianAvailability'][number], 'location' | 'physician'>[]>
) => {
  // Check 1 monthly API call into the future
  // Request goes 3 months from the beginning of the current month,
  // so calculate from start of month
  const maxAttempts = TODAY.startOf('month')
    .add(MONTHLY_API_PAGE_SIZE, 'months')
    // But start attempts at today
    .diff(TODAY, 'days');

  for (let i = 0; i < maxAttempts; i += 1) {
    const currentDate = TODAY.add(i, 'days');
    const currentDateString = dateAndTimeParsers.toRemoteDate(currentDate);

    if (availableDates[currentDateString]) {
      return currentDate;
    }
  }

  return undefined;
};

export const useSetInitialDate = (
  availableDates:
    | Record<string, Pick<AvailabilityByMonth['physicianAvailability'][number], 'location' | 'physician'>[]>
    | undefined,
  setSelectedDate: (newValue: Dayjs) => void
) => {
  // Only do this on initial load
  const hasSetInitialDateRef = useRef(false);

  // Don't re-run if function reference changes
  const memoizedSetSelectedDate = useEvent(setSelectedDate);

  useEffect(() => {
    if (!availableDates || hasSetInitialDateRef.current) {
      return undefined;
    }

    hasSetInitialDateRef.current = true;
    const firstDateWithAvailability = getInitialDateWithAvailability(availableDates);

    if (firstDateWithAvailability) {
      memoizedSetSelectedDate(firstDateWithAvailability);
    }
  }, [availableDates, memoizedSetSelectedDate]);
};
