import { createContext, useContext } from 'react';

import routesConfig from '../configurations';

import type { RoutingConfig } from '~types';

// Exposing the routesConfig to the useRouteNavigation() hook via this context. However,
// the application doesn't need to render the provider, because the default context value
// is the value the application should see.
// The context provider is only required when overriding the default routesConfig for
// unit testing.
const RouteContext = createContext<Record<string, RoutingConfig<any>>>(routesConfig);

export const useRouteContext = <T extends Record<string, RoutingConfig<any>>>(): T => {
  return useContext(RouteContext) as any;
};

export const RouteContextProvider = RouteContext.Provider;
