import { I18n } from '@almond/localization';

import { Yup } from '~modules/forms';

import type { InsuranceState } from '~types';

export const validationSchema: Yup.ObjectSchema<Pick<InsuranceState, 'card'>> = Yup.object().shape({
  card: Yup.object().shape({
    front: Yup.string().required(I18n.t('validations.imageError')),
  }),
});
