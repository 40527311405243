import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { useAsync } from '~modules/promises';
import { loadedPatientUuidAtom, profileAtom, userAtom } from '~modules/state';
import { userUtilities } from '~modules/user';

import useLoadPatient from './useLoadPatient';

const useNavigationBar = () => {
  const loadPatient = useLoadPatient();
  const { isAuthenticated } = useAuth0();
  const userState = useRecoilValue(userAtom);
  const isAdmin = userUtilities.isAdmin(userState.user);
  const { doAsync, isLoading } = useAsync();
  const profileState = useRecoilValue(profileAtom);
  const loadedPatientUuidState = useRecoilValue(loadedPatientUuidAtom);
  const searchParams = useLocalSearchParams();

  const skipLoadingPatient =
    !userState.user || !isAuthenticated || searchParams.is_new_member === 'true' || loadedPatientUuidState;

  useEffect(() => {
    const toCall = async () => {
      await loadPatient();
    };

    if (skipLoadingPatient) {
      return;
    }

    doAsync(toCall);
  }, [doAsync, loadPatient, skipLoadingPatient]);

  const bannerText = (() => {
    if (isAdmin && searchParams.is_new_member === 'true') {
      return 'New Member Booking';
    }

    if (!loadedPatientUuidState || !isAdmin) {
      return 'almond';
    }

    if (isLoading || !profileState.firstName || !profileState.lastName) {
      return '';
    }

    const formattedDateString = dayjs(profileState.birthday, 'YYYY-MM-DD').format('MM/DD/YYYY');

    let preferredName = ' ';

    if (profileState.preferredFirstName) {
      preferredName = ` "${profileState.preferredFirstName}" `;
    }

    return `${profileState.firstName}${preferredName}${profileState.lastName} (${formattedDateString})`;
  })();

  return { isLoading: isAdmin ? isLoading : false, bannerText };
};

export default useNavigationBar;
