import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  input: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  buttons: {
    alignItems: 'center',
    gap: sizes.SPACING_S,
    maxWidth: '100%',
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.placeholder,
  },
}));
