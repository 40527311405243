import React, { useMemo, useState } from 'react';
import { View } from 'react-native';

import { dateAndTimeParsers } from '@almond/date-and-time';
import { useTheme } from '@almond/ui';

import { ChevronRight } from '~assets';

import { Day } from './Day';

import { calendarBarStyles, DAY_WIDTH, DAY_WIDTH_MARGIN_RIGHT } from './styles';

import type { Dayjs } from 'dayjs';

export type CalendarBarInstance = {
  scrollToDate: (date: Dayjs) => void;
};

type DisabledCalendarBarProps = {
  selectedDate: Dayjs;
};

export const DisabledCalendarBar = (props: DisabledCalendarBarProps) => {
  const { selectedDate } = props;
  const [styles] = useTheme(calendarBarStyles);

  // Number of days to show based on how wide the component is rendered
  // Updated using `onLayout` below based on the dynamic size of the element.
  const [numberToShow, setNumberToShow] = useState(0);

  const renderedDateList = useMemo(() => {
    const dates: Dayjs[] = [];

    for (let i = 0; i < numberToShow; i += 1) {
      dates.push(selectedDate.add(i, 'day'));
    }

    return dates;
  }, [numberToShow, selectedDate]);

  return (
    <View style={styles.container} testID="DisabledCalendarBar">
      <View style={[styles.scrollButton, styles.disabledFakeTransparent]}>
        <ChevronRight />
      </View>
      <View
        style={styles.disabledFakeContainer}
        onLayout={event => {
          setNumberToShow(Math.ceil(event.nativeEvent.layout.width / (DAY_WIDTH + DAY_WIDTH_MARGIN_RIGHT)));
        }}
      >
        {renderedDateList.map(date => (
          <Day key={dateAndTimeParsers.toRemoteDate(date)} day={date} isLoading={false} />
        ))}
      </View>
      <View style={[styles.scrollButton, styles.disabledFakeDim]}>
        <ChevronRight />
      </View>
    </View>
  );
};
