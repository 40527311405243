import { useCallback } from 'react';

import { useArtemisRedirectUrl } from './useArtemisRedirectUrl';

export const useRedirectBackToArtemis = () => {
  const artemisRedirectUrl = useArtemisRedirectUrl();

  return useCallback(
    (pathname?: string) => {
      // If the Artemis redirect URL is "_blank", that means that
      // Demi was opened in a new tab, and that there should still
      // exist an Artemis tab. In that case, we close the Demi tab
      // instead of redirecting back to Artemis within this tab.
      if (artemisRedirectUrl === '_blank') {
        window.close();

        return;
      }

      // Redirecting back to artemis if it's specified inside the local state.
      window.location.replace(
        pathname && !artemisRedirectUrl.endsWith(pathname) ? `${artemisRedirectUrl}${pathname}` : artemisRedirectUrl
      );
    },
    [artemisRedirectUrl]
  );
};
