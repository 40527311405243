import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput, Text, useTheme } from '@almond/ui';
import { useRecoilState } from 'recoil';

import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import themedStyles from './styles';

import type { FormValues } from '@almond/ui';
import type { DOCTOR_INFO_PAGE_NAME, HistoryState } from '~types';

export const DoctorInfoPage: React.FC = () => {
  const { t } = useTranslation();
  const [historyState, setHistoryState] = useRecoilState(historyAtom);
  const saveHistory = useSaveHistory();
  const { doAsync, isLoading } = useAsync();
  const [styles] = useTheme(themedStyles);
  const { dispatch } = useRouteNavigation<typeof DOCTOR_INFO_PAGE_NAME>();

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState>) => {
      const toCall = async (): Promise<void> => {
        setHistoryState(prevState => ({ ...prevState, needHealthRecords: true }));
        // save all keys so doctorInfo and needHealthRecords both save
        await saveHistory({ ...formValues, needHealthRecords: true }, 'health_records');
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [dispatch, doAsync, saveHistory, setHistoryState]
  );

  const handleSecondaryPress = useCallback(() => {
    const toCall = async (): Promise<void> => {
      setHistoryState(prevState => ({ ...prevState, doctorInfo: '', needHealthRecords: false }));
      // save all keys so doctorInfo and needHealthRecords both save
      await saveHistory({ ...historyState, doctorInfo: '', needHealthRecords: false }, 'health_records');
      await dispatch('submit');
    };

    doAsync(toCall);
  }, [dispatch, doAsync, historyState, saveHistory, setHistoryState]);

  return (
    <MainForm
      id="doctorInfo"
      title={t('doctorInfo.title')}
      submitButtonTitle={t('continue')}
      onSubmit={handleSubmit}
      atom={historyAtom}
      requiredFields={['doctorInfo']}
      secondaryButtonTitle={t('doctorInfo.secondaryTitle')}
      onSecondaryPress={handleSecondaryPress}
      isLoading={isLoading}
    >
      <ConnectedTextInput name="doctorInfo" placeholder={t('doctorInfo.placeholder')} testID="DoctorInfo" />
      <Text size="s" style={styles.subtitleContainer}>
        <Text style={styles.subtitleLabel}>{t('doctorInfo.subtitle')}</Text>
      </Text>
    </MainForm>
  );
};
