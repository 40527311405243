import { useIsFocused } from '@react-navigation/native';
import useSWR from 'swr';

import type { SWRHook } from 'swr';

export const useFocusedSWR: SWRHook = ((key: any, ...args: [any, any]) => {
  const isFocused = useIsFocused();

  return useSWR(isFocused ? key : null, ...args);
}) as SWRHook;
