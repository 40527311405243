import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';

import { Divider, Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { SelectOption } from '@almond/ui';

export type ResultItemProps<T extends string> = {
  item: SelectOption<T>;
  onPress: (item: SelectOption<T>) => void;
};

export const ResultItem = <T extends string>(props: ResultItemProps<T>) => {
  const { onPress } = props;
  const [styles] = useTheme(themedStyles);

  const handlePress = useCallback(() => {
    onPress(props.item);
  }, [onPress, props.item]);

  return (
    <TouchableOpacity onPress={handlePress}>
      <Text size="m" fontStyle="medium" style={styles.itemText}>
        {props.item.label}
      </Text>
      <Divider />
    </TouchableOpacity>
  );
};
