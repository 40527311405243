import React, { useCallback } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Accordion, ActivityIndicator, MaterialIcon, sizes, Text, useBrowserType, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';
import { useFocusEffect, useRouter } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { ErrorMessage } from '~modules/errors';
import { ContainerWithSidebar } from '~modules/layout';
import { useStripeProduct } from '~modules/product';
import { patientAtom } from '~modules/state';

import { MainPanel } from './MainPanel';
import { SidePanel } from './SidePanel';

import themedStyles from './styles';

export const CreditCardPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const isMobile = useBrowserType() === 'mobile';
  const { data, isLoading, error, mutate } = useStripeProduct();
  const patientState = useRecoilValue(patientAtom);
  const { setParams } = useRouter();

  useFocusEffect(
    useCallback(() => {
      if (!patientState.createdPatientUuid) return;

      // As soon as the patient is created, then its UUID is added to the URL params. However, if the user goes back,
      // then this param will be removed. And if the current patient tries being created one more time, then
      // there will be an error. We're caching the `createdPatientUuid` in the recoil state, and then restore it
      // inside the URL params here. This is the only place where we need to do it.
      setParams({ patient_uuid: patientState.createdPatientUuid });
    }, [patientState.createdPatientUuid, setParams])
  );

  // Gets cached data from request on PricingExplanation page, so shouldn't
  // ever reach loading/error states
  if (isLoading) {
    return <ActivityIndicator />;
  }

  if (error || !data) {
    return <ErrorMessage error={error} onTryAgain={() => mutate()} />;
  }

  return (
    <ContainerWithSidebar
      id="creditCard"
      title={
        isMobile ? (
          <Text size="xl" style={styles.mobileTitle}>
            {t('creditCard.title')}
          </Text>
        ) : (
          t('creditCard.title')
        )
      }
      mobileOrderReversed
      content={<MainPanel product={data} />}
      sidebarContent={
        <SidePanel
          product={data}
          mobileRender={(content, sidebarIsLoading, isError, totalPrice) => (
            <Accordion
              style={styles.accordion}
              title={(icon, isOpen) => (
                <View style={styles.accordionToggle}>
                  <Text size="xl">
                    {t(isOpen ? `creditCard.promotion.hideSummary` : `creditCard.promotion.showSummary`)}
                  </Text>
                  {icon}
                  <View style={styles.accordionPrice}>
                    {sidebarIsLoading && <ActivityIndicator size={sizes.SPACING_M} lineWidth={sizes.SPACING_XXS} />}
                    {isError && <MaterialIcon source="error" color="error" size={sizes.SPACING_L} />}
                    <Text size="xl" fontStyle="bold">
                      {formatPriceInCents(totalPrice)}
                    </Text>
                  </View>
                </View>
              )}
            >
              {content}
            </Accordion>
          )}
        />
      }
    />
  );
};
