import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.lightSecondaryBackground,
  },
  mobileContainer: {
    backgroundColor: theme.lightAccent,
  },

  innerContainer: {
    flex: 1,
  },
  horizontal: {
    flexDirection: 'row',
  },
  horizontalReverse: {
    flexDirection: 'row-reverse',
  },
  vertical: {
    flexDirection: 'column',
  },

  contentContainer: {
    backgroundColor: theme.lightAccent,
  },

  sidebarContentContainer: {
    minWidth: 360,
    backgroundColor: theme.lightAccent,
  },
  sidebarContentContainerNarrow: {
    minWidth: 280,
  },

  contentContainerDesktop: {
    flex: 3,
    alignItems: 'center',
    backgroundColor: theme.lightSecondaryBackground,
  },

  contentContainerExtend: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: theme.lightSecondaryBackground,
  },
  sidebarContentContainerDesktop: {
    flex: 1,
  },
  desktopBack: {
    width: '100%',
    margin: 0,
    padding: sizes.SPACING_S,
  },
  titleContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: sizes.SPACING_XXL,
  },
  titleContainerMobile: {
    marginTop: sizes.SPACING_XL,
    paddingHorizontal: sizes.SPACING_S,
  },

  contentInnerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    // Don't let content overlap page title
    minHeight: 'fit-content' as any,
  },

  subtitle: {
    textAlign: 'center',
    marginTop: sizes.SPACING_S,
    width: '35%',
  },
  subtitleMobile: {
    width: '100%',
  },
}));
