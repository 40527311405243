import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  requiredNotice: {
    textAlign: 'center',
    marginTop: sizes.SPACING_S,
  },
  formError: {
    color: theme.error,
    width: 326,
    textAlign: 'center',
    marginTop: sizes.SPACING_S,
  },
  signIn: {
    color: theme.error,
    textDecorationLine: 'underline',
  },
}));
