export const ALLERGIES_PAGE_NAME = '/allergies' as const;
export const DOCTOR_INFO_PAGE_NAME = '/doctor-info' as const;
export const DRUG_ALLERGIES_PAGE_NAME = '/drug-allergies' as const;
export const FAMILY_HISTORY_PAGE_NAME = '/family-history' as const;
export const HEALTH_CONDITIONS_PAGE_NAME = '/health-conditions' as const;
export const HOSPITALIZATION_PAGE_NAME = '/hospitalization' as const;
export const MEDICATIONS_PAGE_NAME = '/medications' as const;
export const OCCUPATION_PAGE_NAME = '/occupation' as const;
export const PERNICIOUS_HABITS_PAGE_NAME = '/pernicious-habits' as const;
export const PREGNANCIES_PAGE_NAME = '/pregnancies' as const;
export const SURGERIES_PAGE_NAME = '/surgeries' as const;
export const PRIMARY_CARE_PROVIDER_PAGE_NAME = '/primary-care-provider' as const;
export const BASELINE_INTAKE_PAGE_NAME = '/baseline-intake' as const;

export type AllergiesParams = undefined;
export type DoctorInfoParams = undefined;
export type DrugAllergiesParams = undefined;
export type FamilyHistoryParams = undefined;
export type HealthConditionsParams = undefined;
export type HospitalizationParams = undefined;
export type MedicationsParams = undefined;
export type OccupationParams = undefined;
export type PerniciousHabitsParams = undefined;
export type PregnanciesParams = undefined;
export type SurgeriesParams = undefined;
export type PrimaryCareProviderParams = undefined;
export type BaselineIntakeParams = undefined;
