import { I18n } from '@almond/localization';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export const validationSchema: Yup.ObjectSchema<Record<string, any>> = Yup.object().shape({
  email: Yup.string().email(I18n.t('validations.emailFormatError')).nullable(),
  phone: Yup.string()
    .test('phone', I18n.t('feedbackModal.phoneInvalid'), function test(v) {
      if (!v) {
        return true;
      }

      return isValidPhoneNumber(v, 'US');
    })
    .nullable(),
  feedback: Yup.string().required(I18n.t('feedbackModal.feedbackInvalid')),
});
