import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { AuthButton } from '~modules/auth';
import { Container } from '~modules/layout';
import { isBaselineProduct } from '~modules/product';
import { useDetermineFirstMonthFree } from '~modules/profile';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { MessageWithImageV2 } from '~modules/ui';

import { useLoadPatient } from '../../hooks';

import themedStyles from './styles';

import type { GET_STARTED_PAGE_NAME } from '~types';

export const GetStartedPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const loadPatient = useLoadPatient();
  const { dispatch } = useRouteNavigation<typeof GET_STARTED_PAGE_NAME>();
  const [styles] = useTheme(themedStyles);
  const { product } = useLocalSearchParams();
  const isBaseline = isBaselineProduct(product);

  useDetermineFirstMonthFree(false);

  const onIsAuthenticated = useCallback(() => {
    const toCall = async (): Promise<void> => {
      await loadPatient();
      await dispatch('signin');
    };

    doAsync(toCall);
  }, [doAsync, loadPatient, dispatch]);

  return (
    <Container id="confirmed-getStarted" contentContainerStyle={styles.contentContainer}>
      <MessageWithImageV2
        image="relaxingWoman"
        imageDimensions={{ width: 634, height: 381 }}
        mobileImageDimensions={{ width: 253, height: 152 }}
        title={t(`confirmed.message.${isBaseline ? 'baselineTitle' : 'title'}`)}
        subtitle={t('confirmed.message.subtitle')}
        footer={
          <AuthButton onIsAuthenticated={onIsAuthenticated} isLoading={isLoading} testID="GetStarted">
            {t('confirmed.established.submitTitle')}
          </AuthButton>
        }
        isMessageOverImage
      />
    </Container>
  );
};
