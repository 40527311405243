import React, { useCallback, useEffect } from 'react';

import { useLocalSearchParams, useRouter } from 'expo-router';

import { usersApi } from '~modules/api';
import { Login } from '~modules/auth';
import { getArtemisRedirectUrl } from '~modules/integration';
import { useAsync } from '~modules/promises';
import { getAllowedInitialSearchParams, useRouteNavigation } from '~modules/routing';

import { useLoadPatient } from '../../hooks';
import { log } from '../../logger';

import type { UserDetail } from '@almond/api-types';
import type { PATIENT_LOGIN_PAGE_NAME } from '~types';

export const PatientLoginPage: React.FC = () => {
  const loadPatient = useLoadPatient();
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof PATIENT_LOGIN_PAGE_NAME>();
  const searchParams = useLocalSearchParams();
  const { setParams } = useRouter();

  const onIsAuthenticated = useCallback(
    (user?: UserDetail) => {
      const toCall = async (): Promise<void> => {
        // If `hasRedirect` is specified then it means that the user has just set a new password,
        // so we need to consume the redirect and process the url params.
        if (!user?.hasRedirect) {
          log("The current user doesn't have any redirects, so can't consume it.");

          const artemisRedirectUrl = getArtemisRedirectUrl(searchParams.artemis_redirect_url);

          // If user resets their password, but not as part of the Careguide-booked post-booking flow
          // (i.e. trying to log into Artemis), there won't be a redirect to consume. In that case,
          // bring the user to Artemis (because that's where they were trying to get)
          window.location.replace(artemisRedirectUrl);

          return;
        }

        const response = await usersApi.consumeRedirect();
        const { searchParams: urlSearchParams } = new URL(response.url);
        const initialSearchParams = getAllowedInitialSearchParams(urlSearchParams);

        setParams(initialSearchParams);
      };

      doAsync(toCall);
    },
    // Ignoring `searchParams.artemis_redirect_url`, otherwise the redirect will be consumed twice.
    // `searchParams.artemis_redirect_url` shouldn't be changed, because it comes from the outside.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doAsync, setParams]
  );

  useEffect(() => {
    const toCall = async (): Promise<void> => {
      if (!searchParams.patient_uuid) return;

      await loadPatient();
      await dispatch('signin');
    };

    doAsync(toCall);
  }, [dispatch, doAsync, loadPatient, searchParams.patient_uuid]);

  return <Login onIsAuthenticated={onIsAuthenticated} isLoading={isLoading} options={{ restoreRecoilState: true }} />;
};
