import React from 'react';

import { LocalImage, Text, useAdaptive, useBrowserType, useTheme } from '@almond/ui';

import { useImages } from '~assets';

import { MessageContainer } from '../MessageContainer';

import themedStyles from './styles';

import type { Images } from '~assets';
import type { StyleProp, ViewStyle } from 'react-native';

export type MessageWithImageProps = {
  mobileImage?: keyof Images;
  title: string;
  subtitle?: string;
  style?: StyleProp<ViewStyle>;
};

const MessageWithImage: React.FC<MessageWithImageProps> = props => {
  const images = useImages();

  const [styles] = useTheme(themedStyles);
  const containerStyle = useAdaptive(styles.containerDesktop, {});
  const browserType = useBrowserType();
  const image = browserType === 'mobile' ? props.mobileImage : undefined;

  return (
    <MessageContainer style={[props.style, styles.container, containerStyle]}>
      {image && <LocalImage source={image} style={styles.image} resizeMode="contain" images={images} />}
      <Text size="xxl" fontStyle="medium" style={styles.center}>
        {props.title}
      </Text>
      {props.subtitle && (
        <Text size="m" style={styles.center}>
          {props.subtitle}
        </Text>
      )}
    </MessageContainer>
  );
};

export default MessageWithImage;
