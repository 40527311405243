import * as axiosBase from '../axiosBase';
import { CALCULATE_PRICE_PATH, PRODUCTS_PATH, PROMOTION_CODES } from './config';

import type { PromotionCodeOut } from '@almond/api-types';

const lookupKeys = ['basic_annual_membership', 'baseline'];

export const getProducts = async () => {
  const result = await axiosBase.get(PRODUCTS_PATH, {
    params: { lookup_keys: lookupKeys },
  });

  return Object.fromEntries(result.products.map(product => [product.almondProductKey, product]));
};

export const calculatePrice = (priceId: string) => axiosBase.post(CALCULATE_PRICE_PATH(priceId));

export const checkPromotionCode = async (code: string) => {
  if (code.toLowerCase() === 'firstmonthfree') {
    // Hardcode "fake" promotion code
    return {
      amountOff: 0,
      id: '',
      promotionCodeType: 'stripe_discount',
      code,
    } satisfies PromotionCodeOut & { code: string };
  }

  const result = await axiosBase.get(PROMOTION_CODES, { params: { code: code.toLowerCase() } });

  return {
    ...result,
    code,
  };
};
