import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  bodyWrapper: {
    gap: 14,
  },
  ctaWrapper: {
    alignItems: 'center',
    gap: 10,
    marginTop: 30,
  },
}));
