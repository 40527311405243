/* eslint-disable max-lines */

import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import {
  Button,
  ColumnContainer,
  ScrollView,
  sizes,
  SkeletonLoader,
  Text,
  useBrowserTypeMap,
  useTheme,
} from '@almond/ui';
import dayjs from 'dayjs';

import { Tags } from './Tags';
import { Timeslot } from './Timeslot';

import { themedContainerStyles, themedTagsStyles, themedTimeSlotsStyles } from './styles';

import type { AvailableSlot, VisitLocationEnum } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

export type TimeSlotsProps = {
  selectedDate: Dayjs;
  timeSlots: readonly AvailableSlot[];
  isExpanded: boolean;
  onToggleExpand: () => void;
  onSelect: (timeSlot: AvailableSlot) => void;
  onGoToFirstVisitPress: (firstVisitDate: Dayjs) => void;
  firstVisitOn?: Dayjs;
  location: VisitLocationEnum;
  isLoading: false;
  duration: number;
  isPrimary: boolean;
};

export type TimesSlotsLoadingProps = Omit<Partial<TimeSlotsProps>, 'isLoading'> & {
  isLoading: true;
};

const DEFAULT_LENGTH = 6;
const TODAY = dayjs();

const LoadingTimeslots: React.FC = () => {
  const [containerStyles] = useTheme(themedContainerStyles);
  const [timeSlotsStyles] = useTheme(themedTimeSlotsStyles);
  const [tagsStyles] = useTheme(themedTagsStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const timeSlots = Array.from({ length: DEFAULT_LENGTH }, (_, index) => ({ id: index }));
  const tags = Array.from({ length: 2 }, (_, index) => ({ id: index }));

  return (
    <View style={[containerStyles.rightContainer, isMobile && containerStyles.rightContainerMobile]}>
      {isDesktop && (
        <View style={tagsStyles.tags}>
          {tags.map((_, index) => (
            <SkeletonLoader.RoundedRectangle key={index} width={60} height={20} roundness={100} />
          ))}
        </View>
      )}

      <View>
        {isDesktop && (
          <ColumnContainer
            renderItem={() => (
              <SkeletonLoader.RoundedRectangle width={80} height={sizes.SPACING_XL} roundness={sizes.SPACING_XL} />
            )}
            keyExtractor={item => item.id.toString()}
            numColumns={3}
            style={timeSlotsStyles.timeSlots}
            horizontalPaddingPercentage={4}
          >
            {timeSlots}
          </ColumnContainer>
        )}
        {isMobile && (
          <View>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal
              contentContainerStyle={timeSlotsStyles.timeSlotsMobileContent}
              scrollEnabled={false}
            >
              {timeSlots.map((_, index) => (
                <SkeletonLoader.RoundedRectangle
                  key={index}
                  width={80}
                  height={sizes.SPACING_XL}
                  roundness={sizes.SPACING_XL}
                />
              ))}
            </ScrollView>
          </View>
        )}
        {isDesktop && (
          <SkeletonLoader.RoundedRectangle width="100%" height={sizes.SPACING_XXL} roundness={sizes.SPACING_XXL} />
        )}
      </View>
    </View>
  );
};

export const Timeslots: React.FC<TimeSlotsProps | TimesSlotsLoadingProps> = props => {
  const [containerStyles] = useTheme(themedContainerStyles);
  const [timeSlotsStyles] = useTheme(themedTimeSlotsStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const { t } = useTranslation();

  if (props.isLoading) {
    return <LoadingTimeslots />;
  }

  const helpscoutAvailable = typeof window !== 'undefined' && !!(window as any).Beacon?.('info');
  const talkToCareTeam = helpscoutAvailable ? () => (window as any).Beacon?.('open') : undefined;

  const {
    selectedDate,
    timeSlots,
    isExpanded,
    onToggleExpand,
    onSelect,
    firstVisitOn,
    onGoToFirstVisitPress,
    location,
    duration,
    isPrimary,
  } = props;
  const slicedTimeSlots =
    !isExpanded && timeSlots.length > DEFAULT_LENGTH ? timeSlots.slice(0, DEFAULT_LENGTH) : timeSlots;
  const locationString = location === 'Office' ? 'office' : 'video';

  return (
    <View
      style={[
        containerStyles.rightContainer,
        !timeSlots.length && containerStyles.withoutTimeslotsGap,
        isMobile && containerStyles.rightContainerMobile,
        isMobile && !timeSlots.length && containerStyles.withoutTimeslotsGapMobile,
      ]}
    >
      {isDesktop && <Tags isPrimary={isPrimary} location={location} duration={duration} />}
      <View>
        {isDesktop && !!timeSlots.length && (
          <ColumnContainer
            renderItem={item => <Timeslot timeSlot={item} onPress={onSelect} location={location} />}
            keyExtractor={item => item.key}
            numColumns={3}
            style={timeSlotsStyles.timeSlots}
            horizontalPaddingPercentage={4}
          >
            {slicedTimeSlots}
          </ColumnContainer>
        )}
        {isMobile && !!timeSlots.length && (
          <View>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal
              contentContainerStyle={timeSlotsStyles.timeSlotsMobileContent}
            >
              {timeSlots.map(timeSlot => (
                <Timeslot key={timeSlot.key} timeSlot={timeSlot} onPress={onSelect} location={location} />
              ))}
            </ScrollView>
          </View>
        )}
        {!timeSlots.length && (
          <View style={isDesktop && timeSlotsStyles.noVisitsContainerDesktop}>
            <Text style={[timeSlotsStyles.noVisitsText, isMobile && timeSlotsStyles.noVisitsTextMobile]} size="s">
              {!firstVisitOn && t('scheduling.availabilitySection.noAvailableContact', { location: locationString })}
              {firstVisitOn &&
                `${t('scheduling.availabilitySection.noAvailability', {
                  selectedDate: selectedDate.format('MMM D'),
                })}.\n${t(
                  `scheduling.availabilitySection.${
                    selectedDate.isSame(TODAY, 'day') ? 'nextAvailableVisit' : 'nextAvailableVisitDate'
                  }`,
                  {
                    count: firstVisitOn.diff(TODAY, 'days'),
                    date: firstVisitOn.format('MMM D'),
                    location: locationString,
                  }
                )}`}
            </Text>
            {(firstVisitOn || talkToCareTeam) && (
              <Button
                size="m"
                style={timeSlotsStyles.goToNextDate}
                onPress={firstVisitOn ? () => onGoToFirstVisitPress(firstVisitOn) : talkToCareTeam}
              >
                {t(`scheduling.availabilitySection.${firstVisitOn ? 'goToNextDate' : 'talkToCareTeam'}`, {
                  location: locationString,
                })}
              </Button>
            )}
          </View>
        )}
        {isDesktop && timeSlots.length > DEFAULT_LENGTH && (
          <Button type="secondary" onPress={onToggleExpand} size="m" fixedWidth={false}>
            {t(`scheduling.availabilitySection.${isExpanded ? 'hideMore' : 'showMore'}`)}
          </Button>
        )}
      </View>
    </View>
  );
};
