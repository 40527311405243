import React from 'react';

import { useController, useFormContext } from '@almond/ui';

import { PharmacyInput } from '~modules/location';

import type { PharmacyInputProps } from '~modules/location';

export type ConnectedPharmacyInputProps = Omit<PharmacyInputProps, 'onSelect'> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedPharmacyInput: React.FC<ConnectedPharmacyInputProps> = props => {
  const { name, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, value, ...restField } = field;

  return <PharmacyInput {...restField} {...restProps} onSelect={selectedValue => onChange(selectedValue)} />;
};

export default ConnectedPharmacyInput;
