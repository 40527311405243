import React from 'react';
import { TouchableOpacity } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { GestureResponderEvent, TouchableOpacityProps } from 'react-native';

export type ClearButtonProps = TouchableOpacityProps;

export const ClearButton: React.FC<ClearButtonProps> = props => {
  const { onPress, ...restProps } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  const handlePress = (event: GestureResponderEvent): void => {
    onPress?.(event);

    const iframe = document.getElementById('iframe') as HTMLIFrameElement | null;

    iframe?.contentWindow?.postMessage({ type: 'clear' }, '*');
  };

  return (
    <TouchableOpacity {...restProps} onPress={handlePress} style={styles.clearButton} testID="ClearSignature">
      <Text fontStyle="bold" style={styles.clearButtonText}>
        {t('consentSignature.clear')}
      </Text>
    </TouchableOpacity>
  );
};
