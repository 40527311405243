import * as axiosBase from './axiosBase';

export * from './admin';
export * from './appointments';
export * from './components';
export * from './config';
export * from './dailyAvailability';
export * from './emails';
export * from './feedback';
export * from './hooks';
export * from './patients';
export * from './physicians';
export * from './products';
export * from './profiles';
export * from './users';
export * from './visitReasons';
export { axiosBase };
