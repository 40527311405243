import { isBaselineProduct } from '~modules/product';
import { demographicAtom } from '~modules/state';
import {
  CASH_RATES_PAGE_NAME,
  CREATE_PATIENT_PAGE_NAME,
  CREDIT_CARD_PAGE_NAME,
  DEMOGRAPHIC_PAGE_NAME,
  INSURANCE_NOT_ACCEPTED_PAGE_NAME,
  INSURANCE_PAGE_NAME,
  LEARN_MORE_PAGE_NAME,
  NEW_MEMBER_SCHEDULING_PAGE_NAME,
  PHONE_VERIFICATION_PAGE_NAME,
  PRICING_EXPLANATION_PAGE_NAME,
} from '~types';

import { checkoutRoutes } from './checkout';

import type { RoutingConfig, StackParamList } from '~types';

export const signupRoutes = {
  id: 'signup' as const,
  initial: () => ({ name: DEMOGRAPHIC_PAGE_NAME }),
  routes: {
    [DEMOGRAPHIC_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const demographicState = await getState(demographicAtom);

          if (demographicState.isOptedIntoSms) {
            return { name: PHONE_VERIFICATION_PAGE_NAME };
          }

          return { name: INSURANCE_PAGE_NAME };
        },
      },
    },
    [PHONE_VERIFICATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: INSURANCE_PAGE_NAME }),
      },
    },
    [INSURANCE_PAGE_NAME]: {
      on: {
        submit: (_1, searchParams, params?: { isAccepted: boolean; hasInsurance: boolean }) => {
          const isBaseline = isBaselineProduct(searchParams.product);

          if (isBaseline) {
            return { name: PRICING_EXPLANATION_PAGE_NAME };
          }

          if (!params?.hasInsurance) return { name: CASH_RATES_PAGE_NAME };
          if (params?.isAccepted) return { name: PRICING_EXPLANATION_PAGE_NAME };

          return { name: INSURANCE_NOT_ACCEPTED_PAGE_NAME };
        },
      },
    },
    [INSURANCE_NOT_ACCEPTED_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PRICING_EXPLANATION_PAGE_NAME }),
      },
    },
    [CASH_RATES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PRICING_EXPLANATION_PAGE_NAME }),
      },
    },
    [PRICING_EXPLANATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CREDIT_CARD_PAGE_NAME }),
        learnMore: () => ({ name: LEARN_MORE_PAGE_NAME }),
      },
    },
    [CREDIT_CARD_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CREATE_PATIENT_PAGE_NAME }),
      },
    },
    [CREATE_PATIENT_PAGE_NAME]: {
      on: {
        complete: () => checkoutRoutes,
      },
    },
    [LEARN_MORE_PAGE_NAME]: {
      on: {
        submit: () => ({ name: NEW_MEMBER_SCHEDULING_PAGE_NAME }),
      },
    },
    [NEW_MEMBER_SCHEDULING_PAGE_NAME]: {
      on: {},
    },
  },
} satisfies RoutingConfig<StackParamList>;
