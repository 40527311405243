import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 196,
    height: 212,
    position: 'absolute',
  },
  bottom: {
    color: theme.secondaryTextDark,
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
  },
}));
