/* src/ui/components/Accordion/Accordion.module.css */
.Accordion_expandClickableArea {
  position: initial;
  z-index: 1;
}
.Accordion_expandClickableArea:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}
.Accordion_caret {
  z-index: 0;
}
.Accordion_rightAction {
  z-index: 2;
}
.Accordion_collapsable {
  interpolate-size: allow-keywords;
  transition: all 0.3s ease allow-discrete;
  height: auto;
  overflow: hidden;
  display: block;
}
.Accordion_hidden {
  height: 0;
  display: none;
}

/* src/ui/components/ToastNotification/ToastNotificationList.module.css */
.ToastNotificationList_container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 24px;
  gap: 8px;
}
.ToastNotificationList_item {
  padding: 8px;
  border-radius: 8px;
  transition: transform 0.3s ease-out;
  transform: none;
}
@starting-style {
  .ToastNotificationList_item {
    transform: translateX(calc(100% + 24px));
  }
}
.ToastNotificationList_infoTheme {
  background-color: var(--color-info);
}
.ToastNotificationList_errorTheme {
  color: var(--color-background);
  background-color: var(--color-error);
}
.ToastNotificationList_text {
  color: inherit;
}
.ToastNotificationList_srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
/*# sourceMappingURL=index.css.map */