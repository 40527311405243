import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  modalContainer: {
    width: 640,
    height: 440,
    backgroundColor: theme.lightSecondaryBackground,
  },

  modalContainerMobile: {
    width: '100%',
    height: '100%',
  },

  title: {
    alignSelf: 'center',
    textAlign: 'center',
    color: theme.darkText,
    marginHorizontal: 72,
  },

  titleMobile: {
    marginHorizontal: 0,
  },

  subtitle: {
    textAlign: 'center',
  },

  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 88,
    rowGap: sizes.SPACING_S,
  },

  contentMobile: {
    paddingHorizontal: 0,
  },

  footer: {
    alignItems: 'center',
    rowGap: sizes.SPACING_S,
  },
}));
