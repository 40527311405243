import React, { useRef } from 'react';

import { useTranslation } from '@almond/localization';
import { IconButton } from '@almond/ui';

import PlacesInput from '../PlacesInput';
import Footer from './Footer';

import type { PlacesInputProps } from '../PlacesInput';
import type { TextInput as NativeTextInput } from 'react-native';
import type { GooglePlacesAutocompleteRef } from 'react-native-google-places-autocomplete';

export type PharmacyInputProps = Omit<
  PlacesInputProps,
  'types' | 'autocompleteRef' | 'textInputRef' | 'placeholder' | 'accessibilityLabel'
>;

export const PharmacyInput = (props: PharmacyInputProps) => {
  const autocompleteRef = useRef<GooglePlacesAutocompleteRef>(null);
  const textInputRef = useRef<NativeTextInput>(null);
  const { t } = useTranslation();

  const selectAlto = () => {
    autocompleteRef.current?.setAddressText('Alto Pharmacy');
    autocompleteRef.current?.triggerSearch('Alto Pharmacy');
    textInputRef.current?.focus();
  };

  return (
    <>
      <PlacesInput
        {...props}
        types="pharmacy|drugstore"
        autocompleteRef={autocompleteRef}
        textInputRef={textInputRef}
        label={t('pharmacy.placeholder')}
        accessibilityLabel={t('pharmacy.alt')}
        left={<IconButton source="search" color="primary" size={21} />}
      />
      <Footer onPress={selectAlto} />
    </>
  );
};
