import { useCallback } from 'react';

import { useLocalSearchParams } from 'expo-router';
import { useRecoilState } from 'recoil';

import { patientsApi } from '~modules/api';
import { useAuth0 } from '~modules/auth/hooks';
import { useGetSubscription } from '~modules/payment';
import { useProcessProfileResponse } from '~modules/profile/hooks';
import { loadedPatientUuidAtom } from '~modules/state';

import { log } from '../logger';
import useProcessPatientResponse from './useProcessPatientResponse';

const useLoadPatient = () => {
  const { isAuthenticated } = useAuth0();
  const [loadedPatientUuid, setLoadedPatientUuid] = useRecoilState(loadedPatientUuidAtom);
  const getSubscription = useGetSubscription();
  const processProfileResponse = useProcessProfileResponse();
  const processPatientResponse = useProcessPatientResponse();
  const searchParams = useLocalSearchParams();

  return useCallback(async () => {
    if (!isAuthenticated) return;
    if (!searchParams.patient_uuid || loadedPatientUuid === searchParams.patient_uuid) return;

    const patientResponse = await patientsApi.retrieve();
    const subscription = await getSubscription();

    log(`Patient ${patientResponse.uuid} has been loaded.`);

    setLoadedPatientUuid(searchParams.patient_uuid);
    processProfileResponse(patientResponse.profile);
    processPatientResponse(patientResponse, subscription);
  }, [
    isAuthenticated,
    searchParams.patient_uuid,
    loadedPatientUuid,
    getSubscription,
    setLoadedPatientUuid,
    processProfileResponse,
    processPatientResponse,
  ]);
};

export default useLoadPatient;
