import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { Dayjs } from 'dayjs';

export type HeaderProps = {
  month: Dayjs;
};

export const Header: React.FC<HeaderProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.header}>
      <Text style={styles.headerTitle} size="l" fontStyle="bold">
        {props.month.format('MMMM YYYY')}
      </Text>
    </View>
  );
};
