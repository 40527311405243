import { useEvent } from '@almond/utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { productsApi } from '~modules/api';
import { HttpRequestError } from '~modules/errors';
import { useAsync } from '~modules/promises';
import { appointmentParamsAtom, creditCardAtom, patientProgressAtom } from '~modules/state';

import type { PromotionCode } from '~types';

export const useCheckCode = () => {
  const { doAsync, isLoading } = useAsync();
  const setCreditCardState = useSetRecoilState(creditCardAtom);
  const { subscription } = useRecoilValue(patientProgressAtom);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const isResubscribe = !subscription && !isNewMemberRemote;

  const checkCode = useEvent((code: string, discountCode?: [string, PromotionCode | undefined]) => {
    return doAsync(async (): Promise<PromotionCode> => {
      if (isResubscribe && code === 'firstmonthfree') {
        return { code: 'firstmonthfree', state: 'not-found' };
      }

      try {
        const result = await productsApi.checkPromotionCode(code);
        const resultWithTag: PromotionCode = { ...result, state: 'success' };

        if (resultWithTag.promotionCodeType === 'stripe_discount') {
          setCreditCardState(prevState => ({ ...prevState, promotionCode: resultWithTag }));
        } else if (resultWithTag) {
          setCreditCardState(prevState => ({ ...prevState, friendReferralCode: resultWithTag }));
        }

        return resultWithTag;
      } catch (e) {
        if (e instanceof HttpRequestError && e.status === 404) {
          const resultWithTag: PromotionCode = { code, state: 'not-found' };

          if (discountCode) {
            setCreditCardState(prevState => ({ ...prevState, [discountCode[0]]: discountCode[1] }));
          }

          return resultWithTag;
        }

        throw e;
      }
    });
  });

  return { checkCode, isLoading };
};
