import { HttpStatusCode } from '~types';

import type { ErrorCode } from '~types';

export const translateHttpStatusCode = (httpStatusCode?: number): ErrorCode => {
  if (httpStatusCode === HttpStatusCode.NOT_FOUND) {
    return 'NotFoundException';
  }

  if (
    httpStatusCode &&
    httpStatusCode >= HttpStatusCode.BAD_REQUEST &&
    httpStatusCode < HttpStatusCode.INTERNAL_SERVER_ERROR
  ) {
    return 'InvalidRequestException';
  }

  if (
    httpStatusCode &&
    httpStatusCode >= HttpStatusCode.INTERNAL_SERVER_ERROR &&
    httpStatusCode <= HttpStatusCode.NETWORK_AUTHENTICATION_REQUIRED
  ) {
    return 'ServerErrorException';
  }

  return 'UnknownException';
};
