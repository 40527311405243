import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  row: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: sizes.SPACING_S,
  },

  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  resendCodeButton: {
    paddingHorizontal: sizes.SPACING_XS,
  },

  intervalButtonLabel: {
    fontSize: sizes.FONT_SIZE_L,
    textDecorationLine: 'none',
  },

  imageMobile: {
    alignSelf: 'center',
    marginBottom: sizes.SPACING_M,
  },
}));
