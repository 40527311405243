import React, { useState } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { List, SegmentedButtons, Text, useTheme } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { creditCardAtom } from '~modules/state';
import { LocalImage } from '~modules/ui';

import themedStyles from './styles';

type Insurance = 'withInsurance' | 'withoutInsurance';

export const SidebarContent: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const [insurance, setInsurance] = useState<Insurance>('withInsurance');
  const creditCardState = useRecoilValue(creditCardAtom);

  const handleValueChange = (value: Insurance) => {
    setInsurance(value);
  };

  const descriptionKey =
    creditCardState.promotionCode?.code === 'firstmonthfree' && creditCardState.promotionCode.state === 'success'
      ? 'firstMonthFreeDescription'
      : 'description';

  return (
    <View style={styles.container}>
      <LocalImage source="clouds1" width={267} height={197} style={styles.selfCenter} />
      <Text family="PlayfairDisplay" fontStyle="bold" size="l" role="heading" aria-level={2} style={styles.centerText}>
        {t('pricingExplanation.sidebarContent.title')}
      </Text>
      <View style={styles.description}>
        <Text>{t(`pricingExplanation.sidebarContent.${descriptionKey}`)}</Text>
      </View>
      <SegmentedButtons
        buttons={[
          { value: 'withInsurance', label: t('pricingExplanation.sidebarContent.withInsurance.label') },
          { value: 'withoutInsurance', label: t('pricingExplanation.sidebarContent.withoutInsurance.label') },
        ]}
        initialValue="withInsurance"
        onValueChange={handleValueChange}
        style={styles.buttons}
      />
      {insurance === 'withInsurance' && <Text>{t(`pricingExplanation.sidebarContent.withInsurance.title`)}</Text>}
      {insurance === 'withoutInsurance' && (
        <>
          <Text>{t(`pricingExplanation.sidebarContent.withoutInsurance.title.header`)}</Text>
          <List.UnorderedList>
            {t(`pricingExplanation.sidebarContent.withoutInsurance.title.prices`, { returnObjects: true }).map(
              price => (
                <List.ListItem key={price}>
                  <Text>{price}</Text>
                </List.ListItem>
              )
            )}
          </List.UnorderedList>
          <Text>{t(`pricingExplanation.sidebarContent.withoutInsurance.title.footer`)}</Text>
        </>
      )}
    </View>
  );
};
