import React from 'react';

import { Auth0Provider as NativeAuth0Provider } from '@auth0/auth0-react';

import env from '~env';

import { AUTH0_PERMISSIONS } from '../../config';
import { useRedirectCallback } from '../../hooks';

const SUBDIRECTORY = '/auth-redirect';

const Auth0Provider: React.FC<React.PropsWithChildren> = props => {
  const redirectCallback = useRedirectCallback();

  return (
    <NativeAuth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      authorizationParams={{
        scope: AUTH0_PERMISSIONS,
        redirect_uri: window.location.origin + SUBDIRECTORY,
        audience: env.AUTH0_AUDIENCE,
      }}
      onRedirectCallback={appState => redirectCallback(appState as any, false)}
    >
      {props.children}
    </NativeAuth0Provider>
  );
};

export default Auth0Provider;
