import { I18n } from '@almond/localization';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { Yup } from '~modules/forms';

// Disallow the following space separated characters ( ) [ ] , { } |
const allowedCharacters = /^[^()[\],{}|]*$/;

export const validationSchema: Yup.ObjectSchema<Record<string, any>> = Yup.object().shape({
  firstName: Yup.string().matches(allowedCharacters, I18n.t('validations.nameInvalidCharacter')).nullable(),
  preferredFirstName: Yup.string().matches(allowedCharacters, I18n.t('validations.nameInvalidCharacter')).nullable(),
  lastName: Yup.string().matches(allowedCharacters, I18n.t('validations.nameInvalidCharacter')).nullable(),
  birthday: Yup.string().date(['MM/DD/YY', 'MM/DD/YYYY']).maxDate().nullable(),
  email: Yup.string().email(I18n.t('validations.emailFormatError')).nullable(),
  phone: Yup.string()
    .test('phone', 'Please enter a valid 10 digit phone number', v => {
      if (!v) {
        return false;
      }

      return isValidPhoneNumber(v, 'US');
    })
    .nullable(),
});
