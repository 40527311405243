import { log, logAndCapture, logError } from './logger';

import type AppError from '~modules/errors/AppError';
import type { SentryMessageDetails } from '~types';

export const logger = (category: string) => (message: string, details?: any) => log(category, message, details);

export const errorLogger = (category: string) => (error: AppError | unknown, details?: string) =>
  logError(category, error, details);

export const captureLogger = (category: string) => (message: string, details?: SentryMessageDetails) =>
  logAndCapture(category, message, details);
