import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

const ITEM_SIZE = 50;

export default styleSheetFactory(theme => ({
  container: {
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_S,
    padding: sizes.SPACING_M,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: sizes.SPACING_XS,
    flexDirection: 'row',
    ...getShadow(false),
  },
  center: {
    flex: 1,
    marginHorizontal: sizes.SPACING_M,
  },
  focused: {
    ...getShadow(true),
  },
  iconContainer: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.lightAccent,
    borderRadius: ITEM_SIZE / 2,
  },
  icon: {
    maxWidth: '60%',
  },
  title: {
    color: theme.darkText,
    userSelect: 'none',
  },
  subtitle: {
    color: theme.secondaryText,
    userSelect: 'none',
    marginTop: sizes.SPACING_XS,
  },
}));
