import React, { useMemo, useState } from 'react';
import { FlatList, View } from 'react-native';

import { uiUtilities, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useEvent } from '@almond/utils';
import dayjs from 'dayjs';

import { Calendar, CALENDAR_HEIGHT } from '../Calendar';
import { ArrowButton } from './ArrowButton';

import themedStyles from './styles';

import type { CalendarProps } from '../Calendar';
import type { Dayjs } from 'dayjs';

export type ScrollableCalendarProps = Omit<CalendarProps, 'month' | 'isLoading'> & {
  numVisible: number;
  requestDataAt: (date: Dayjs) => void;
};

// Can be configured through the props in the future.
const MAX_MONTHS = 12;

export const ScrollableCalendar = (props: ScrollableCalendarProps) => {
  const [styles] = useTheme(themedStyles);
  const [page, setPage] = useState(0);
  const { date, numVisible, requestDataAt, ...restProps } = props;
  const months = useMemo(() => Array.from({ length: MAX_MONTHS }, (_, index) => dayjs().add(index, 'months')), []);
  const { isMobile } = useBrowserTypeMap();
  const groupedMonths = useMemo(() => uiUtilities.groupItems(months, numVisible), [months, numVisible]);

  const onViewableItemsChanged = useEvent<Exclude<FlatList['props']['onViewableItemsChanged'], null | undefined>>(
    info => {
      if (!info) return;

      const lastMonthVisible = info.viewableItems?.at(-1)?.item as undefined | (typeof months)[number];

      if (lastMonthVisible) {
        requestDataAt(lastMonthVisible);
      }
    }
  );

  const goForwardPage = () => {
    const newPage = page + 1;

    setPage(newPage);
    const lastVisibleMonth = groupedMonths[newPage]?.at(-1);

    if (lastVisibleMonth) {
      requestDataAt(lastVisibleMonth);
    }
  };

  if (isMobile) {
    return (
      <FlatList
        contentContainerStyle={styles.containerMobile}
        data={months}
        renderItem={info => <Calendar key={info.item.format()} {...restProps} date={date} month={info.item} />}
        onViewableItemsChanged={onViewableItemsChanged}
        getItemLayout={(_, index) => {
          return { length: CALENDAR_HEIGHT, offset: CALENDAR_HEIGHT * index, index };
        }}
        scrollEventThrottle={300}
      />
    );
  }

  if (page < 0) return null;

  return (
    <View style={styles.container}>
      {page > 0 && (
        <ArrowButton direction="left" onPress={() => setPage(prevState => prevState - 1)} testID="Calendar_PrevMonth" />
      )}
      {groupedMonths[page].map(month => (
        <Calendar key={month.format()} {...restProps} date={date} month={month} />
      ))}
      {page < groupedMonths.length - 1 && (
        <ArrowButton direction="right" onPress={goForwardPage} testID="Calendar_NextMonth" />
      )}
    </View>
  );
};
