import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useTheme } from '@almond/ui';

import { useRedirectBackToArtemis } from '~modules/integration';
import { Container } from '~modules/layout';
import { RecommendationMessage } from '~modules/ui';

import themedStyles from './styles';

export const InfectionReviewingPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const redirectBackToArtemis = useRedirectBackToArtemis();

  return (
    <Container title={t('infectionOutcome.title')} id="infectionOutcome" size="XL">
      <RecommendationMessage
        subtitle={t('infectionOutcome.reviewing.subtitle')}
        bodyText={
          <View style={styles.bodyWrapper}>
            {t('infectionOutcome.reviewing.body', { returnObjects: true }).map((text, i) => (
              <Text key={i} size="m">
                {text}
              </Text>
            ))}
          </View>
        }
      />
      <View style={styles.ctaWrapper}>
        <Button onPress={() => redirectBackToArtemis()} type="primary" testID="Submit">
          {t('backToDashboard')}
        </Button>
        <Button onPress={() => redirectBackToArtemis('/messages')} type="secondary" testID="Secondary">
          {t('messageCareTeam')}
        </Button>
      </View>
    </Container>
  );
};
