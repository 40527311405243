import type { BaseParamsWithoutRouting } from './navigation/navigationWithoutRouting';
import type { RecoilState } from 'recoil';

// Gets the keys from object T that match type V
type KeysMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[Extract<keyof T, string>];
// Gets the keys from object T that DON'T match type V
type KeysNotMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? never : K;
}[Extract<keyof T, string>];
// Get the keys from object T that are required
type RequiredKeys<T> = { [K in keyof T]-?: object extends Pick<T, K> ? never : K }[keyof T];

// Create a list of objects with name and param properties
type ObjectWith<ParamMap extends object, T extends keyof ParamMap> = {
  [Key in T]: ParamMap[Key] extends undefined
    ? { name: Key; params?: undefined }
    : RequiredKeys<ParamMap[Key]> extends never
      ? { name: Key; params?: Omit<ParamMap[Key], 'routing_config_id'> }
      : { name: Key; params: Omit<ParamMap[Key], 'routing_config_id'> };
}[T];
// All the routes that have params, and their params
export type ParamStatePairs<ParamMap extends object> = ObjectWith<ParamMap, KeysNotMatching<ParamMap, undefined>>;
// A list of routes that don't have params
export type NoParamRoutes<ParamMap extends object> = ObjectWith<ParamMap, KeysMatching<ParamMap, undefined>>;

// A destination can be a string (a route name) or an object with a name and params. In the future,
// a destination can also be another routing config
export type EventDestination<ParamMap extends object> =
  | ((NoParamRoutes<ParamMap> | ParamStatePairs<ParamMap>) & {
      // Allow replacing the current page in the stack with the
      // next page. This allows the "back" button to skip over the
      // current page
      replace?: boolean;
    })
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  | RoutingConfigId;

export type EventDestinationGetter<ParamMap extends object, Params> = (
  getState: <T>(recoilValue: RecoilState<T>) => Promise<T>,
  searchParams: BaseParamsWithoutRouting,
  params?: Params
) => EventDestination<ParamMap> | Promise<EventDestination<ParamMap>>;

// Routing config object. For each route, a RouteConfig is defined
export type RouteConfig<ParamMap extends object> = {
  // TODO: change params to more specific type
  on: { [k: string]: EventDestinationGetter<ParamMap, any> } | null;
  reset?: boolean;
};

export type RoutingConfigId = { id: string };

type InitialReturnValue<ParamMap extends object> = (NoParamRoutes<ParamMap> | ParamStatePairs<ParamMap>) & {
  // Allow replacing the current page in the stack with the
  // next page. This allows the "back" button to skip over the
  // current page
  replace?: boolean;
};

// The overall route config object type
export type RoutingConfig<ParamMap extends object> = RoutingConfigId & {
  routes: { [Key in keyof ParamMap]?: RouteConfig<ParamMap> };
} & (
    | {
        initial: (searchParams: BaseParamsWithoutRouting) => InitialReturnValue<ParamMap>;
        initialAsync?: never;
      }
    | {
        initial?: never;
        initialAsync: (
          getState: <T>(recoilValue: RecoilState<T>) => Promise<T>,
          searchParams: BaseParamsWithoutRouting
        ) => Promise<InitialReturnValue<ParamMap>>;
      }
  );

// The type of the dispatch function returned by useRouteNavigation
export type Dispatch<Events extends Record<string, any>> = <E extends Extract<keyof Events, string>>(
  event: E,
  params?: Events[E],
  searchParams?: BaseParamsWithoutRouting
) => Promise<void>;

// Helper type to get list of events for a given route to pass to the Dispatch type
export type GetEvents<
  ParamMap extends object,
  InferredConfig extends RoutingConfig<ParamMap>,
  CurrentPage extends keyof ParamMap,
> = Exclude<
  InferredConfig extends { routes: { [Key in CurrentPage]: { on: infer Events } } }
    ? // eslint-disable-next-line @typescript-eslint/ban-types
      { [K in keyof Events]: Events[K] extends (...args: any) => any ? Parameters<Events[K]>[2] : never }
    : never,
  null
>;

// Given a page name, get the config IDs that contain that page
export type ConfigIdFromPageName<
  ParamMap extends object,
  InferredRouteConfig extends RoutingConfig<ParamMap>,
  CurrentPage extends keyof ParamMap,
> = InferredRouteConfig extends {
  id: infer ID;
  routes: { [key in CurrentPage]: any };
}
  ? ID
  : never;

// Get params from the initial() function of a routing config
export type InitialConfigParams<
  ParamMap extends object,
  InferredRoutingConfig extends RoutingConfig<ParamMap>,
  Id extends InferredRoutingConfig['id'],
> = InferredRoutingConfig extends {
  id: Id;
  initial: (params: infer Params) => any;
}
  ? Params
  : never;
