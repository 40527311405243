import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  container: {
    position: 'absolute',
  },

  animation: {
    flex: 1,
  },
}));
