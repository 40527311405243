import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { useTheme } from '@almond/ui';

import { MainForm } from '~modules/forms';

import { useAuth0 } from '../../hooks';
import AuthButton from '../AuthButton';

import themedStyles from './styles';

import type { AuthButtonProps } from '../AuthButton';

export type LoginProps = Pick<AuthButtonProps, 'onIsAuthenticated' | 'isLoading' | 'options'>;

const Login: React.FC<LoginProps> = props => {
  const { onIsAuthenticated, options } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { isLoading, isAuthenticated } = useAuth0();

  return (
    <MainForm id="login" title={t('login.title')} size="M">
      <View style={styles.signInButton}>
        <AuthButton
          isLoading={isLoading || isAuthenticated}
          onIsAuthenticated={onIsAuthenticated}
          testID="SignIn"
          options={options}
        >
          {t('login.submitTitle')}
        </AuthButton>
      </View>
    </MainForm>
  );
};

export default Login;
