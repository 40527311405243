/* eslint-disable func-names */
import { LOCAL_DATE_FORMAT } from '@almond/date-and-time';
import { I18n } from '@almond/localization';
import dayjs from 'dayjs';
import * as yupExtended from 'yup';

import * as yupUtilities from './yupUtilities';

import type { DateInputFormat } from '@almond/date-and-time';
import type { Dayjs } from 'dayjs';

yupExtended.addMethod(
  yupExtended.string,
  'date',
  function (
    formats: DateInputFormat[] = ['MM/DD/YYYY'],
    errorMessage = I18n.t('validations.dateFormatError', { format: formats[0] })
  ) {
    return this.test(`testDate`, errorMessage, function (value) {
      const { path, createError } = this;

      return yupUtilities.validateDate(path, createError, errorMessage, formats, value);
    });
  }
);

yupExtended.addMethod(yupExtended.string, 'minDate', function (errorMessage?: string, minDate?: Dayjs) {
  const formattedErrorMessage =
    errorMessage || I18n.t('validations.dateMinError', { minDate: minDate?.format(LOCAL_DATE_FORMAT) });

  return this.test(`testDateMin`, formattedErrorMessage, function (value) {
    const { path, createError } = this;

    return yupUtilities.validateMinDate(path, createError, formattedErrorMessage, value, minDate);
  });
});

yupExtended.addMethod(yupExtended.string, 'maxDate', function (errorMessage?: string, maxDate = dayjs()) {
  const formattedErrorMessage = errorMessage || I18n.t('validations.dateMaxError');

  return this.test(`testDateMax`, formattedErrorMessage, function (value) {
    const { path, createError } = this;

    return yupUtilities.validateMaxDate(path, createError, formattedErrorMessage, value, maxDate);
  });
});

yupExtended.addMethod(yupExtended.string, 'zip', function (errorMessage = I18n.t('validations.zipFormatError')) {
  return this.test(`testZip`, errorMessage, function (value) {
    const { path, createError } = this;

    return yupUtilities.validateZip(path, createError, errorMessage, value);
  });
});

yupExtended.addMethod(yupExtended.string, 'phone', function (errorMessage = I18n.t('validations.phoneFormatError')) {
  return this.test(`testPhone`, errorMessage, function (value) {
    const { path, createError } = this;

    return yupUtilities.validatePhoneNumber(path, createError, errorMessage, value);
  });
});

yupExtended.addMethod(yupExtended.object, 'selectInputValue', function (errorMessage: string) {
  return this.test(`testSelectInputValue`, errorMessage, function (value) {
    const { path, createError } = this;

    return yupUtilities.validateSelectInputValue(path, createError, errorMessage, value);
  });
});

export default yupExtended;
