import { useEffect } from 'react';

import type dayjs from 'dayjs';
import type { MutableRefObject, RefObject } from 'react';
import type { ScrollView as ScrollViewType } from 'react-native';

type Params = {
  isLoading: boolean;
  singleDayData: any;
  selectedProviderRef: MutableRefObject<null | { physicianUuid: string; appointmentType: string }>;
  selectedDate: dayjs.Dayjs;
  scrollViewRef: RefObject<ScrollViewType>;
};

export const useScrollToSelectedAppointmentType = (params: Params) => {
  const { isLoading, singleDayData, selectedProviderRef, selectedDate, scrollViewRef } = params;
  const hasAnyLoadedSelectedDayAvailability = singleDayData.length > 0;

  useEffect(() => {
    // When the selected date changes, make sure the page is scrolled to the top
    if (selectedProviderRef.current && hasAnyLoadedSelectedDayAvailability && !isLoading) {
      // But if the date changed because a specific provider was clicked on, be sure to scroll to that provider
      const scrollChild = scrollViewRef.current?.getInnerViewNode();
      const element = scrollChild.querySelector(
        [
          `[data-physician="${selectedProviderRef.current.physicianUuid}"]`,
          `[data-appointment-type="${selectedProviderRef.current.appointmentType}"]`,
        ].join('')
      );

      if (element) {
        // If the element exists on the page, scroll to it. If the element does NOT yet exist
        // then it's still loading - don't do any scrolling until this useEffect re-runs
        const startOfSelectedElement = element.getBoundingClientRect().y - scrollChild.getBoundingClientRect().y;
        const toScrollTo = Math.max(0, startOfSelectedElement - element.getBoundingClientRect().height / 2);

        scrollViewRef.current?.scrollTo({ x: 0, y: toScrollTo, animated: true });
        selectedProviderRef.current = null;
      }
    } else {
      scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: false });
    }
  }, [selectedDate, scrollViewRef, hasAnyLoadedSelectedDayAvailability, isLoading, selectedProviderRef]);
};
