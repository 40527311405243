import HttpRequestError from './HttpRequestError';

import type { ErrorDetails } from '~types';

export const PAYMENT_FAILED_ERROR_STATUS_CODE = 402;

export default class PaymentRequiredError extends HttpRequestError {
  constructor(errorDetails: ErrorDetails = {}, message?: string) {
    super(PAYMENT_FAILED_ERROR_STATUS_CODE, errorDetails, message);
  }
}
