import React from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

export const Caption: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <Text fontStyle="medium" style={styles.caption}>
      {t('consentSignature.caption')}
    </Text>
  );
};
