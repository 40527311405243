import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Modal, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { VisitOutcomeSchema } from '../../visitOutcomes';
import type { ModalProps } from '@almond/ui';

export type DisclaimerModalProps = Pick<ModalProps, 'isVisible' | 'onRequestClose'> & {
  visitOutcome: VisitOutcomeSchema;
  onSubmit: () => void;
};

export const DisclaimerModal = (props: DisclaimerModalProps) => {
  const { visitOutcome, onSubmit, ...restProps } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <Modal {...restProps} showClose containerStyle={[styles.modalContainer, isMobile && styles.modalContainerMobile]}>
      <Text
        style={[styles.title, isMobile && styles.titleMobile]}
        size="xxxl"
        fontStyle="bold"
        testID="DisclaimerModal-Title"
      >
        {t('scheduling.disclaimer.title')}
      </Text>
      <View style={[styles.content, isMobile && styles.contentMobile]}>
        <Text fontStyle="bold" style={styles.subtitle}>
          {visitOutcome.copy}
        </Text>
        <Text>{t('scheduling.disclaimer.message')}</Text>
      </View>
      <View style={styles.footer}>
        <Button size="m" onPress={restProps.onRequestClose} testID="DisclaimerModal-Submit">
          {t('scheduling.disclaimer.submitTitle')}
        </Button>
        <Button type="secondary" size="m" onPress={onSubmit} testID="DisclaimerModal-Secondary">
          {t('scheduling.disclaimer.secondaryTitle')}
        </Button>
      </View>
    </Modal>
  );
};
