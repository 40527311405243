import { atom } from 'recoil';

import {
  CREDIT_CARD_STATE_KEY,
  DEFAULT_CREDIT_CARD_STATE,
  DEFAULT_DEMOGRAPHIC_STATE,
  DEFAULT_INSURANCE_STATE,
  DEFAULT_PROFILE_PROGRESS_STATE,
  DEFAULT_PROFILE_STATE,
  DEMOGRAPHIC_STATE_KEY,
  INSURANCE_STATE_KEY,
  PROFILE_PROGRESS_STATE_KEY,
  PROFILE_STATE_KEY,
} from './config';

export const profileAtom = atom({
  key: PROFILE_STATE_KEY,
  default: DEFAULT_PROFILE_STATE,
});

export const demographicAtom = atom({
  key: DEMOGRAPHIC_STATE_KEY,
  default: DEFAULT_DEMOGRAPHIC_STATE,
});

export const creditCardAtom = atom({
  key: CREDIT_CARD_STATE_KEY,
  default: DEFAULT_CREDIT_CARD_STATE,
});

export const insuranceAtom = atom({
  key: INSURANCE_STATE_KEY,
  default: DEFAULT_INSURANCE_STATE,
  dangerouslyAllowMutability: true,
});

export const profileProgressAtom = atom({
  key: PROFILE_PROGRESS_STATE_KEY,
  default: DEFAULT_PROFILE_PROGRESS_STATE,
});
