body {
  /*
     * This prevents the overall page from overscrolling, or from pulling to refresh.
     * This makes the fixed header feel more like a real fixed header
     */
  height: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
}

ul {
  margin: 0;
}
