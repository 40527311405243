/* eslint-disable @typescript-eslint/no-var-requires,global-require */
import React from 'react';
import { useWindowDimensions, View } from 'react-native';

import { useBrowserTypeMap, useIsReducedMotionEnabled, useTheme } from '@almond/ui';

import themedStyles from './styles';

// Using require instead of import because if breaks the WebStorm on my side.
const LottieView = require('lottie-react-native').default;

const COEFFICIENT = 3.5;

export const ConfettiBurstsDecoration = () => {
  const [styles] = useTheme(themedStyles);
  const dimensions = useWindowDimensions();
  const imageWidthMobile = dimensions.height / COEFFICIENT;
  const imageWidthDesktop = dimensions.height / COEFFICIENT;
  const imageMarginLeftMobile = (dimensions.width - imageWidthMobile) / 2;
  const imageMarginLeftDesktop = dimensions.width / 4;
  const { isMobile } = useBrowserTypeMap();

  return (
    <View
      style={[
        {
          width: isMobile ? imageWidthMobile : imageWidthDesktop,
          marginLeft: isMobile ? imageMarginLeftMobile : imageMarginLeftDesktop,
        },
        styles.container,
      ]}
    >
      <LottieView
        autoPlay={!useIsReducedMotionEnabled()}
        source={require('./animation.json')}
        style={styles.animation}
        speed={1.1}
      />
    </View>
  );
};
