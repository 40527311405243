import dayjs from 'dayjs';

import { DATE_COMPARISON_UNIT_MAP, DATE_FORMAT_LENGTH_MAP, DATE_REGEX_MAP } from '../config';

import type { DateInputFormat } from '@almond/date-and-time';
import type { SelectInputValue } from '@almond/ui';
import type { Dayjs } from 'dayjs';
import type * as Yup from 'yup';

export const validateValue = (
  path: string,
  createError: any,
  errorMessage: string,
  regex: RegExp[],
  value?: string
): Yup.ValidationError | boolean => {
  if (!value) return true;

  return regex.some(r => value.match(r)) || createError({ path, message: errorMessage });
};

export const validateDate = (
  path: string,
  createError: any,
  errorMessage: string,
  formats: DateInputFormat[],
  value?: string
): Yup.ValidationError | boolean => {
  let isValid = validateValue(
    path,
    createError,
    errorMessage,
    formats.map(format => DATE_REGEX_MAP[format]),
    value
  );

  if (isValid && value) {
    const format = DATE_FORMAT_LENGTH_MAP[value.length];

    // An additional check to fix some edge cases, for example:
    // If a user enters 02/31/2024, then it returns 03/02/2024, so the strings won't match.
    isValid = dayjs(value, format).format(format) === value || createError({ path, message: errorMessage });
  }

  return isValid;
};

export const validateMinDate = (
  path: string,
  createError: any,
  errorMessage: string,
  value?: string,
  minDate?: Dayjs
): Yup.ValidationError | boolean => {
  if (!value || !minDate) return true;

  const format = DATE_FORMAT_LENGTH_MAP[value.length];
  const unit = DATE_COMPARISON_UNIT_MAP[format];

  return dayjs(value, format).isSameOrAfter(minDate, unit) || createError({ path, message: errorMessage });
};

export const validateMaxDate = (
  path: string,
  createError: any,
  errorMessage: string,
  value?: string,
  maxDate?: Dayjs
): Yup.ValidationError | boolean => {
  if (!value) return true;

  const format = DATE_FORMAT_LENGTH_MAP[value.length];
  const unit = DATE_COMPARISON_UNIT_MAP[format];

  return dayjs(value, format).isSameOrBefore(maxDate, unit) || createError({ path, message: errorMessage });
};

export const validateZip = (
  path: string,
  createError: any,
  errorMessage: string,
  value?: string
): Yup.ValidationError | boolean => {
  return validateValue(path, createError, errorMessage, [/^[0-9]{5}(?:-[0-9]{4})?$/], value);
};

export const validateCreditCardNumber = (
  path: string,
  createError: any,
  errorMessage: string,
  value?: string
): Yup.ValidationError | boolean => {
  return validateValue(
    path,
    createError,
    errorMessage,
    [
      // eslint-disable-next-line max-len
      /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/,
    ],
    value
  );
};

export const validateCreditCardCVC = (
  path: string,
  createError: any,
  errorMessage: string,
  value?: string
): Yup.ValidationError | boolean => {
  return validateValue(path, createError, errorMessage, [/^[0-9]{3,4}$/], value);
};

export const validatePhoneNumber = (
  path: string,
  createError: any,
  errorMessage: string,
  value?: string
): Yup.ValidationError | boolean => {
  if (!value) return true;

  const phoneRegexes = [
    /^(\+?1)? ?\(\d{3}\) ?\d{3}-\d{4}$/, // "+1 (123) 456-7890", "+1(123)456-7890", "(123) 456-7890", "(123)456-7890"
    /^(\+?1)?\d{10}$/, // "+11234567890", "1234567890"
  ];

  return phoneRegexes.some(regex => regex.test(value.trim())) || createError({ path, message: errorMessage });
};

export const validateSelectInputValue = (
  path: string,
  createError: any,
  errorMessage: string,
  value?: SelectInputValue<string>
): any => {
  if (!value) return true;

  return !!Object.keys(value).length || createError({ path, message: errorMessage });
};
