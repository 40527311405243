import React from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import { OFFICE_VISIT_PRICE, SAME_DAY_VISIT_PRICE, TELEHEALTH_VISIT_PRICE, ULTRASOUND_PRICE } from '../../config';
import SecondaryMessageSubtitle from './SecondaryMessageSubtitle';

import themedStyles from './styles';

const SecondaryMessage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <>
      <Text fontStyle="medium" style={styles.text}>
        {t('visitCostMessage.secondaryMessage.title')}
      </Text>
      <SecondaryMessageSubtitle left={t('visitCostMessage.secondaryMessage.officeVisit')} right={OFFICE_VISIT_PRICE} />
      <SecondaryMessageSubtitle
        left={t('visitCostMessage.secondaryMessage.telehealthVisit')}
        right={TELEHEALTH_VISIT_PRICE}
      />
      <SecondaryMessageSubtitle
        left={t('visitCostMessage.secondaryMessage.sameDayVisit')}
        right={SAME_DAY_VISIT_PRICE}
      />
      <SecondaryMessageSubtitle left={t('visitCostMessage.secondaryMessage.ultrasound')} right={ULTRASOUND_PRICE} />
    </>
  );
};

export default SecondaryMessage;
