import { useCallback } from 'react';

import { setHeapUser } from '@almond/analytics';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/browser';
import { useFocusEffect, useGlobalSearchParams, useRouter } from 'expo-router';
import Cookies from 'js-cookie';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useUpdateUser } from 'statsig-react';

import env from '~env';
import { appointmentsApi, usersApi } from '~modules/api';
import { useAsync } from '~modules/promises';
import { appointmentParamsAtom, userAtom } from '~modules/state';

import { CDN_COOKIE_NAME } from '../config';
import { log, logError } from '../logger';
import { userUtilities } from '../services';

const setCookie = (cdnCookie: string) => {
  Cookies.remove(CDN_COOKIE_NAME);
  Cookies.set(CDN_COOKIE_NAME, cdnCookie, {
    domain: env.APEX_DOMAIN,
    sameSite: 'None',
    secure: true,
    expires: 1,
  });
};

const alertHeader = 'IF YOU ARE AN ALMOND MEMBER AND YOU SEE THIS, PLEASE CALL US AT (424) 237-8406';
const alertFooter = 'Contact engineering via the #bugs Slack channel if you believe you saw this in error.';

const useSetUser = (): boolean => {
  const [userState, setUserState] = useRecoilState(userAtom);
  const { isAuthenticated, isLoading: isAuthenticating } = useAuth0();
  const updateStatsigUser = useUpdateUser();
  const { doAsync, isLoading } = useAsync();
  const setAppointmentParamsState = useSetRecoilState(appointmentParamsAtom);
  const searchParams = useGlobalSearchParams();
  const router = useRouter();

  useFocusEffect(
    useCallback(() => {
      if (isAuthenticating || !isAuthenticated || userState.user || isLoading) {
        return;
      }

      // eslint-disable-next-line max-statements
      const toCall = async (): Promise<void> => {
        const user = await usersApi.getUser();

        log('User authenticated:', user);

        const userMetadata = {
          patientUuid: user.patientUuid,
          role: user.patientUuid ? 'patient' : 'internal',
        } as const;

        setUserState(prevState => ({ ...prevState, user }));
        Sentry.setUser({ id: user.authId });
        setHeapUser(user.authId, userMetadata);
        setCookie(user.cdnCookie);

        if (!userUtilities.isAdmin(user)) {
          // We would like to update patientUuid only if it's specified in the response.
          if (user.patientUuid) {
            router.setParams({ patient_uuid: user.patientUuid });
          }
        }

        updateStatsigUser({
          userID: user.authId,
          custom: userMetadata,
        });

        if (userUtilities.isAdmin(user) && searchParams.is_admin !== 'true') {
          // Ensure that if logged in as an admin, the is_admin flag must be set correctly.
          // This prevents accidentally going through the member booking flow when logging
          // in as an admin user.
          // eslint-disable-next-line no-alert
          window.alert(
            [
              alertHeader,
              '\n\nYou just tried logging in to Demi. You used the member login form but',
              'logged in with a Care Guide or Provider login. Either use a member/patient',
              'login, or go to Artemis to book a member/patient.',
              alertFooter,
            ].join(' ')
          );

          return;
        }

        if (searchParams.is_admin === 'true' && !userUtilities.isAdmin(user)) {
          if (process.env.EXPO_PUBLIC_ENV === 'prod') {
            logError('searchParams.is_admin is specified as true in the URL, but the current user is not an admin.');
            router.setParams({ is_admin: 'false', is_new_member: 'false' });
          } else {
            // eslint-disable-next-line no-alert
            window.alert(
              [
                'IF YOU ARE AN ALMOND MEMBER AND YOU SEE THIS, PLEASE CALL US AT (424) 237-8406',
                '\n\nYou just tried to book an appointment for a new member as a Care Guide or Provider,',
                'but you logged in as a patient.',
                'Go to the dashboard, sign out, and login using a Care Guide or Provider account.',
                alertFooter,
              ].join(' ')
            );
          }

          return;
        }

        if (searchParams.patient_uuid && user.patientUuid && searchParams.patient_uuid !== user.patientUuid) {
          // eslint-disable-next-line no-alert
          window.alert([
            [
              alertHeader,
              '\n\nIt seems like you clicked on a link for one user while logged in as another user.',
              'Either go to the dashboard and sign out, or use a correct link.',
              alertFooter,
            ].join(' '),
          ]);

          return;
        }

        const uuid = user.patientUuid || searchParams.patient_uuid;

        // Once we get /users/me (for the patient), or have logged on as a provider, get the patient's
        // past appointment count. NOTE that `uuid` will be falsy for the new patient self-book flow,
        // since the user auths with Auth0, comes back to the application, and calls /users/me BEFORE
        // the "create patient" API call runs. So the patient can call /users/me BUT `patient_uuid` will
        // be undefined.
        // However, we can guarantee that in that case, `getPastAppointmentCount` will return 0 for that user,
        // so the network request is unnecessary anyway!
        let isNewMemberRemote = true;

        if (uuid) {
          const response = await appointmentsApi.getPastAppointmentCount(uuid);

          isNewMemberRemote = response.count === 0;
        }

        setAppointmentParamsState(prevState => ({
          ...prevState,
          isNewMemberRemote,
        }));
      };

      doAsync(toCall);
    }, [
      doAsync,
      isAuthenticated,
      isAuthenticating,
      isLoading,
      router,
      searchParams.is_admin,
      searchParams.patient_uuid,
      setAppointmentParamsState,
      setUserState,
      updateStatsigUser,
      userState.user,
    ])
  );

  return isAuthenticating || (isAuthenticated && !userState.user) || isLoading;
};

export default useSetUser;
