import type { RequiredFieldsMode } from '@almond/ui';
import type { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';

export const extractNestedField = (object: Record<string, any>, key: string): any => {
  return key.split('.').reduce((acc, cur) => {
    if (!acc) return undefined;

    return acc[cur];
  }, object);
};

export const isRequiredFieldValid = (values: Record<string, any>, requiredField: string): boolean => {
  const nestedField = extractNestedField(values, requiredField);

  if (Array.isArray(nestedField)) return !!nestedField.length;
  if (typeof nestedField === 'object' && nestedField !== null) return !!Object.keys(nestedField).length;

  return !!nestedField;
};

export const areRequiredFieldsValid = (
  values: Record<string, any>,
  requiredFields: string[],
  mode: RequiredFieldsMode = 'all'
): boolean => {
  if (!requiredFields.length) return true;

  return requiredFields.reduce((acc, cur) => {
    if (mode === 'oneOf') return acc || isRequiredFieldValid(values, cur);

    return acc && isRequiredFieldValid(values, cur);
  }, mode === 'all');
};

export const callOnEnterPress = (fn: () => void) => (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
  if ('key' in event && event.key === 'Enter') {
    event.preventDefault();
    fn();
  }
};
