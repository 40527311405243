/* eslint-disable global-require */

import {
  CIRCULAR_STD_BLACK,
  CIRCULAR_STD_BLACK_ITALIC,
  CIRCULAR_STD_BOLD,
  CIRCULAR_STD_BOLD_ITALIC,
  CIRCULAR_STD_BOOK,
  CIRCULAR_STD_BOOK_ITALIC,
  CIRCULAR_STD_MEDIUM,
  CIRCULAR_STD_MEDIUM_ITALIC,
  PLAYFAIR_DISPLAY_BOLD,
} from '@almond/ui';
import * as Font from 'expo-font';

export const loadFonts = async (): Promise<void> => {
  try {
    await Font.loadAsync({
      [CIRCULAR_STD_BLACK]: require('../fonts/CircularStd-Black.ttf'),
      [CIRCULAR_STD_BLACK_ITALIC]: require('../fonts/CircularStd-BlackItalic.ttf'),
      [CIRCULAR_STD_BOLD]: require('../fonts/CircularStd-Bold.ttf'),
      [CIRCULAR_STD_BOLD_ITALIC]: require('../fonts/CircularStd-BoldItalic.ttf'),
      [CIRCULAR_STD_BOOK]: require('../fonts/CircularStd-Book.ttf'),
      [CIRCULAR_STD_BOOK_ITALIC]: require('../fonts/CircularStd-BookItalic.ttf'),
      [CIRCULAR_STD_MEDIUM]: require('../fonts/CircularStd-Medium.ttf'),
      [CIRCULAR_STD_MEDIUM_ITALIC]: require('../fonts/CircularStd-MediumItalic.ttf'),
      [PLAYFAIR_DISPLAY_BOLD]: require('../fonts/PlayfairDisplay-Bold.ttf'),
    });
  } catch (error: any) {
    // In the meantime, re-throw so Sentry sees an uncaught error
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};
