import React from 'react';

import { useController, useFormContext } from '@almond/ui';

import { AddressInput } from '~modules/location';

import type { PharmacyInputProps } from '~modules/location';

export type ConnectedAddressInputProps = Omit<PharmacyInputProps, 'onSelect'> & {
  name: string;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedAddressInput: React.FC<ConnectedAddressInputProps> = props => {
  const { name, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, value, ...restField } = field;

  return (
    <AddressInput
      {...restField}
      {...restProps}
      onSelect={selectedValue => {
        if (selectedValue.addressLine1) formContext.setValue('line1', selectedValue.addressLine1);
        if (selectedValue.city) formContext.setValue('city', selectedValue.city);
        if (selectedValue.region) formContext.setValue('stateCode', selectedValue.region);
        if (selectedValue.postalCode) formContext.setValue('zip', selectedValue.postalCode);
      }}
    />
  );
};

export default ConnectedAddressInput;
