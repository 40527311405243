import React from 'react';

import { SelectInputGroup, useController, useFormContext } from '@almond/ui';
import { usePathname } from 'expo-router';

import type { SelectInputGroupProps } from '@almond/ui';

export type ConnectedSelectInputGroupProps<T extends string> = Omit<
  SelectInputGroupProps<T>,
  'onSelect' | 'values' | 'closeModalTrigger'
> & {
  name: string;
  onLengthChange?: (length: number) => void;
};

/**
 * This component was designed to connect to `react-hook-form`.
 * Component is connected automatically if it is rendered inside our Form component.
 */
const ConnectedSelectInputGroup: React.FC<ConnectedSelectInputGroupProps<string>> = props => {
  const { name, onLengthChange, ...restProps } = props;
  const formContext = useFormContext();
  const { field } = useController({ name, control: formContext.control });
  const { ref, onChange, value, ...restField } = field;
  const pathname = usePathname();

  return (
    <SelectInputGroup
      {...restField}
      {...restProps}
      values={value}
      onSelect={selectedValue => {
        onChange(selectedValue);
        onLengthChange?.(selectedValue.length);
      }}
      closeModalTrigger={pathname}
    />
  );
};

export default ConnectedSelectInputGroup;
