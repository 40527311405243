import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { useTheme } from '@almond/ui';

import PlacesInput from '../PlacesInput';

import themedStyles from './styles';

import type { PlacesInputProps } from '../PlacesInput';

export type AddressInputProps = Omit<
  PlacesInputProps,
  'types' | 'placeholder' | 'autoCapitalize' | 'textContentType' | 'autocompleteRef' | 'textInputRef'
>;

export const AddressInput = (props: AddressInputProps) => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <PlacesInput
          {...props}
          label={t('address.line1')}
          autoCapitalize="words"
          textContentType="streetAddressLine1"
          types="address"
          filterReverseGeocodingByTypes={['establishment', 'street_address']}
          renderDescription={description => description.structured_formatting.main_text}
        />
      </View>
    </View>
  );
};
