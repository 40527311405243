import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(() => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'none',
    width: '100%',
    paddingVertical: 12.8,
  },
  containerVisible: {
    display: 'flex',
  },
  contentText: {
    textAlign: 'center',
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: sizes.SPACING_M,
    width: '100%',
  },
  contentDesktop: {
    paddingHorizontal: sizes.SPACING_XXL,
  },
  close: {
    flexGrow: 0,
    position: 'absolute',
    right: sizes.SPACING_M,
  },
}));
