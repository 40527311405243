import type { PatientProgressState, PatientState } from '~types';

export const PATIENT_STATE_KEY = 'patientState';
export const PATIENT_PROGRESS_STATE_KEY = 'patientProgressState';
export const LOADED_PATIENT_UUID_STATE_KEY = 'loadedPatientUuidAtom';

export const DEFAULT_PATIENT_STATE: PatientState = {
  signature: '',
};

export const DEFAULT_PATIENT_PROGRESS_STATE: PatientProgressState = {};
