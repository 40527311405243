import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import {
  ConnectedPhoneNumberInput,
  ConnectedTextInput,
  Form,
  ScrollView,
  SENTRY_UNMASK,
  SubmitButton,
  useTheme,
} from '@almond/ui';
import { yupResolver } from '@hookform/resolvers/yup';

import { feedbackApi } from '~modules/api';
import { useAsync } from '~modules/promises';

import { logError } from '../../logger';
import { BaseModal } from '../BaseModal';
import { validationSchema } from './validations';

import { themedStyles } from './styles';

import type { BaseModalProps } from '../BaseModal';
import type { FormValues } from '@almond/ui';

export type FeedbackModalProps = Omit<BaseModalProps, 'title' | 'subtitle'> & {
  feedbackUuid: string | (() => Promise<string>);
  onSubmit: () => void;
  eventType: 'idle' | 'abandon';
};

type FeedbackFormValues = {
  email?: string;
  phone?: string;
  feedback: string;
};

export const FeedbackModal = (props: FeedbackModalProps) => {
  const { feedbackUuid, onSubmit, eventType, ...restProps } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { doAsync, isLoading } = useAsync();

  const handleSubmit = (values: FormValues<FeedbackFormValues>) => {
    const toCall = async () => {
      try {
        const resolvedFeedbackUuid = typeof feedbackUuid === 'string' ? feedbackUuid : await feedbackUuid();

        if (resolvedFeedbackUuid) {
          await feedbackApi.updateFeedback(resolvedFeedbackUuid)({
            eventType,
            response: values,
          });
        }
      } catch (err) {
        // Don't block user from completing their action if the API request fails, but log
        // a useful error to Sentry
        logError(`Call to ${eventType} feedback API failed`, `Payload: ${JSON.stringify(values)}`);
      }

      onSubmit();
    };

    doAsync(toCall);
  };

  return (
    <BaseModal title={t('feedbackModal.title')} {...restProps}>
      <Form
        name="Feedback form"
        onSubmit={handleSubmit}
        resolver={yupResolver(validationSchema)}
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        <View style={styles.container}>
          <ScrollView contentContainerStyle={styles.contentContainer}>
            <ConnectedPhoneNumberInput
              name="phone"
              label={t('feedbackModal.phoneLabel')}
              containerStyle={styles.textInput}
              textContentType="telephoneNumber"
              style={styles.input}
              testID="FeedbackModal-PhoneNumber"
            />
            <ConnectedTextInput
              name="email"
              label={t('feedbackModal.emailLabel')}
              containerStyle={styles.textInput}
              inputMode="email"
              textContentType="emailAddress"
              style={styles.input}
              testID="FeedbackModal-Email"
            />
            <ConnectedTextInput
              name="feedback"
              placeholder={t('feedbackModal.feedbackPlaceholder')}
              multiline
              containerStyle={styles.textInput}
              style={styles.input}
              testID="FeedbackModal-Feedback"
            />
          </ScrollView>
        </View>
        <SubmitButton
          requiredFieldsMode="oneOf"
          requiredFields={['phone', 'email', 'feedback']}
          style={[styles.button, SENTRY_UNMASK]}
          type="accent"
          size="m"
          testID="FeedbackModal-Submit"
        >
          {t('feedbackModal.submitTitle')}
        </SubmitButton>
      </Form>
    </BaseModal>
  );
};
