import { useMemo } from 'react';

import { useNavigation, useRouter } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { useRouteContext } from '../context/routeContext';
import { log } from '../logger';
import { getAllowedSearchParams, routerPathToNavigationPath } from '../services';

import type routeConfigs from '../configurations';
import type { BaseParamsWithoutRouting, RouteConfig, RoutingConfig, StackParamList } from '~types';
import type { RecoilValue } from 'recoil';

type DefaultInferredRouteConfig = (typeof routeConfigs)[keyof typeof routeConfigs];

/**
 * To know when routing config we're currently "inside of", we rely on the `routingConfigId`
 * param. However, when the app initially starts, there is no `routingConfigId` value. We need
 * to somehow bootstrap the `routingConfigId` value. That is what this function's purpose is when
 * called without a 3rd parameter.
 *
 * When routeToConfig() is called with a 3rd parameter, it calls navigation.push() instead of
 * navigation.replace(). This is because we're legitimately navigating to a new page defined
 * by the config, and we want to add a new history entry for it. This is used when one config
 * navigates to another config.
 *
 * The 2nd parameter of routeToConfig() is a "destination" object. It's a way to initialize
 * the config when on a page deeper inside a config. This is specifically useful when redirecting
 * back to the application from Auth0. This might not be a good idea, and we might want to remove
 * this functionality as we learn more about how these configs will be used and organized.
 */
export const useRouteToConfig = <ParamMap extends StackParamList = StackParamList>() => {
  const navigation = useNavigation();
  const router = useRouter();
  const routingConfigMap = useRouteContext<typeof routeConfigs>();

  const getState = useRecoilCallback(
    callbackInterface =>
      async <T>(atom: RecoilValue<T>): Promise<T> => {
        return callbackInterface.snapshot.getPromise(atom);
      },
    []
  );

  return useMemo(() => {
    // Define the returned function as an overloaded function. The function has two
    // signatures. The first is stricter, enforcing that if the config being routed
    // to requires params, that those params are passed.
    // The second is for situations in which we can't infer the config being passed, so
    // there are no params being passed in.
    //
    // If the caller passes in a param object, it must be able to infer the correct value
    // of the params to avoid a type error. However, if the params are omitted, the
    // type checker won't catch that the params are missing. This is a shortcoming with
    // this function definition, but it's the best solution I could find.
    async function routeToConfig<Id extends string>(
      config: { id: Id },
      pushHistoryItem: boolean,
      searchParams: BaseParamsWithoutRouting
    ): Promise<void>;
    async function routeToConfig<Id extends DefaultInferredRouteConfig['id']>(
      config: { id: Id },
      pushHistoryItem: boolean,
      searchParams: BaseParamsWithoutRouting
    ): Promise<void> {
      const routingConfig: RoutingConfig<StackParamList> | undefined =
        routingConfigMap[config.id as keyof typeof routeConfigs];

      if (!routingConfig) {
        throw new Error(`No routing config found with ID "${config.id}"`);
      }

      const result = await ('initial' in routingConfig && routingConfig.initial
        ? routingConfig.initial(searchParams)
        : routingConfig.initialAsync(getState, searchParams));

      log(`"${config.id}" configuration is active.`);

      const nextState = routingConfig.routes[result.name as keyof typeof routingConfig.routes] as
        | RouteConfig<ParamMap>
        | undefined;

      if (!nextState) {
        throw new Error(`Invalid destination "${result.name}" for initial route on config ID "${routingConfig.id}"`);
      }

      const navigationPath = routerPathToNavigationPath(result.name);
      const allowedSearchParams = getAllowedSearchParams(searchParams);
      const params = { ...allowedSearchParams, ...result.params, routing_config_id: routingConfig.id };

      if (nextState.reset) {
        navigation.reset({
          routes: [{ name: navigationPath, params }],
          index: 0,
        });
      } else if (pushHistoryItem && !result.replace) {
        // No idea why this type isn't working. It's the exact same thing happening
        // when routing to a state in useRouteNavigation(), but there Typescript is
        // ok with this.
        // If this is called outside the ApplicationStack (i.e. in Auth0 state restoring),
        // then navigation.push() doesn't exist. So we need to use navigation.navigate()
        const performNavigation = router.push || router.navigate;

        performNavigation({ pathname: result.name, params });
      } else {
        // One of two conditions:
        // 1. We're "replacing" the state, so pop off the current page and replace it
        //    with the new page
        // 2. We're "connecting" the current navigation page to the config by "navigating"
        //    to the config. But that navigation *should* just navigate to the same page
        //    (with added routingConfigId param), so .replace instead of .push to avoid a
        //    duplicate navigation history entry.
        router.replace({ pathname: result.name, params });
      }
    }

    return routeToConfig;
  }, [getState, navigation, router, routingConfigMap]);
};
