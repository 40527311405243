import React from 'react';
import { View } from 'react-native';

import { DAYS_IN_WEEK } from '@almond/date-and-time';
import { useTheme } from '@almond/ui';

import themedStyles from './styles';

export type EmptySpaceProps = {
  weekLength: number;
};

export const EmptySpace: React.FC<EmptySpaceProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <>
      {Array(DAYS_IN_WEEK - props.weekLength)
        .fill(-1)
        .map((_, index) => (
          <View key={index} style={styles.dateContainer} />
        ))}
    </>
  );
};
