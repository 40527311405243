import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';
import { useSetRecoilState } from 'recoil';

import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HistoryState, PRIMARY_CARE_PROVIDER_PAGE_NAME } from '~types';

export const PrimaryCareProviderPage: React.FC = () => {
  const { t } = useTranslation();
  const setHistoryState = useSetRecoilState(historyAtom);
  const saveHistory = useSaveHistory();
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof PRIMARY_CARE_PROVIDER_PAGE_NAME>();

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState> | null) => {
      if (!formValues) {
        setHistoryState(prevState => ({ ...prevState, primaryCareProvider: undefined }));
      }

      const toCall = async (): Promise<void> => {
        await saveHistory(
          formValues,
          'primary_care_provider',
          'primaryCareProvider',
          t('primaryCareProvider.skipTitle')
        );
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, setHistoryState, saveHistory, t, dispatch]
  );

  return (
    <MainForm
      id="primaryCareProvider"
      title={t('primaryCareProvider.title')}
      submitButtonTitle={t('continue')}
      onSubmit={handleSubmit}
      atom={historyAtom}
      requiredFields={['primaryCareProvider']}
      secondaryButtonTitle={t('primaryCareProvider.skipTitle')}
      onSecondaryPress={() => handleSubmit(null)}
      isLoading={isLoading}
    >
      <ConnectedTextInput
        name="primaryCareProvider"
        placeholder={t('primaryCareProvider.placeholder')}
        testID="PrimaryCareProvider"
      />
    </MainForm>
  );
};
