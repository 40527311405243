import { CIRCULAR_STD_BOOK, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  container: {
    flex: 1,
    marginTop: sizes.SPACING_M,
  },

  contentContainer: {
    alignItems: 'center',
    rowGap: 12,
    paddingVertical: sizes.SPACING_S,
  },

  textInput: {
    width: 340,
    maxWidth: '100%',
  },

  input: {
    fontFamily: CIRCULAR_STD_BOOK,
    fontSize: sizes.FONT_SIZE_M,
  },

  button: {
    width: 108,
    alignSelf: 'center',
    marginTop: sizes.SPACING_S,
  },
}));
