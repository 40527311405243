.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  padding-inline: 8px;
  height: 64px;
  background: var(--color-light-accent);
}

.select {
  height: 100%;
}

.visitReasonIcon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.currentVisitReason {
  height: 100%;
  appearance: none;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  padding-inline: 4px;
}
.currentVisitReasonButton {
  cursor: pointer;
}

.popover {
  background: var(--color-background);
  border-radius: 4px;
  box-shadow: var(--box-shadow);
}

.listItem {
  padding: 6px 12px;
  cursor: pointer;
}

.listItem[data-hovered='true'] {
  background: var(--color-light-accent);
}

.srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.listBox {
  max-height: min(580px, calc(90dvh - 130px));
  overflow-y: auto;
}

/* MOBILE_WIDTH_THRESHOLD */
@media (max-width: 600px) {
  .wrapper {
    background: transparent;
  }
  .popover {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background: var(--color-light-accent);

    /* override inline JS-based positioning to force it full width */
    left: 0 !important;
  }

  .listItem[data-focused='true'] {
    background: color-mix(in srgb, var(--color-light-accent) 90%, var(--color-accent));
  }

  .listItem[data-selected='true'] {
    background: var(--color-selected-option);
  }
}
