import React from 'react';

import { useTranslation } from '@almond/localization';
import { CONTACT_US_PHONE_NUMBER, MaterialIcon, Text, useTheme } from '@almond/ui';
import { useClipboard } from '@almond/utils';

import { Calendar, Phone } from '~assets';
import { Container } from '~modules/layout';
import { useRouteNavigation } from '~modules/routing';

import { BlockItemLink } from '../../components';

import themedStyles from './styles';

import type { LEARN_MORE_PAGE_NAME } from '~types';

export const LearnMorePage: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const { copy, isCopied } = useClipboard();
  const { dispatch } = useRouteNavigation<typeof LEARN_MORE_PAGE_NAME>();

  return (
    <Container id="learnMore" title={t('learnMore.title')} size="L">
      <Text style={[styles.copyMessage, isCopied && styles.showCopyMessage]} aria-hidden={!isCopied}>
        {t('learnMore.copySuccess')}
      </Text>
      <BlockItemLink
        icon={Phone}
        onPress={() => copy(CONTACT_US_PHONE_NUMBER)}
        title={t('learnMore.call')}
        rightIcon={<MaterialIcon source="content-copy" size={16} color="secondaryTextDark" />}
      />
      <BlockItemLink
        icon={Calendar}
        onPress={() => dispatch('submit')}
        title={t('learnMore.schedule')}
        rightIcon={null}
      />
    </Container>
  );
};
