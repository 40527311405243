.providers {
  display: grid;
  gap: 15px;
  grid-auto-rows: 1fr;
}

.provider {
  overflow: hidden;
  display: grid;
  grid-template-areas:
    'photo .     .'
    'photo name  button'
    'photo title title'
    'photo .     .';
  grid-template-columns: min-content auto min-content;
  grid-template-rows: 20px auto 1fr 20px;
  column-gap: 10px;
  padding: 0;
  padding-right: 20px;
}

.name {
  grid-area: name;
}
.title {
  grid-area: title;
}
.loadingTitle {
  margin-top: 5px;
}
.button {
  grid-area: button;
  width: 80px;
}
.photo {
  grid-area: photo;
  width: 90px;
  height: 100%;
}
