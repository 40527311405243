import { useRecoilValue } from 'recoil';

import { userAtom } from '~modules/state';

import { userUtilities } from '../services';

const useIsAdmin = (): boolean => {
  const userState = useRecoilValue(userAtom);

  return userUtilities.isAdmin(userState.user);
};

export default useIsAdmin;
