import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  containerMobile: {
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: sizes.SPACING_XL,
  },

  messageContainer: {
    ...getShadow(),
    backgroundColor: theme.background,
    flex: 1,
    minWidth: 480,
    width: 637,
    borderRadius: sizes.SPACING_S,
    justifyContent: 'center',
    paddingVertical: 48,
    paddingHorizontal: 64,
    rowGap: sizes.SPACING_M,
  },

  messageContainerMobile: {
    minWidth: 0,
    width: '90%',
    alignItems: 'center',
    paddingHorizontal: sizes.SPACING_XXL,
    paddingVertical: sizes.SPACING_XL,
  },

  messageOver: {
    zIndex: 1,
  },

  image: {
    marginTop: -80,
    marginStart: -180,
  },

  imageMobile: {
    marginStart: 0,
    marginTop: 0,
    marginBottom: -68,
    zIndex: 2,
  },

  center: {
    textAlign: 'center',
  },

  footerDesktop: {
    marginTop: sizes.SPACING_M,
  },
}));
