import React from 'react';
import { Image, View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { NoticeModal, useBrowserTypeMap, useTheme } from '@almond/ui';

import { useImages } from '~assets';

import themedStyles from './styles';

import type { NoticeModalProps } from '@almond/ui';

type ConfirmationModalProps = Pick<NoticeModalProps, 'isVisible' | 'onRequestClose'> & {
  onLeavePress: () => void;
};

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { onLeavePress, ...restProps } = props;
  const { isMobile } = useBrowserTypeMap();
  const images = useImages();
  const image = (
    <View style={[styles.modalImage, isMobile && styles.modalImageMobile]}>
      <Image resizeMode="contain" source={images.universe3} style={styles.image} />
    </View>
  );

  return (
    <NoticeModal
      {...restProps}
      image={image}
      onPrimaryPress={restProps.onRequestClose}
      onSecondaryPress={onLeavePress}
      title={t('closeButton.modal.title')}
      subtitle={t('closeButton.modal.subtitle')}
      primaryButtonText={t('closeButton.modal.cancelButton')}
      secondaryButtonText={t('closeButton.modal.leaveButton')}
    />
  );
};
