import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';

import { AddressInput, MainForm, StateCodeInput } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { addressAtom } from '~modules/state';

import { useCreateAddress } from '../../hooks';
import { validationSchema } from './validations';

import type { FormValues } from '@almond/ui';
import type { ADDRESS_PAGE_NAME, AddressFormValues } from '~types';

export const AddressPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const createAddress = useCreateAddress();
  const { dispatch } = useRouteNavigation<typeof ADDRESS_PAGE_NAME>();

  const handleSubmit = useCallback(
    (values: FormValues<AddressFormValues>) => {
      const toCall = async (): Promise<void> => {
        await createAddress(values);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, createAddress, dispatch]
  );

  return (
    <MainForm
      id="address"
      title={t('address.title')}
      submitButtonTitle={t('continue')}
      atom={addressAtom}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isLoading={isLoading}
      requiredFields={['line1', 'city', 'stateCode', 'zip']}
      size="M"
    >
      <AddressInput name="line1" testID="Address" />
      <ConnectedTextInput
        name="line2"
        label={t('address.line2')}
        autoCapitalize="words"
        textContentType="streetAddressLine2"
      />
      <ConnectedTextInput
        name="city"
        label={t('address.city')}
        autoCapitalize="words"
        textContentType="addressCity"
        testID="City"
      />
      <StateCodeInput name="stateCode" label={t('address.stateCode')} testID="StateCode" />
      <ConnectedTextInput name="zip" label={t('zip')} inputMode="numeric" textContentType="postalCode" testID="Zip" />
    </MainForm>
  );
};
