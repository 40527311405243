import { LAB_ORDERS_PAGE_NAME } from '~types';

import { testingRoutes } from './testing';

import type { RoutingConfig, StackParamList } from '~types';

export const labRoutes = {
  id: 'lab' as const,
  initial: () => ({ name: LAB_ORDERS_PAGE_NAME }),
  routes: {
    [LAB_ORDERS_PAGE_NAME]: {
      on: {
        submit: () => testingRoutes,
      },
    },
  },
} satisfies RoutingConfig<StackParamList>;
