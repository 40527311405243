import { I18n } from '@almond/localization';

import type { InsuranceCarrierChoicesEnum } from '@almond/api-types';
import type { SelectOption } from '@almond/ui';

export const AETNA = 'aetna';
export const ANTHEM = 'anthem';
export const BLUE_SHEILD = 'blueShield';
export const CIGNA = 'cigna';
export const UNITED_HEALTHCARE = 'unitedHealthcare';
export const OTHER_INSURANCE = 'other';
export const BLUE_CROSS_BLUE_SHIELD = 'blueCrossBlueShield';
export const INSURANCE_OPTIONS: SelectOption<InsuranceCarrierChoicesEnum>[] = [
  { value: AETNA, label: 'Aetna PPO' },
  { value: ANTHEM, label: 'Anthem Blue Cross PPO' },
  { value: BLUE_CROSS_BLUE_SHIELD, label: 'Blue Cross Blue Shield PPO' },
  { value: BLUE_SHEILD, label: 'Blue Shield PPO' },
  { value: CIGNA, label: 'Cigna PPO' },
  { value: UNITED_HEALTHCARE, label: 'United Healthcare PPO' },
  { value: OTHER_INSURANCE, label: I18n.t('other') },
];
export const OFFICE_VISIT_PRICE = '$300';
export const SAME_DAY_VISIT_PRICE = '$250';
export const TELEHEALTH_VISIT_PRICE = '$200';
export const ULTRASOUND_PRICE = '$150';
