import type { GoogleMapApiResult, LocationAttributes } from '~types';

export const parseToLocationAttributes = (result: GoogleMapApiResult): LocationAttributes => {
  const addressComponent = result.address_components;

  const number = addressComponent.find(component => component.types.includes('street_number'))?.long_name;
  const premise = addressComponent.find(component => component.types.includes('premise'))?.long_name;
  const street = addressComponent.find(component => component.types.includes('street_address'))?.long_name;
  const route = addressComponent.find(component => component.types.includes('route'))?.long_name;

  const addressLine1 = `${number || premise || ''} ${street || route || ''}`.trim();

  const neighborhood = addressComponent.find(component => component.types.includes('neighborhood'))?.long_name;
  const sublocality = addressComponent.find(component => component.types.includes('sublocality'))?.long_name;

  const addressLine2 = `${neighborhood || ''} ${sublocality || ''}`.trim();

  const city = addressComponent.find(component => component.types.includes('locality'))?.long_name;
  const region = addressComponent.find(component =>
    component.types.includes('administrative_area_level_1')
  )?.short_name;
  const postalCode = addressComponent.find(component => component.types.includes('postal_code'))?.long_name;
  const country = addressComponent.find(component => component.types.includes('country'))?.short_name;

  return {
    name: result.name,
    placesId: result.place_id,
    addressLine1,
    addressLine2: addressLine2.length < 1 ? undefined : addressLine2,
    city,
    region,
    postalCode: postalCode || '',
    country,
    formattedAddress: result.formatted_address,
    latitude: result.geometry?.location.lat || 0,
    longitude: result.geometry?.location.lng || 0,
  };
};
