import { API_PATH } from '../config';

export const PROFILES_PATH = `${API_PATH}profiles/` as const;

export const OTP_SESSIONS_PATH = (uuid: string) => `${PROFILES_PATH}${uuid as '{profile_uuid}'}/otp_sessions/` as const;

export const ADDRESS_PATH = (uuid: string) => `${PROFILES_PATH}${uuid as '{profile_uuid}'}/address/` as const;
export const ATTACH_PATH = (uuid: string, paymentMethodId: string) =>
  `${PROFILES_PATH}${uuid as '{profile_uuid}'}/cards/${paymentMethodId as '{payment_method_id}'}/attach` as const;
export const INSURANCE_CARD_FRONT_PATH = (uuid: string) =>
  `${PROFILES_PATH}${uuid as '{profile_uuid}'}/insurance/front/` as const;
export const INSURANCE_CARD_BACK_PATH = (uuid: string) =>
  `${PROFILES_PATH}${uuid as '{profile_uuid}'}/insurance/back/` as const;
export const INSURANCE_PATH = (uuid: string) => `${PROFILES_PATH}${uuid as '{profile_uuid}'}/insurance/` as const;
export const STRIPE_CUSTOMERS_PATH = (uuid: string) =>
  `${PROFILES_PATH}${uuid as '{profile_uuid}'}/stripe_customers/` as const;
export const VALIDATE_PATH = (uuid: string) => `${OTP_SESSIONS_PATH(uuid)}validate/` as const;
