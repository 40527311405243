import React, { useRef } from 'react';
import { View } from 'react-native';

import { BaseButton, SENTRY_UNMASK, Text, useIsFocused, useTheme } from '@almond/ui';

import { ChevronRight } from '~assets';

import themedStyles from './styles';

import type { PressableProps, StyleProp, ViewStyle } from 'react-native';

export type BlockItemLinkProps<T> = Pick<PressableProps, 'onPress'> & {
  testID?: string;
  onPress: (value: T) => void;
  icon: any;
  title: string;
  subtitle?: string;
  style?: StyleProp<ViewStyle>;
  rightIcon?: React.ReactNode;
};

export const BlockItemLink = <T = never,>(props: BlockItemLinkProps<T>) => {
  const { testID, onPress, style, title, subtitle, icon: Icon, rightIcon = <ChevronRight /> } = props;
  const [styles] = useTheme(themedStyles);
  const { isFocused, focusProps } = useIsFocused();
  const ref = useRef<View>(null);

  return (
    <BaseButton
      onPress={onPress}
      style={[styles.container, isFocused && styles.focused, style, SENTRY_UNMASK]}
      testID={testID}
      role="button"
      {...focusProps}
      ref={ref}
    >
      <View style={styles.iconContainer}>
        <Icon style={styles.icon} />
      </View>
      <View style={styles.center}>
        <Text style={styles.title} fontStyle="bold">
          {title}
        </Text>
        {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
      </View>
      {rightIcon}
    </BaseButton>
  );
};
