import { sizes, styleSheetFactory } from '@almond/ui';

const HEADSHOT_MOBILE_SIZE = 140;

export const themedStyles = styleSheetFactory(theme => ({
  bioContainer: {
    flex: 1,
    columnGap: 10,
    justifyContent: 'space-between',
  },

  bioContainerExpanded: {
    justifyContent: 'flex-start',
  },

  readBioBtn: {
    marginBottom: 'auto',
    width: 80,
  },

  bioBtn: {
    marginTop: 10,
    width: 100,
  },

  bioBtnLabel: {
    color: theme.secondaryTextDark,
  },

  modal: {
    backgroundColor: theme.lightAccent,
  },

  modalContent: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    rowGap: sizes.SPACING_L,
    padding: sizes.SPACING_M,
  },

  columnMobile: {
    alignItems: 'center',
    rowGap: sizes.SPACING_S,
  },

  headshotMobile: {
    width: HEADSHOT_MOBILE_SIZE,
    height: HEADSHOT_MOBILE_SIZE,
    borderRadius: HEADSHOT_MOBILE_SIZE / 2,
  },

  darkText: {
    color: theme.darkText,
  },
}));
