import React from 'react';

import { useTranslation } from '@almond/localization';
import { Link, Text, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { useRedirectBackToArtemis } from '~modules/integration';
import { isBaselineProduct } from '~modules/product';
import { useIsAdmin } from '~modules/user';

import themedStyles from './styles';

import type { HttpRequestError } from '~modules/errors';
import type { GestureResponderEvent } from 'react-native';

export type FormErrorProps = {
  error?: HttpRequestError;
  patientUuid: string | null;
};

const FormError: React.FC<FormErrorProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const redirectBackToArtemis = useRedirectBackToArtemis();
  const searchParams = useLocalSearchParams();
  const isBaseline = isBaselineProduct(searchParams.product);
  const isAdmin = useIsAdmin();
  let content = null;

  const handlePress = (e: GestureResponderEvent) => {
    e.preventDefault();

    if (props.patientUuid && isAdmin) {
      // It would be very complex to update all the Recoil state correctly to switch from the
      // new member flow to the returning member flow. It's likely (either now or over time in the
      // future) that we would forget to update something and cause the application state to be
      // incorrect.
      // Instead, let's just create a "returning patient" URL and do a hard reload navigation to that
      // URL. This resets the Recoil state from application bootstrap. This flow is used infrequently
      // enough (and only by Care Guides) that the UX tradeoff is worth the reduced code complexity.
      const urlObject = new URL(window.location.href);

      urlObject.pathname = '/';
      urlObject.searchParams.set('patient_uuid', props.patientUuid);
      urlObject.searchParams.set('is_new_member', 'false');
      window.location.href = urlObject.toString();
    } else {
      const path = isBaseline ? 'messages?input_hint=baseline_signup' : '/';

      redirectBackToArtemis(path);
    }
  };

  if (!props.error) return null;

  if (props.error?.errorCode === 'account_already_exists') {
    const reason: 'email' | 'phone' = props.error?.details?.errors[0]?.data?.reason || 'email';
    const humanReadableReason = t(`demographic.error.${reason}`);

    if (isAdmin) {
      content = (
        <>
          {t('demographic.error.adminPart1', { identifier: humanReadableReason })}
          <Link onPress={handlePress} style={styles.signIn} testID="EmailFoundLink">
            {t('demographic.error.adminPart2')}
          </Link>
          {t('demographic.error.adminPart3')}
        </>
      );
    } else if (reason === 'phone') {
      content = <>{t('demographic.error.contactCareTeam')}</>;
    } else {
      content = (
        <>
          {t('demographic.error.part1')}
          <Link onPress={handlePress} style={styles.signIn} testID="EmailFoundLink">
            {t('demographic.error.signIn')}
          </Link>
          {t('demographic.error.part2')}
        </>
      );
    }
  } else {
    content = props.error.message;
  }

  return <Text style={styles.formError}>{content}</Text>;
};

export default FormError;
