/* eslint-disable max-statements */
import { useRef } from 'react';

import { shouldLogAnalytics, trackShareASaleTransaction, useTrackAnalyticsEvent } from '@almond/analytics';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import env from '~env';
import { creditCardAtom, profileProgressAtom, userAtom } from '~modules/state';
import { userUtilities } from '~modules/user';

import { log, logAndCapture } from '../logger';
import { calculateDiscountedTotalPrice } from '../services';
import { useConfirmPayment } from './useConfirmPayment';

const productEventMap = {
  baseline: 'baseline_purchased',
  gynecology_membership: 'membership_purchased',
} as const;

export type PurchaseProps = {
  createPayment: (params: {
    promotionCode?: string;
    friendReferralCode?: string;
    price: string;
    firstMonthFree?: boolean;
    paymentBehavior?: string;
  }) => Promise<{
    stripeId: string | undefined;
    clientSecret?: string;
    type?: string;
    status?: string;
  }>;
  onSuccess?: () => void;
};

export const usePurchase = ({ createPayment, onSuccess }: PurchaseProps) => {
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const confirmPayment = useConfirmPayment();
  const searchParams = useLocalSearchParams();

  const secretRef = useRef<Record<string, { clientSecret?: string; type?: string }>>({});

  return useRecoilCallback(
    recoilInterface => async (paymentMethod?: string) => {
      const profileProgressState = await recoilInterface.snapshot.getPromise(profileProgressAtom);
      const userState = await recoilInterface.snapshot.getPromise(userAtom);
      const creditCardState = await recoilInterface.snapshot.getPromise(creditCardAtom);

      const isAdmin = userUtilities.isAdmin(userState.user);

      if (!searchParams.patient_uuid) {
        logAndCapture("Can't create payment without patient_uuid");

        return null;
      }

      if (!creditCardState.product) {
        logAndCapture("Can't create payment without product");

        return null;
      }

      if (!profileProgressState?.creditCard) {
        if (isAdmin) {
          log('Admin user - skipping payment creation since no credit card has been entered');

          return null;
        }
      }

      log('Creating payment for the patient');

      const isFirstMonthFreeApplied = creditCardState?.promotionCode?.code === 'firstmonthfree';
      const promotionCode =
        creditCardState.promotionCode?.state === 'success' && !isFirstMonthFreeApplied
          ? creditCardState.promotionCode.id
          : undefined;
      const friendReferralCode =
        creditCardState.friendReferralCode?.state === 'success' ? creditCardState.friendReferralCode.id : undefined;

      const payment = await createPayment({
        promotionCode,
        friendReferralCode,
        firstMonthFree: isFirstMonthFreeApplied,
        price: creditCardState.product?.price.id,
      });

      if (!payment.stripeId) {
        logAndCapture('Payment ID was not created despite a successful payment request to the API');

        return null;
      }

      const value = secretRef.current[payment.stripeId] || {};

      secretRef.current[payment.stripeId] = value;
      value.clientSecret = payment.clientSecret ?? value.clientSecret;
      value.type = payment.type ?? value.type;

      if (payment.status !== 'paid') {
        await confirmPayment(value.type || 'payment', value.clientSecret, paymentMethod);
      }

      const discountTotalPrice = calculateDiscountedTotalPrice(creditCardState.product, [
        creditCardState.promotionCode,
        creditCardState.friendReferralCode,
      ]);

      if (shouldLogAnalytics()) {
        trackShareASaleTransaction(payment.stripeId, discountTotalPrice, env.MERCHANT_ID);
      }

      trackAnalyticsEvent(productEventMap[creditCardState.product.almondProductKey], {
        isNewMember: searchParams.is_new_member === 'true',
        patientUuid: searchParams.patient_uuid,
        price: discountTotalPrice,
        content_id: creditCardState.product.id,
        content_name: creditCardState.product.name,
        content_type: 'product',
      });

      onSuccess?.();

      return payment.stripeId;
    },
    [
      searchParams.patient_uuid,
      searchParams.is_new_member,
      createPayment,
      trackAnalyticsEvent,
      onSuccess,
      confirmPayment,
    ]
  );
};
