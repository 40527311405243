import React from 'react';

import { Trans } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

type FooterProps = {
  onPress?: () => void;
};

const Footer: React.FC<FooterProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <Text size="s" style={styles.footerText}>
      {/* The structure of the elements inside <Trans> needs to match the structure in
      the translation key. Adding strings to ensure there are still nodes for
      <Trans> to populate. */}
      <Trans i18nKey="pharmacy.helperText">
        <Text size="s" style={styles.linkText} onPress={props.onPress}>
          {'Search for Alto Pharmacy'}
        </Text>
        <Text>
          {' to get your medication delivered for free. '}
          {'We have no formal affiliation we just love their fast delivery, good prices and excellent service.'}
        </Text>
      </Trans>
    </Text>
  );
};

export default Footer;
