import React from 'react';

import { useTranslation } from '@almond/localization';

import { MainForm } from '~modules/forms';
import { useRouteNavigation } from '~modules/routing';

import { VisitCostMessage } from '../../components';

import type { CASH_RATES_PAGE_NAME } from '~types';

export const CashRatesPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof CASH_RATES_PAGE_NAME>();

  return (
    <MainForm
      id="cashRates"
      title={t('cashRates.title')}
      submitButtonTitle={t('continue')}
      onSubmit={() => dispatch('submit')}
    >
      <VisitCostMessage />
    </MainForm>
  );
};
