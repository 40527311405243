import React from 'react';
import { Image, View } from 'react-native';

import { useTheme } from '@almond/ui';

import { Camera } from '~assets';

import { themedPhysicianStyles } from './styles';

import type { PhysicianFindOut, VisitLocationEnum } from '@almond/api-types';

export type HeadshotProps = {
  physician: PhysicianFindOut;
  location: VisitLocationEnum;
};

export const Headshot: React.FC<HeadshotProps> = props => {
  const [styles] = useTheme(themedPhysicianStyles);
  const { physician, location } = props;

  if (location === 'Office') {
    return <Image source={{ uri: physician.profileImage }} style={styles.headshot} role="presentation" />;
  }

  return (
    <View style={styles.headshot} role="presentation">
      <Image source={{ uri: physician.profileImage }} style={styles.headshotImage} />
      <View style={styles.cameraIcon}>
        <Camera />
      </View>
    </View>
  );
};
