import useSWR from 'swr';

import { productsApi } from '~modules/api';

import { calculateDiscount } from '../services';

import type { ProductOut } from '@almond/api-types';
import type { CreditCardState, PromotionCodeResult } from '~types';

export const useCalculatePrice = (
  product: Pick<ProductOut, 'price'> | undefined,
  creditCardState: Pick<CreditCardState, 'friendReferralCode' | 'promotionCode'>
) => {
  const codeLoading =
    creditCardState.promotionCode?.state === 'init' || creditCardState.friendReferralCode?.state === 'init';

  let promotionCode: PromotionCodeResult | undefined;
  let friendReferralCode: PromotionCodeResult | undefined;

  if (creditCardState.promotionCode?.state === 'success') {
    promotionCode = creditCardState.promotionCode;
  }

  if (creditCardState.friendReferralCode?.state === 'success') {
    friendReferralCode = creditCardState.friendReferralCode;
  }

  const { data, error, isLoading, mutate } = useSWR(
    codeLoading || !product?.price.id
      ? null
      : [product.price.id, promotionCode?.id, friendReferralCode?.id, promotionCode?.code === 'firstmonthfree'],
    ([priceId, promotionCodeId, friendReferralCodeId, firstMonthFree]) => {
      return productsApi.calculatePrice(priceId)({
        firstMonthFree,
        promotionCode: firstMonthFree ? undefined : promotionCodeId,
        friendReferralCode: friendReferralCodeId,
      });
    }
  );

  if (codeLoading) {
    return {
      fullPrice: product?.price.amount ?? 0,
      discountPrice: product?.price.amount ?? 0,
      isLoading: true,
      error: null,
      retry: mutate,
    };
  }

  let firstMonthFreeDiscount;

  if (data && product) {
    firstMonthFreeDiscount =
      (data.fullPrice ?? 0) -
      (data.discountPrice ?? 0) -
      calculateDiscount(product, promotionCode) -
      calculateDiscount(product, friendReferralCode);

    return {
      fullPrice: data.fullPrice,
      discountPrice: data.discountPrice,
      firstMonthFreeDiscount,
      error,
      isLoading,
      retry: mutate,
    };
  }

  return {
    fullPrice: product?.price.amount ?? 0,
    discountPrice: product?.price.amount ?? 0,
    error,
    isLoading,
    retry: mutate,
  };
};
