import { hasBaselineProduct, isBaselineProduct } from '~modules/product';
import { patientProgressAtom, profileProgressAtom } from '~modules/state';
import {
  CONSENT_SIGNATURE_PAGE_NAME,
  CREDIT_CARD_PAGE_NAME,
  GET_STARTED_PAGE_NAME,
  INSURANCE_ID_PAGE_NAME,
} from '~types';

import { accountSetupRoutes } from './accountSetup';
import { historyRoutes } from './history';

import type { InsuranceDetail } from '@almond/api-types';
import type { RoutingConfig, StackParamList } from '~types';

export const postBookingSelfRoutes = {
  id: 'postBookingSelf' as const,
  initial: () => ({ name: GET_STARTED_PAGE_NAME }),
  routes: {
    [GET_STARTED_PAGE_NAME]: {
      reset: true,
      on: {
        signin: async (getState, searchParams) => {
          const patientProgressState = await getState(patientProgressAtom);
          const profileProgressState = await getState(profileProgressAtom);
          const { consent, historyData, subscription, products } = patientProgressState;
          const { isInsuranceAccepted, insuranceId } = profileProgressState;

          if (isBaselineProduct(searchParams.product)) {
            const hasPurchasedBaseline = hasBaselineProduct(products);

            if (!hasPurchasedBaseline) {
              return { name: CREDIT_CARD_PAGE_NAME };
            }
          } else if (!subscription) {
            return { name: CREDIT_CARD_PAGE_NAME };
          }

          if (!consent) {
            return { name: CONSENT_SIGNATURE_PAGE_NAME };
          }

          if (!insuranceId && isInsuranceAccepted) {
            return { name: INSURANCE_ID_PAGE_NAME };
          }

          if (!historyData) {
            return historyRoutes;
          }

          return accountSetupRoutes;
        },
      },
    },
    [CREDIT_CARD_PAGE_NAME]: {
      on: {
        submit: async (getState, _, insurance: Pick<InsuranceDetail, 'memberId' | 'isAccepted'>) => {
          const patientProgressState = await getState(patientProgressAtom);
          const { historyData, consent } = patientProgressState;

          if (!insurance.memberId && insurance?.isAccepted) {
            return { name: INSURANCE_ID_PAGE_NAME };
          }

          if (!consent) {
            return { name: CONSENT_SIGNATURE_PAGE_NAME };
          }

          if (!historyData) {
            return historyRoutes;
          }

          return accountSetupRoutes;
        },
      },
    },
    [CONSENT_SIGNATURE_PAGE_NAME]: {
      on: {
        submit: async (getState, _, insurance: Pick<InsuranceDetail, 'memberId' | 'isAccepted'>) => {
          const patientProgressState = await getState(patientProgressAtom);

          if (!insurance.memberId && insurance?.isAccepted) {
            return { name: INSURANCE_ID_PAGE_NAME };
          }

          if (!patientProgressState.historyData) {
            return historyRoutes;
          }

          return accountSetupRoutes;
        },
      },
    },
    [INSURANCE_ID_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const patientProgressState = await getState(patientProgressAtom);

          if (!patientProgressState.historyData) {
            return historyRoutes;
          }

          return accountSetupRoutes;
        },
      },
      reset: true,
    },
  },
} satisfies RoutingConfig<StackParamList>;
