import React, { useCallback, useEffect, useState } from 'react';
import { type TextInput as NativeTextInput } from 'react-native';

import { TextInput, useTheme } from '@almond/ui';

import { Results } from './Results';
import states from './states.json';

import themedStyles from './styles';

import type { SelectOption, TextInputProps } from '@almond/ui';

type StateCode = keyof typeof states;

export type StateCodeInputProps = Omit<TextInputProps, 'onChangeText' | 'value'> & {
  value?: StateCode;
  onSelect: (value?: StateCode) => void;
};

export const StateCodeInput = React.forwardRef<NativeTextInput, StateCodeInputProps>((props, ref) => {
  const { onSelect, value: propValue, ...restProps } = props;
  const [value, setValue] = useState('');
  const filterData = useCallback(
    (data: typeof states, query: string) =>
      (Object.entries(data) as [StateCode, string][])
        .filter(
          ([k, v]) => k.toLowerCase().includes(query.toLowerCase()) || v.toLowerCase().includes(query.toLowerCase())
        )
        .map(([key, label]) => ({ label, value: key })),
    []
  );
  const [isSelected, setIsSelected] = useState(false);
  const [styles] = useTheme(themedStyles);

  const handleTextChange = useCallback(
    (text: string) => {
      const filteredData = filterData(states, text);
      const state = filteredData[0];

      // We need to catch the event when the user fills the state in based on the
      // info in his browser.
      if (!value && filteredData.length === 1 && state) {
        onSelect(state.value);
        setIsSelected(true);
      } else {
        onSelect(undefined);
        setIsSelected(false);
      }

      setValue(text);
    },
    [filterData, onSelect, value]
  );

  const handleSelect = useCallback(
    (item: SelectOption<StateCode>) => {
      setValue(item.label);
      onSelect(item.value);
      setIsSelected(true);
    },
    [onSelect]
  );

  useEffect(() => {
    if (propValue && propValue !== value) {
      setValue(states[propValue]);
      setIsSelected(true);
    }
  }, [propValue, value]);

  return (
    <>
      <TextInput
        {...restProps}
        value={value}
        onChangeText={handleTextChange}
        ref={ref}
        withBottomPadding={!value || isSelected}
        style={!!value && !isSelected && styles.inputWithResults}
      />
      <Results data={filterData(states, value)} onSelect={handleSelect} query={value} isHidden={isSelected} />
    </>
  );
});
