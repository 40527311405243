import React from 'react';
import { View } from 'react-native';

import { dateAndTimeUtilities } from '@almond/date-and-time';
import { useTheme } from '@almond/ui';

import { Date } from './Date';
import { EmptySpace } from './EmptySpace';

import themedStyles from './styles';

import type { Dayjs } from 'dayjs';

export type WeekProps = {
  week: Dayjs[];
  selectedDate?: Dayjs;
  availableDates?: { [key in string]?: unknown };
  onDayPress?: (day: Dayjs) => void;
  isFirst?: boolean;
  isLast?: boolean;
  isLoading?: boolean;
};

export const Week: React.FC<WeekProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={[styles.row, props.isFirst && styles.firstRow, props.isLast && styles.lastRow]}>
      {props.isFirst && <EmptySpace weekLength={props.week.length} />}
      {props.week.map((dayOfWeek, index) => (
        <Date
          key={index}
          date={dayOfWeek}
          mode={dateAndTimeUtilities.getDateMode(dayOfWeek, props.isLoading, props.selectedDate, props.availableDates)}
          onPress={props.onDayPress}
        />
      ))}
      {props.isLast && <EmptySpace weekLength={props.week.length} />}
    </View>
  );
};
