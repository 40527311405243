import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

export default () => {
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);

  searchParams.set('visit_reason', 'general_infection');
  searchParams.set('is_new_member', 'false');

  return <Redirect href={`/recommendation?${searchParams}`} />;
};
