import { useCallback } from 'react';

import { patientsApi } from '~modules/api';

import type { FormValues } from '@almond/ui';
import type { PharmacyFormValues } from '~types';

const useCreatePharmacy = (): ((values: FormValues<PharmacyFormValues>) => Promise<void>) => {
  return useCallback(async (values: FormValues<PharmacyFormValues>) => {
    const { name, ...restPharmacy } = values.pharmacy;

    await patientsApi.createPharmacy({ name, address: { ...restPharmacy } });
  }, []);
};

export default useCreatePharmacy;
