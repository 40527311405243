import React from 'react';

import { BaseButton, Text, useTheme } from '@almond/ui';

import { themedTimeSlotsStyles } from './styles';

import type { AvailableSlot, VisitLocationEnum } from '@almond/api-types';

export type TimeslotProps = {
  timeSlot: AvailableSlot;
  onPress: (timeSlot: AvailableSlot) => void;
  location: VisitLocationEnum;
};

export const Timeslot: React.FC<TimeslotProps> = props => {
  const [styles] = useTheme(themedTimeSlotsStyles);
  const { timeSlot, onPress, location } = props;

  return (
    <BaseButton
      role="button"
      style={styles.timeSlot}
      onPress={() => onPress(timeSlot)}
      testID={`SchedulingTimeslot: ${location}`}
    >
      <Text style={styles.timeSlotText}>{timeSlot.key}</Text>
    </BaseButton>
  );
};
