export const srcDoc = `<!--suppress CssInvalidFunction -->
<html lang='en'>
  <meta name="viewport" content="width=device-width initial-scale=1.0" />
  <style>
    * {
      margin: 0;
      padding: 0;
    }

    canvas {
      position: absolute;
      transform: translateZ(0);
    }
  </style>
  <body>
    <canvas></canvas>
    <script src="https://cdn.jsdelivr.net/npm/signature_pad@4.1.5/dist/signature_pad.umd.min.js"></script>
    <script>
      let signaturePad;

      const resizeCanvas = (canvas, width, height) => {
        const devicePixelRatio = 1;

        canvas.width = width * devicePixelRatio;
        canvas.height = height * devicePixelRatio;

        canvas.getContext('2d').scale(devicePixelRatio, devicePixelRatio);
        signaturePad?.clear();
      };

      const handleEnd = () => {
        const signature = signaturePad?.toDataURL();

        window.parent.postMessage({ type: 'endStroke', signature }, '*');
      };

      const enableSignaturePad = canvas => {
        signaturePad = new SignaturePad(canvas, {
          minWidth: 3,
          maxWidth: 4,
          throttle: 0,
          penColor: '#000000',
        });

        signaturePad.addEventListener('endStroke', handleEnd);
      };

      const handleMessage = event => {
        if (event.data.type === 'clear') {
          signaturePad?.clear();
        }
      };

      const app = () => {
        const canvas = document.querySelector('canvas');
        const width = document.body.clientWidth || window.innerWidth;
        const height = document.body.clientHeight || window.innerHeight;

        canvas.width = width;
        canvas.height = height;

        resizeCanvas(canvas, width, height);
        enableSignaturePad(canvas);
        window.addEventListener('resize', () => resizeCanvas(canvas, width, height));
        window.addEventListener('message', handleMessage);
      };

      app();
    </script>
  </body>
</html>`;
