import React, { Component } from 'react';

import { logger } from '~modules/logger/services';

import RuntimeError from '../../RuntimeError';
import ErrorPage from '../ErrorPage';

import type { ErrorInfo } from 'react';

class ErrorBoundary extends Component<React.PropsWithChildren> {
  public state: { hasError: boolean; error: unknown } = { hasError: false, error: null };

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const runtimeError = RuntimeError.fromOriginal(error, 'UnknownException');

    runtimeError.addDetail('componentStack', errorInfo);
    logger.logError('GLOBAL', runtimeError);
  }

  handleTryAgain = async (): Promise<void> => {
    this.setState({ hasError: false });
  };

  render(): React.ReactNode {
    if (this.state.hasError)
      return (
        <ErrorPage
          onTryAgain={this.handleTryAgain}
          error={this.state.error instanceof Error ? this.state.error : undefined}
        />
      );

    return this.props.children;
  }
}

export default ErrorBoundary;
