import { useRecoilValue } from 'recoil';

import { appointmentParamsAtom } from '~modules/state';

import { getProviderTypeString } from '../services';
import { useRecommendationBodyText } from './useRecommendationBodyText';
import { useVisitReasons } from './useVisitReasons';

export const useSchedulingVisitReasonsModal = () => {
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);
  const isNewMember = appointmentParamsState.isNewMemberRemote;
  const { visitReason, isLoadingVisitReasons, visitReasonsError } = useVisitReasons();
  const visitOutcome = visitReason?.visitOutcome;
  const modalBody = useRecommendationBodyText(visitOutcome);

  if (!visitReason || !visitOutcome || isLoadingVisitReasons || visitReasonsError) {
    return { visitOutcome, isNewMember, visitReason };
  }

  const visitReasonTitle = visitReason.title;
  const providerTitleText = visitOutcome.providerTypes ? getProviderTypeString(visitOutcome.providerTypes) : '';

  const text = {
    modalBody,
    visitReasonTitle,
    providerTitleText,
  };

  return {
    text,
    visitOutcome,
    isNewMember,
    visitReason,
  };
};
