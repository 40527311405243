import type { AppointmentAvailabilityMetadataState, AppointmentParamsState } from '~types';

export const APPOINTMENT_AVAILABILITY_METADATA_STATE_KEY = 'appointmentAvailabilityMetadataState';
export const APPOINTMENT_PARAMS_STATE_KEY = 'appointmentParamsState';

export const DEFAULT_APPOINTMENT_AVAILABILITY_METADATA_STATE: AppointmentAvailabilityMetadataState = {};

export const DEFAULT_APPOINTMENT_PARAMS_STATE: AppointmentParamsState = {
  isNewMemberRemote: true,
};
