import React, { useState } from 'react';

import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useBrowserTypeMap } from '@almond/ui';
import { useLocalSearchParams, useRouter } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { NavigationBar } from '~modules/integration';
import { useNavigationBar } from '~modules/patient/hooks';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom } from '~modules/state';
import { Background } from '~modules/ui';

import { Scheduling, VisitReasonHeaderMobile, VisitReasonSwitcher } from '../../components';
import { useVisitReasons } from '../../hooks';
import { appointmentUtilities } from '../../services';

import type { SCHEDULING_PAGE_NAME, SchedulingParams } from '~types';
import type { Key } from 'react';

export const SchedulingPage: React.FC = () => {
  const [isVisitReasonModalOpen, setIsVisitReasonModalOpen] = useState(false);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const { setParams } = useRouter();
  const { dispatch } = useRouteNavigation<typeof SCHEDULING_PAGE_NAME>();
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);
  const searchParams = useLocalSearchParams<SchedulingParams>();
  const { isLoading: isLoadingNavigationBar, bannerText } = useNavigationBar();
  const { visitReason } = useVisitReasons();
  const trackAnalyticsEvent = useTrackAnalyticsEvent();

  const onVisitReasonSelection = async (reason: Key) => {
    const updatedVisitReason = String(reason);

    trackAnalyticsEvent('visit_reasons', { visitReasons: updatedVisitReason });

    if (appointmentUtilities.isCuratedFlow(appointmentParamsState, updatedVisitReason)) {
      await dispatch('curatedSubmit', undefined, { visit_reason: updatedVisitReason });
    } else {
      // Opt out users when they change visit reason from Scheduling page
      setParams<SchedulingParams>({ visit_reason: updatedVisitReason, showAll: 'true' });
    }
  };

  const disableChangingVisitReason = (() => {
    const showAll = searchParams.showAll === 'true';
    const infectionOutcome = searchParams.infectionOutcome === 'true';

    if (searchParams.visit_reason === 'assisted_lab') {
      return !showAll;
    }

    // If we're in the curated flow and the user opts out after filling out the questionnaire,
    // we don't want to allow changing the visit reason
    if (appointmentUtilities.isCuratedFlow(appointmentParamsState, searchParams.visit_reason)) {
      return infectionOutcome;
    }

    return false;
  })();

  return (
    <>
      <NavigationBar
        isLoading={isLoadingNavigationBar}
        bannerText={bannerText}
        center={
          isMobile && (
            <VisitReasonHeaderMobile
              disableSwitching={disableChangingVisitReason}
              onSelectionChange={onVisitReasonSelection}
              onInformationPress={() => setIsVisitReasonModalOpen(true)}
            />
          )
        }
        shouldHideCloseButton={isMobile}
      />
      {isDesktop && (
        <VisitReasonSwitcher disableSwitching={disableChangingVisitReason} onSelectionChange={onVisitReasonSelection} />
      )}
      <Background testID="SchedulingPage">
        <Scheduling
          key={
            /* Completely reset the scheduling UI if the visit reason changes.
             * This forces the selected date back to today (and then runs the
             * rest of the scheduling "find first date" code)
             */
            searchParams.visit_reason
          }
          visitReason={visitReason}
          setIsVisitReasonModalOpen={setIsVisitReasonModalOpen}
          isVisitReasonModalOpen={isVisitReasonModalOpen}
        />
      </Background>
    </>
  );
};
