import { useCallback, useEffect, useRef } from 'react';

import { useInterval } from '@almond/ui';
import { cypressUtilities, useEvent } from '@almond/utils';

let INACTIVITY_INTERVAL_MS = 45000; // 45 seconds
const EVENTS_TO_LISTEN = [
  'mouseup',
  'mousedown',
  'mouseout',
  'mouseleave',
  'mouseenter',
  'mouseover',
  'mousemove',
  'wheel',
  'keyup',
  'keydown',
  'keypress',
  'touchstart',
  'touchend',
  'touchmove',
  'touchcancel',
  'scroll',
  'scrollend',
];

export const useRunOnUserIdle = (callback: () => void, shouldRun = true) => {
  const [withInterval, cancelInterval] = useInterval();
  const isDirtyRef = useRef(false);
  const wrappedCallback = useEvent(callback);

  const runInterval = useCallback(() => {
    cancelInterval();
    withInterval(() => {
      if (!isDirtyRef.current && shouldRun) {
        wrappedCallback();
        isDirtyRef.current = true;
      }
    }, INACTIVITY_INTERVAL_MS);
  }, [wrappedCallback, cancelInterval, shouldRun, withInterval]);

  useEffect(() => {
    EVENTS_TO_LISTEN.forEach(event => {
      window.addEventListener(event, runInterval);
    });

    return () => {
      EVENTS_TO_LISTEN.forEach(event => {
        window.removeEventListener(event, runInterval);
      });
    };
  }, [cancelInterval, runInterval]);

  useEffect(() => {
    // Running the interval on mount.
    runInterval();
  }, [runInterval]);

  useEffect(() => {
    if (cypressUtilities.isCypressRunning()) {
      window.demiSetInactivityInterval = (ms: number) => {
        INACTIVITY_INTERVAL_MS = ms;
        isDirtyRef.current = false;
        runInterval();
      };
    }
  }, [runInterval]);
};
