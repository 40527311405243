import React from 'react';

import { Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { TextProps } from '@almond/ui';

export const Error = (props: TextProps) => {
  const [styles] = useTheme(themedStyles);
  const { style, children, ...restProps } = props;

  return (
    <Text {...restProps} style={[styles.error, style]}>
      {children}
    </Text>
  );
};
