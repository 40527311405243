import React from 'react';

import { useTranslation } from '@almond/localization';

import { MainForm } from '~modules/forms';
import { useRouteNavigation } from '~modules/routing';

import { VisitCostMessage } from '../../components';

import type { INSURANCE_NOT_ACCEPTED_PAGE_NAME } from '~types';

export const InsuranceNotAcceptedPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof INSURANCE_NOT_ACCEPTED_PAGE_NAME>();

  return (
    <MainForm
      id="insuranceNotAccepted"
      title={t('insuranceNotAccepted.title')}
      submitButtonTitle={t('continue')}
      onSubmit={() => dispatch('submit')}
    >
      <VisitCostMessage />
    </MainForm>
  );
};
