import { useCallback } from 'react';

import { useLocalSearchParams } from 'expo-router';

import { patientsApi } from '~modules/api';
import { HttpRequestError } from '~modules/errors';

import { log } from '../logger';

import type { ErrorTypeChoices, SubscriptionOut } from '@almond/api-types';

export const useGetSubscription = (): (() => Promise<SubscriptionOut | null>) => {
  const searchParams = useLocalSearchParams();

  return useCallback(async () => {
    if (!searchParams.patient_uuid) {
      log("Can't get subscription without patient_uuid");

      return null;
    }

    try {
      return await patientsApi.getSubscription();
    } catch (error) {
      const CAUGHT_ERROR_CODES = ['inactive_subscription', 'no_payment_method'] satisfies ErrorTypeChoices[];

      if (error instanceof HttpRequestError && error.errorCode && CAUGHT_ERROR_CODES.includes(error.errorCode as any)) {
        log('Subscription for patient is inactive or no payment method exists', { errorCode: error?.errorCode });

        return null;
      }

      throw error;
    }
  }, [searchParams.patient_uuid]);
};
