import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  titleContainer: {
    marginBottom: sizes.SPACING_XXL,
  },
  titleText: {
    textAlign: 'center',
    marginTop: sizes.SPACING_S,
  },
  signatureContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  signature: {
    height: 224,
    borderWidth: sizes.SPACING_XXS,
    borderRadius: sizes.SPACING_S,
    borderColor: theme.placeholder,
    overflow: 'hidden',
    backgroundColor: theme.background,
  },
  caption: {
    color: theme.placeholder,
    marginTop: sizes.SPACING_M,
    textAlign: 'center',
  },
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
  },
  clearButton: {
    alignSelf: 'center',
    marginTop: sizes.SPACING_S,
  },
  clearButtonText: {
    color: theme.primary,
    textTransform: 'uppercase',
  },
}));
