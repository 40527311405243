import type { Slot } from '../ui';

export type AppointmentAvailabilityMetadataState = Record<string, unknown>;

export type AppointmentParamsState = {
  isTelehealth?: boolean;
  // When loading appointment availability, we book members as either "new" vs "returning".
  // A returning member a) has a log in, and b) has had at least 1 appointment in the past.
  // In order to check this, we make an API request to `past_count`.
  // Initially it's set when "is_new_member" is set, and then after the user is loaded.
  isNewMemberRemote?: boolean;
  visitTime?: Slot;
  type?: string;
  careTeamMember?: string;
  labOrders?: string[];
};
