import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { HIPAA_LINK, Link, PRIVACY_URL, TERMS_URL, Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

export const Title: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.titleContainer}>
      <Text fontStyle="medium" size="s" style={styles.titleText}>
        {t('consentSignature.subtitle.text1')}
        <Link key={TERMS_URL} url={TERMS_URL} style={styles.titleText}>
          {t('consentSignature.subtitle.link1')}
        </Link>
        {t('consentSignature.subtitle.text2')}
        <Link key={HIPAA_LINK} url={HIPAA_LINK} style={styles.titleText}>
          {t('consentSignature.subtitle.link2')}
        </Link>
        {t('consentSignature.subtitle.text3')}
        <Link key={PRIVACY_URL} url={PRIVACY_URL} style={styles.titleText}>
          {t('consentSignature.subtitle.link3')}
        </Link>
        {t('consentSignature.subtitle.text4')}
      </Text>
    </View>
  );
};
